import React from 'react';

const MaintenancePage = () => {
  return (
    <div>
      <h1>Coming Soon</h1>
      <p>This page is currently under construction.</p>
    </div>
  );
};

export default MaintenancePage;
