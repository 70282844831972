import { createSlice } from "@reduxjs/toolkit";
// import * as API from "../../api/apiService";

const switchRole = createSlice({
    name: "switchRole",
    initialState: {
        user: true,
        advisor: false,
    },
    reducers: {
        switchToAdvisor: (state) => {
            state.advisor = true;
            state.user = false;
        },
        switchToUser: (state) => {
            state.user = true;
            state.advisor = false;
        },

        hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});
export default switchRole.reducer;

export const { switchToAdvisor, switchToUser, hasError } = switchRole.actions;

// export const getPost = (data) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     await API.getPost(data).then((response) => {
//       dispatch(onSuccess(response.data));
//     });
//   } catch (e) {
//     dispatch(hasError(e.message));
//   }
// };
