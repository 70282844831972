import React, { useEffect, useState } from 'react';
import domainName from '../../domainName';
import { COLORS } from '../../colorTheme/colorTheme';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";  

const MyRequest = () => {
    const { UserId } = useSelector((state) => state.login);
    const [t] = useTranslation("global");
    const [displayListing, setDisplayListing] = useState([]);
    const [wantDeleteItem, setWantDeleteItem] = useState();
    const [showDeleteRequest, setShowDeleteRequest] = useState(false);

    async function deleteRequest() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/deleteUserRequest",
                "POST",
                JSON.stringify({
                    "UserId": wantDeleteItem.PosterUserId,
                    "RequestId": wantDeleteItem._id,
                    "DeleteInfo": "test"
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then(() => {
                setShowDeleteRequest(false);
                getRequestListing(); // Refresh the request listing after deletion
            })
            .catch((error) => {
                console.error("Error deleting request:", error);
            });
    }

    async function getRequestListing() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/selectUserRequestListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "PageNum": 1,
                    "ResultNum": 100
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson) => {
                setDisplayListing(responseJson.Data);
            })
            .catch((error) => {
                console.error("Error fetching requests:", error);
            });
    }

    function DeleteRequestModal() {
        return (
            <Modal show={showDeleteRequest} onHide={() => setShowDeleteRequest(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('myRequest.Delete Request')} {wantDeleteItem?.RequestIdNo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('myRequest.Are you sure you want to delete this request?')}</Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteRequest(false)}>
                    {t('myRequest.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={deleteRequest}>
                        {t('myRequest.Delete')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function renderNoBookings() {
        return (
            <Row>
                <h4 style={{ textAlign: 'center' }}>{t("myRequest.You don't have any request yet")}</h4>
            </Row>
        );
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '1.8%',
                    marginTop: '1%',
                }}
            />
        );
    }

    function renderRequestListings(item) {
        return (
            <div style={{ marginTop: '2%', marginBottom: '1%', padding: '1%', /*borderWidth: 0, borderBottomWidth: 1.5, borderColor: COLORS.lightGray1, borderStyle: 'solid',*/}}>
                <Row className="my-3">
                    <Col><h5>{t('myRequest.Request No')} : {item.RequestIdNo}</h5></Col>
                    <Col className="d-flex justify-content-end">{item.DisplayDateTime}</Col>
                </Row>
                <Row className="my-3">
                    <Col> <img src={require('../../img/icons/categories.png')} style={{ width: 35, height: 35 }} /> {item.CategoryDisplayList[0].CategoryName} :{item.CategoryDisplayList[0].SubCateogrynameDisplay}</Col>
                </Row>
                <Row className="my-3">
                    <Col> <img src={require('../../img/icons/location.png')} style={{ width: 35, height: 35 }} /> {item.DisplayLocation}</Col>
                </Row>
                <Row className="my-3">
                    <Col> <img src={require('../../img/icons/description.png')} style={{ width: 35, height: 35 }} />  {item.ExtraInfo}</Col>
                </Row>
                <Row>
                    <Button
                        variant="outline-danger"
                        onClick={() => {
                            setWantDeleteItem(item);
                            setShowDeleteRequest(true);
                        }}
                    >{t('myRequest.Delete')}</Button>
                </Row>
            </div>
        );
    }

    useEffect(() => {
        getRequestListing();
    }, []);

    return (
        <div className='greybk-div p-lg-5'>
            {/* Adjusted Container for Desktop */}
            <Container style={{ width: '55%', maxWidth: '800px' }} className='px-5 py-5 bg-white shadow-lg rounded border-light mx-auto d-none d-lg-block'>
                <h4 className='fw-bold pb-3'>{t('myRequest.My Requests')}</h4>
                {greyLine()}
                {displayListing.length === 0 ? (
                    <div className="mt-4">{renderNoBookings()}</div>
                ) : (
                    displayListing.map((item) => renderRequestListings(item))
                )}
                <DeleteRequestModal />
            </Container>

            {/* Adjusted Container for Mobile */}
            <Container className='px-4 py-4 bg-white shadow-lg rounded border-light mx-auto d-lg-none'>
                <h4 className='fw-bold pb-3'>{t('myRequest.My Requests')}</h4>
                {greyLine()}
                {displayListing.length === 0 ? (
                    <div className="mt-4">{renderNoBookings()}</div>
                ) : (
                    displayListing.map((item) => renderRequestListings(item))
                )}
                <DeleteRequestModal />
            </Container>
        </div>
    );
}

export default MyRequest;

