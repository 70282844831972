import React, { useState, useEffect } from 'react';
import domainName from '../domainName'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const BecomeAdvisor = () => {

  const dispatch = useDispatch();
  const { token, UserData } = useSelector((state) => state.login);

  const [cardNumber, setCardNumber] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');

  const [t, i18n] = useTranslation("global");


  const handleSubmit = (event) => {
    event.preventDefault();
    // handle payment submission here
    alert(t("error.payment successful ! "))
  }

  function planSelection() {
    return (
      <div style={{backgroundColor: "#C0281C"}}>
        <div>{t('becomeAdvisor.50% off limited time USD 9.90')}</div>
        <div>{t('becomeAdvisor.50% off limited time USD 19.90')}</div>
        <div>{t('becomeAdvisor.50% off limited time USD 29.90')}</div>
      </div>

    )
  }


  function paymentForm() {
    return (
      <form onSubmit={handleSubmit}>
        <label>
          {t('becomeAdvisor.Card Number:')}
          <input
            type="text"
            value={cardNumber}
            onChange={(event) => setCardNumber(event.target.value)}
          />
        </label>
        <label>
          (t{'becomeAdvisor.Security Code:'})
          <input
            type="text"
            value={securityCode}
            onChange={(event) => setSecurityCode(event.target.value)}
          />
        </label>
        <label>
          (t{'becomeAdvisor.Expiration Date:'})
          <input
            type="text"
            value={expirationDate}
            onChange={(event) => setExpirationDate(event.target.value)}
          />
        </label>
        <label>
          (t{'becomeAdvisor.Name on Card:'})
          <input
            type="text"
            value={nameOnCard}
            onChange={(event) => setNameOnCard(event.target.value)}
          />
        </label>
        <button type="submit">Submit Payment</button>
      </form>
    )
  }

  return (
    <div>
      <h1>{t('becomeAdvisor.BecomeAdvisor')}</h1>
      <div> {t('becomeAdvisor.step 1 : Description : How it works ')}</div>
      <div> {t('becomeAdvisor.step 2 : Select a plan ')}</div>
      <div>{planSelection()}</div>
      <div> {t('becomeAdvisor.step 3 : Payment method')} </div>
      <div> {paymentForm()}</div>

    </div>
  )
}

export default BecomeAdvisor