// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: "advisor2udev.firebaseapp.com",
  projectId: "advisor2udev",
  storageBucket: "advisor2udev.appspot.com",
  messagingSenderId: "715903127860",
  appId: "1:715903127860:web:facdeed8aaba7d0ee45561",
  measurementId: "G-0JFB2D21XD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

// console.log("app and analytics here", app, analytics)