import React from 'react'
import domainName from '../domainName'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, NavDropdown, Modal, Image } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme';
import BookingListCard from '../components/BookingListCard';
import { useTranslation } from "react-i18next";

const MyBookings = () => {

    const { token, UserData, UserId } = useSelector((state) => state.login);

    const [displayListing, setDisplayListing] = useState([])

    const [tabSelectionUpcoming, setTabSelectionUpcoming] = useState(true);
    const [tabSelectionCompleted, setTabSelectionCompleted] = useState(false)
    const [tabSelectionCancelled, setTabSelectionCancelled] = useState(false)

    const [showNothingMessage, setShowNothingMessage] = useState("upcoming")
    const [colorStatus, setColorStatus] = useState(COLORS.orange)

    const [t] = useTranslation("global");


    async function getBookingListing(bookinglistType) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/selectUserBookingListing",
                "POST",
                JSON.stringify({
                    // "UserId": userState.UserId,
                    // "BookingListType": bookinglistType,
                    // "UserRole": "Advisor",
                    // "PageNum": 1,
                    // "ResultNum": 10
                    "UserId": UserId,
                    "BookingListType": bookinglistType,
                    "UserRole": "User",
                    "PageNum": 1,
                    "ResultNum": 100
                }),
                // undefined, // user id, wait binglun settle backend
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log("responseJson /user/selectUserBookingListing ->", responseJson);
                setDisplayListing(responseJson.Data)


            }))
            .catch((error) => {
                //console.log("error /user/selectUserBookingListing ->", error);
                // Alert.alert(
                //   "Oops!",
                //   "An error occurred. Please try again later.",
                //   [{ text: "OK", onPress: () => { } }],
                //   { cancelable: false }
                // );
            });
    }

    function renderNoBookings(title) {
        return (
            <div style={{
                marginHorizontal: SIZES.padding * 0.8,
                borderRadius: 10,
                backgroundColor: COLORS.lightGray1,
                marginTop: '2%',
                marginBottom: '2%',
                padding: '5%',
                // paddingTop: hp('5%'),
                // paddingBottom: hp('5%'),
                // paddingRight: wp('2%'),
                // paddingLeft: wp('2%'),
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                alignContent: 'center',
                textAlign: 'center'
            }}>
                <Image
                    src={require("../img/icons/bookingImage.png")}
                // style={{ alignItems:'center' }}
                />
                <h3 style={{ paddingTop: '2%', color: COLORS.black }}>{t("MyBookings.You don't have any")} {title} {t('MyBookings.bookings yet')}</h3>
            </div>
        )
    }


    function renderList(item) {
        return (
            <Row className='mt-3'>
                <div style={{
                    backgroundColor: COLORS.lightOrange,
                    padding: '3%'
                }}>
                    {JSON.stringify(item)}
                </div>
            </Row>
        )

    }

    function renderTabSelection() {
        return (
            <Row className='mt-3'>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionUpcoming ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionUpcoming ? 1 : 0,
                            borderBottomColor: COLORS.orange
                        }}
                        onClick={() => {
                            getBookingListing("Upcoming")

                            setTabSelectionUpcoming(true)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("upcoming")
                            setColorStatus(COLORS.orange)
                        }}> {t('MyBookings.Upcoming')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCompleted ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCompleted ? 1 : 0,
                            borderBottomColor: COLORS.orange
                        }}
                        onClick={() => {
                            getBookingListing("Completed")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(true)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("completed")
                            setColorStatus(COLORS.green)
                        }}>
                       {t('MyBookings.Completed')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCancelled ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCancelled ? 1 : 0,
                            borderBottomColor: COLORS.orange
                        }}
                        onClick={() => {
                            getBookingListing("Cancelled")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(true)

                            setShowNothingMessage("cancelled")
                            setColorStatus(COLORS.gray)
                        }}>
                        {t('MyBookings.Cancelled')}
                    </button>
                </Col>
            </Row>
        )
    }



    useEffect(() => {
        getBookingListing("Upcoming")
    }, [])

    return (
        <div className="greybk-div p-lg-5" style={{ height: 'auto', minHeight: 1000 }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="box-container p-2">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('MyBookings.My Bookings')}</h5>
                                </Col>
                            </Row>
                            {renderTabSelection()}
                            {displayListing == "" ? <div>{renderNoBookings(showNothingMessage)}</div>
                                : displayListing.map((item, index) => (
                                    <div style={{padding:'2%'}}>
                                        <BookingListCard 
                                        item={item} 
                                        imageStyle={{
                                            backgroundColor: COLORS.white,
                                            borderRadius: 10,
                                            height: 100,
                                            width: 100,
                                            resizeMode: 'contain'
                                        }} 
                                        statusColor={colorStatus}
                                        navigation={`/bookingDetailsUser?bookingid=${item._id}`}
                                        />
                                    </div>
                                ))}
                        </Container>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default MyBookings