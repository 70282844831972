import React, { useState } from 'react'
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Banner = ({ bannerObj }) => {

    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    const [activeIndex, setActiveIndex] = useState(0);

    const [showNextIcon, setShowNextIcon] = useState(false);
    const [showPrevIcon, setShowPrevIcon] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const handleHover = (isHovered) => {
        setShowNextIcon(isHovered);
        setShowPrevIcon(isHovered);
    }


    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % bannerObj.length);
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + bannerObj.length) % bannerObj.length);
    };


    return (
        <div>
            <Row className="m-0">
                <div id="carouselExampleControls" className="carousel slide p-0 m-0" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-inner">
                        {bannerObj.map((item, index) => (
                            <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
                                <div className="col-12" style={{ cursor: 'pointer', height: isWebsite ? '65vh' : 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                        src={item.ImageDisplay}
                                        className="img-fluid"
                                        alt="Image"
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                            objectFit: 'cover',
                                        }}
                                        onClick={() => {
                                            if (item.Link) {
                                                const link = item.Link.startsWith('http://') || item.Link.startsWith('https://') ? item.Link : `http://${item.Link}`;
                                                window.open(link);
                                            } else {
                                                console.log("No link provided");
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                        ))}
                    </div>
                    {bannerObj.length > 1 && (
                        <button
                            className={`carousel-control-next ${activeIndex === bannerObj.length - 1 ? 'd-none' : ''}`}
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="next"
                            style={{
                                border: '1px solid transparent',
                                backgroundColor: isWebsite ? isHovered1 ? 'rgba(128, 128, 128, 0.5)' : 'transparent' : "transparent",
                                width: isWebsite ? '100px' : 'auto',
                                height: isWebsite ? '65vh' : 'auto',
                                overflow: 'hidden',
                                opacity: 1.5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                right: 0,
                            }}
                            onMouseEnter={() => setIsHovered1(true)}
                            onMouseLeave={() => setIsHovered1(false)}
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                                style={{
                                    backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'black\' width=\'20\' height=\'32\' viewBox=\'0 0 24 24\'%3e%3cpath d=\'M9.707 14.293L11.121 15.707 16.414 10.414 11.121 5.121 9.707 6.536 13.172 10z\'/%3e%3c/svg%3e")',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    display: 'flex',
                                    width: '64px',
                                    height: '64px',
                                    right: '5%',
                                }}
                            ></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    )}
                    {bannerObj.length > 1 && (
                        <button
                            className={`carousel-control-prev ${activeIndex === 0}`}
                            type="button"
                            data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev"
                            style={{
                                border: '1px solid transparent',
                                objectFit: 'cover',
                                backgroundColor: isWebsite ? isHovered ? 'rgba(128, 128, 128, 0.5)' : 'transparent' : "transparent",
                                width: isWebsite ? '100px' : 'auto',
                                height: isWebsite ? '65vh' : 'auto',
                                overflow: 'hidden',
                                opacity: 1.5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                left: 0,
                                transition: 'background-color 0.3s ease',
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                                style={{
                                    backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'black\' width=\'30\' height=\'50\' viewBox=\'0 0 24 24\'%3e%3cpath d=\'M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z\'/%3e%3c/svg%3e")',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    display: 'flex',
                                    width: '64px',
                                    height: '64px',
                                    left: '2%',
                                }}
                            ></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                    )}
                </div>
            </Row>
        </div>
    )
}

export default Banner
