import React, { useEffect, useState } from 'react'
import domainName from '../../domainName'
import { useSelector } from 'react-redux';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { COLORS } from "../../colorTheme/colorTheme";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const NewRequestListings = () => {

    const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);
    //
    const [newRequestListing, setNewRequestListing] = useState([])

    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global");

    async function getNewRequestListing() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/exlporeUserRequestListing",
                "POST",
                JSON.stringify({
                    // "UserId": userState.UserId,
                    "UserId": UserId,
                    "start": 1,
                    "length": 10
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log('/user_request/exlporeUserRequestListing : response : ', responseJson.Data)
                setNewRequestListing(responseJson.Data)

            }))
            .catch((error) => {
                // console.log('/user_request/exlporeUserRequestListing : error : ', error)
            });
    }

    function renderRequestCard(requestitem) {
        return (
            <div style={{
                backgroundColor: requestitem.IsSeenBefore ? COLORS.white : '#F3FBFD' , borderWidth: 0, borderBottomWidth: 1.5, borderColor: COLORS.lightGray1, borderStyle: 'solid',
                paddingTop: '1%', paddingBottom: '1%', cursor: 'pointer'
            }}
                onClick={() => {
                    // console.log(requestitem,requestitem.RequestId)
                    navigate(`/newRequestAdvisorDetails?requestId=${requestitem.RequestId}`)
                }}>
                <Row className='mt-2'>
                    <Col><h4>{t('NewRequestListings.Request No')}: {requestitem.UserRequestData.RequestIdNo}</h4> </Col>
                    <Col className="text-end" >{requestitem.DisplayDateTime}</Col>
                </Row>
                <Row className='mt-2'>
                    <Col><img src={require('../../img/icons/user.png')} style={{ height: 35, width: 35 }} /> {requestitem.UsersData.Name}</Col>
                </Row>

                {requestitem.UserRequestData.CategoryDisplayList.map((data, index) => (
                    <Row className='mt-2'>
                        <Col><img src={require('../../img/icons/categories.png')} style={{ height: 35, width: 35 }} /> {data.CategoryName} : {data.SubCateogrynameDisplay}</Col>
                    </Row>
                ))
                }

            </div >
        )
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '1.8%',
                    marginTop: '1%',
                }}
            />
        );
    }

    useEffect(() => {
        getNewRequestListing()
    }, [])

    return (
        <div style={{ backgroundColor: "#F8F8F8", paddingTop: '2%', minHeight:1000, height:'auto' }}>
            <Container className="width95" style={{ backgroundColor: COLORS.white, paddingBottom: '2%', paddingTop: '1%' }}>
                <Row>
                    <Col>
                        <h1>{t('NewRequestListings.New Request')}</h1>
                        <br />
                        {greyLine()}
                    </Col>
                </Row>


                {/* <div>{JSON.stringify(advisorSfavouriteListingerviceListings)}</div> */}

                <Row style={{ margin: '1%' }}>

                    {newRequestListing.map((item, index) => (
                        renderRequestCard(item)
                    ))}

                </Row>
            </Container>
        </div>
    )
}

export default NewRequestListings