
// import SubscriptionDetails from './SubscriptionDetails';
// import PaymentHistory from './PaymentHistory';

import React, { useState, useEffect } from 'react';
import SubscriptionDetails from './SubscriptionDetails';
import PaymentHistory from './PaymentHistory';
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";



const App = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [t, i18n] = useTranslation("global");
    const [pathLang, setPathLang] = useState('');
    const { pathname, search } = useLocation();


    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    useEffect(() => {
        let queryParams = queryString.parse(search);
        setPathLang(queryParams.lang)

        // Check if the 'lang' parameter is present in the URL
        let _lang = queryParams.lang ? queryParams.lang : localStorage.getItem('selectedValue')
        i18n.changeLanguage(_lang);

    }, [])

    return (


        <div className='greybk-div p-lg-5' style={{ height: '120vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className='col-lg-11'>
                        <div className='box-container p-4' style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                        onClick={() => handleTabChange(1)}
                                        style={{
                                            marginBottom: activeTab === 1 ? '10px' : '0',
                                            borderBottom: activeTab === 1 ? '2px solid #C0281C' : 'none',
                                            borderTop: 'none',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                            color: activeTab === 1 ? 'black' : 'grey',
                                            fontWeight: activeTab === 1 ? 'bold' : 'normal',

                                        }}
                                    >{t('subscription.Subscription')}
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                        onClick={() => handleTabChange(2)}
                                        style={{
                                            marginBottom: activeTab === 2 ? '10px' : '0',
                                            borderBottom: activeTab === 2 ? '2px solid #C0281C' : 'none',
                                            borderTop: 'none',
                                            borderLeft: 'none',
                                            borderRight: 'none',
                                            color: activeTab === 2 ? 'black' : 'grey',
                                            fontWeight: activeTab === 2 ? 'bold' : 'bold',

                                        }}
                                    >{t('subscription.Payment History')}
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content mt-3">
                                <div className={`tab-pane ${activeTab === 1 ? 'show active' : ''}`}>
                                    <SubscriptionDetails />
                                </div>
                                <div className={`tab-pane ${activeTab === 2 ? 'show active' : ''}`}>
                                    <PaymentHistory />
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>

        </div>


    );
};

export default App;
