import React from 'react'
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme';
import domainName from '../domainName';
import { useEffect } from 'react';
import { useState } from 'react';
import { SHA256 } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const ChangeEmailVerification = () => {

    const navigate = useNavigate()

    const [message, setMessage] = useState(t('setMessage.Verification in progress...'))
    const [showSuccess, setShowSuccess] = useState(false)

    const [t] = useTranslation("global");


    async function getUserInfoForMeMenu(uid) {

        let returnData;

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/meMenu",
                "POST",
                JSON.stringify({
                    "UserId": uid,
                    "DisplayScreen": "User"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/meMenu responseJson here', responseJson)
                // setDisplayUserName(responseJson.Data.UserName)
                // setDisplayImage(responseJson.Data.UserImage)
                // setPhoneNumber(responseJson.Data.UserMobileNo)
                // setEmail(responseJson.Data.UserEmail)
                // setCanChangePassword(responseJson.Data.CanChangePassword)
                // setWhatsappNumber(responseJson.Data.UserWhatAppsMobileNo)
                // setNumberInput(responseJson.Data.UserWhatAppsMobileNo.substring(3))

                returnData = responseJson.Data
            }))
            .catch((error) => {
                //console.log('/user/meMenu error here', error)
            });

        return returnData;
    }


    async function updateUserEmail(_email, _userId) {

        //console.log('userId in updateUserEmail', _userId)

        const userData = await getUserInfoForMeMenu(_userId)

        //console.log('userData', userData)

        if (userData) {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/user/updateUserAccount",
                    "POST",
                    JSON.stringify({
                        "UserId": _userId,
                        "Name": userData.UserName,
                        "Email": _email,
                        "WhatappsMobileNo": userData.UserWhatAppsMobileNo,
                        "IsUpdateWhatappsMobileNo": false,
                        "MobileNo": userData.UserMobileNo,
                        "Photos": []
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {

                    //return data here
                    //console.log('responseJson in /user/updateUserAccount', responseJson);

                    if (responseJson.ReturnCode == 200) {
                        //display successful message here 
                        setShowSuccess(true)
                        setMessage(t('setMessage.Email changed successful ! Please go log in and enjoy adviser2u !'))
                    } else {
                        alert(t('error.Email failed to change. Please try it again later'))
                        navigate("/");
                    }

                }))
                .catch((error) => {
                    //console.log("/user/updateUserAccount went wrong. Please try again later.", error)
                });

        } else {
            alert(t("error.Something went wrong. Please try it again later"))
            navigate("/");
        }



    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value from parameter
        const userid = searchParams.get("userId");
        const id = searchParams.get("id");
        const resetid = searchParams.get("resetid");
        const email = searchParams.get("email");
        const timeid = searchParams.get("tid");

        //console.log("userid", userid)

        // put some condition

        const emailHash = SHA256(email);

        const salt = SHA256(emailHash + timeid + id).toString();

        if (salt == resetid) {
            updateUserEmail(email, userid)
        } else {
            alert(t('error.Email verification failed. Please try it again later'))
            navigate("/");
        }

    }, [])

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row style={{ textAlign: 'center' }}>
                                <Col>
                                    <h3 className="fw-bold">{t('changeEmailVerification.Change Email')}</h3>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'center' }}>
                                <Col className='mt-3'>
                                    <h5 className="fw-bold">{message}</h5>
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center">
                                {
                                    showSuccess &&
                                    <Image
                                        src={require('../img/icons/successful.png')}
                                        style={{
                                            width: 120,
                                            height: 100,
                                        }}
                                        className='mt-3'
                                    />
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ChangeEmailVerification