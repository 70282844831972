import { createSelector, createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    token: null,
    isAnonymousUser: true,
    UserId: '',
    latitude: 1.492659,
    longitude: 103.7413591,
    locationDisplay: '81100, Johor Bahru, Johor',
    profileImage: '',
    UserData: '',
    // registerAdviserCountry: null
  },
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    insertAnonymousUserId: (state, action) => {
      state.UserId = action.payload;
    },
    logInSuccess: (state, action) => {
      state.isAnonymousUser = false;
      state.UserData = action.payload;
    },
    logOut: (state, action) => {
      state.token = null
      state.UserData = "";
      state.isAnonymousUser = true;
      state.UserId = "";
      state.profileImage = "";
      state.latitude = 1.492659;
      state.longitude = 103.7413591;
      state.locationDisplay = '81100, Johor Bahru, Johor'
      // state.registerAdviserCountry = null
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    setLongitude: (state, action) => {
      state.longitude = action.payload;
    },
    setLatitude: (state, action) => {
      state.latitude = action.payload;
    },
    setLocationDisplay: (state, action) => {
      state.locationDisplay = action.payload;
    },
    // this is to store the first time adviser register country
    // setRegisteradivserCountry: (state, action) => {
    //   state.registerAdviserCountry = action.payload;
    // },
  },
});
export default loginSlice.reducer;

export const { updateToken, logInSuccess, logOut, insertAnonymousUserId, setLongitude, setLatitude, setLocationDisplay, setProfileImage //, setRegisteradivserCountry
 } = loginSlice.actions;
