import React from 'react'
import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { COLORS } from '../../colorTheme/colorTheme';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from "react-i18next";

const PopUserVerifyEmail = () => {

    const [t] = useTranslation("global");


    const [email, setEmail] = useState()

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let queryParams = queryString.parse(location.search);
        setEmail(queryParams.email)
    }, []);

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row className="justify-content-center">
                                <Col className="d-flex justify-content-center">
                                    <Image
                                        style={{ width: '30%', height: 'auto', marginTop: ('5%') }}
                                        src={require("../../img/icons/verifyEmail.png")}
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-4">
                                <Col className="d-flex justify-content-center">
                                    <h2 className="fw-bold">{t('popUserVerifyEmail.Verify Your Email')}</h2>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <span style={{ textAlign: 'center' }}>{t('popUserVerifyEmail.Please click on the verification link sent to')} </span>
                                <span style={{ textAlign: 'center' }}><h4>{email}</h4></span>
                            </Row>
                            <Nav.Link style={{ textDecorationLine: 'none' }}
                                // to={`/signin`}
                                href="/signin"
                            >
                                {/* <Link style={{ textDecorationLine: 'none' }}
                                    to={`/signin`}
                                > */}
                                    <Row className="justify-content-center mt-4" style={{ backgroundColor: COLORS.orange, borderRadius: 20, paddingTop: '2%', paddingBottom: '2%' }}>
                                        <Col className="d-flex justify-content-center">
                                            <span style={{ color: COLORS.white }}>{t('popUserVerifyEmail.Log in')}</span>
                                        </Col>
                                    </Row>
                                {/* </Link> */}

                            </Nav.Link>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PopUserVerifyEmail