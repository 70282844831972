import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

const Protected = ({ children}) => {

    const location = useLocation();
    // console.log("props",this.props.parameter)
    const { user } = UserAuth()
    const { token, UserData } = useSelector((state) => state.login);

    // console.log("UserData",UserData)

    if (UserData == "" || UserData == null) {
        return <Navigate to={`/signin?redirect=${location.pathname}`} />;
    } else {
        // Once the user is logged in and the token is set, render the children
        return children;
    }

    // return children;

}

export default Protected