import React from 'react'
import closeIcon from '../img/icons/close.svg'
import './Popup.css'

function Popup(props) {
    return (props.trigger) ? (

        <div className="popup">
            <table>
                <tr class="center-row">
                    <td>
                        <div className="popup-image"
                            onClick={props.imageLink ? () => { window.open(`https://${props.imageLink}`) } : undefined}
                            style={{ cursor: props.imageLink ? 'pointer' : 'default' }}>
                            {props.children}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="close-btn">
                            <img src={closeIcon} onClick={() => props.setTrigger(false)} />
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    ) : "";
}

export default Popup