import React from 'react';
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap';

function MessageConversationCard({ photo, name, date, msg, onSide }) {

  return (

    <div className='pt-2 pb-2'>
      {onSide == 'left' ? (

        <div >
          <Row>
            <Col xs={12} md={10} className='d-flex'>
              <Image src={photo} className='round-profile-image' />
              <Card className='p-2 chatCard chatCard-sender' style={{ marginLeft: '1rem' }}>
                <div className='pb-2'>{msg}</div>
                <div style={{ fontSize: '0.6rem', textAlign: 'right' }}>{date}</div>
              </Card>
            </Col>
          </Row>
        </div>

      ) :

        <div>
          <Row>

            <Col xs={12} className='d-flex justify-content-end'>
              <Card className='p-2 chatCard chatCard-me' style={{ marginLeft: '1rem' }}>
                <div className='pb-2'>{msg}</div>
                <div style={{ fontSize: '0.6rem', textAlign: 'left' }}>{date}</div>
              </Card>
            </Col>
          </Row>
        </div>

      }
    </div>



  );
}

export default MessageConversationCard;
