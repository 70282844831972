import React from 'react'
import domainName from '../../domainName'
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { SHA256 } from "crypto-js";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {

    const [t] = useTranslation("global");

    const location = useLocation();
    const navigate = useNavigate();

    const confirmRef = useRef(null);

    // for password visible
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [userId, setUserId] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [paramEmail, setParamEmail] = useState()

    const [validLink, setValidLink] = useState(false)

    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState();

    const [passwordLabelRedWords, setPasswordLabelRedWords] = useState('')
    const [confirmPasswordLabelRedWords, setConfirmPasswordLabelRedWords] = useState('')

    const checkEmailValid = async (email) => {

        //console.log('checkEmailValid', email)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "Email": email ? email : paramEmail,
                    "LoginType": "EMAIL"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log("/user/checkingMobileNoOrEmailIsExisting", responseJson.Data);
                setUserId(responseJson.Data._id)

            }))
            .catch((error) => {
                //console.log("Oops!, /user/checkingMobileNoOrEmailIsExisting error occurred. Please try again later.");
            });
    }


    const checkPhoneNumberValid = async (phonenumber) => {

        //console.log('checkPhoneNumberValid', phonenumber)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "MobileNo": phonenumber ? phonenumber : phoneNumber,
                    "LoginType": "MobileNo"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log("/user/checkingMobileNoOrEmailIsExisting", responseJson.Data);
                setUserId(responseJson.Data._id)

            }))
            .catch((error) => {
                //console.log("Oops!, /user/checkingMobileNoOrEmailIsExisting error occurred. Please try again later.");
            });
    }

    const updatePassword = async () => {

        const passwordHashed = SHA256(confirmPassword).toString();
        const diversifyKey = Math.random().toString(36).substring(2, 12);

        // console.log(JSON.stringify({
        //     "UserId": userId,
        //     "Password": null,
        //     "NewPassword": passwordHashed,
        //     "DiversifyKey": diversifyKey,
        //     "IsDirectUpdate" : true
        // }))

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userUpdateOwnPassword",
                "POST",
                JSON.stringify({
                    "UserId": userId,
                    "Password": null,
                    "NewPassword": passwordHashed,
                    "DiversifyKey": diversifyKey,
                    "IsDirectUpdate": true
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log('responseJson in /user/userUpdateOwnPassword', responseJson);

                if (responseJson.ReturnCode == 200) {
                    alert(t("error.Successfully updated password!"));
                    navigate('/signin')

                } else {
                    alert(responseJson.ReturnDesc);
                }

            }))
            .catch((error) => {
                //console.log("/user/userUpdateOwnPassword went wrong. Please try again later.")
            });
    }

    const handleFormSubmit = async () => {
        //console.log("handleFormSubmit > password, confirm password", password, confirmPassword)
        //console.log("userId", userId)

        let errors = [];

        if (!password) {
            setPasswordLabelRedWords(t("errorPush.New Password field cannot be empty!"));
            errors.push(t("errorPush.- New Password field cannot be empty!"));
        } else {
            setPasswordLabelRedWords("");
        }

        if (password.length < 8) {
            setPasswordLabelRedWords(t("errorPush.Password must be atleast 8 characters!"));
            errors.push(t("errorPush.- Password must be atleast 8 characters!"));
        } else {
            setPasswordLabelRedWords("");
        }

        if (!confirmPassword) {
            setConfirmPasswordLabelRedWords(t("errorPush.Confirm Password field cannot be empty!"));
            errors.push(t("errorPush.- Confirm Password field cannot be empty!"));
        } else {
            setConfirmPasswordLabelRedWords("");
        }

        if (password != confirmPassword) {
            setConfirmPasswordLabelRedWords(t("errorPush.Confirm password must matched with New Password!"));
            errors.push(t("errorPush.- Confirm password must matched with New Password!"));
        } else {
            setConfirmPasswordLabelRedWords("");
        }

        if (errors.length > 0) {
        } else {
            //console.log('reset password and send user to log in page lor ~')

            if (validLink) {
                await updatePassword()
            } else {
                alert(t('error.This link is not valid anymore. Please try again.'))
                navigate('/signin')
            }

        }


    }

    const handlePasswordChange = async (event) => {
        setPassword(event.target.value);
    }
    const handleConfirmPasswordChange = async (event) => {
        setConfirmPassword(event.target.value);
    }

    useEffect(() => {
        // let queryParams = queryString.parse(location.search);
        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value from parameter
        const paramPhoneNumber = searchParams.get('phoneNumber');
        const email = searchParams.get("email");

        const resetid = searchParams.get("resetid");
        const timeid = searchParams.get("tid");
        const randomid = searchParams.get("id");



        //console.log('paramPhoneNumber', paramPhoneNumber)
        //console.log('email', email)
        //console.log('randomid', randomid)
        //console.log('resetid', resetid)
        //console.log('timeid', timeid)

        if (paramPhoneNumber) {
            const phoneHash = SHA256(paramPhoneNumber);

            const salt = SHA256(phoneHash + timeid + randomid).toString();

            if (salt == resetid) {
                //console.log('phone salt == resetid, gonna set the modal false')
                setValidLink(true)
                checkPhoneNumberValid(paramPhoneNumber)
                setPhoneNumber(paramPhoneNumber)
            } else {
                alert(t('error.This link is not available anymore, please go through reset password steps again.'))
                navigate('/signin')
            }


        }
        if (email) {

            const emailHash = SHA256(email);

            const salt = SHA256(emailHash + timeid + randomid).toString();

            if (salt == resetid) {
                //console.log('email salt == resetid, gonna set the modal false')
                setValidLink(true)
                checkEmailValid(email)
                setParamEmail(email)
            } else {
                alert(t('error.This link is not available anymore, please go through reset password steps again.'))
                navigate('/signin')
            }

        }


    }, []);


    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('resetPassword.Reset Password')}</h5>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <span style={{ textAlign: 'center' }}>{paramEmail ? `Email :` : `Phone number :`} <h3>{paramEmail ? `${paramEmail}` : `${phoneNumber}`}</h3></span>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="mt-3">
                                        <label htmlFor="password">{t('resetPassword.New Password')}</label>
                                        <div className="form-control" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <input
                                                className="form-control shadow-none"
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                style={{ paddingLeft: 0, marginLeft: 0, borderWidth: 0 }}
                                                required
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        confirmRef.current.focus();
                                                    }
                                                }}
                                            />
                                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                                setShowPassword(!showPassword)
                                            }}>
                                                {
                                                    showPassword ?
                                                        <img
                                                            src={(require('../../img/icons/showpassword.png'))}
                                                            style={{
                                                                height: 22, width: 22
                                                            }}
                                                        />
                                                        :
                                                        <img
                                                            src={(require('../../img/icons/hidepassword.png'))}
                                                            style={{
                                                                height: 18, width: 18
                                                            }}
                                                        />
                                                }

                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'left' }}>
                                            <span style={{ color: COLORS.red }}>{passwordLabelRedWords}</span>
                                        </div>
                                    </div>


                                    <div className="mt-3">
                                        <label htmlFor="password">{t('resetPassword.Confirm Password')}</label>
                                        <div className="form-control" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <input
                                                className="form-control shadow-none"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                ref={confirmRef}
                                                id="password"
                                                name="password"
                                                value={confirmPassword}
                                                onChange={handleConfirmPasswordChange}
                                                style={{ paddingLeft: 0, marginLeft: 0, borderWidth: 0 }}
                                                required
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleFormSubmit()
                                                    }
                                                }}
                                            />
                                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                                setShowConfirmPassword(!showConfirmPassword)
                                            }}>
                                                {
                                                    showConfirmPassword ?
                                                        <img
                                                            src={(require('../../img/icons/showpassword.png'))}
                                                            style={{
                                                                height: 22, width: 22
                                                            }}
                                                        />
                                                        :
                                                        <img
                                                            src={(require('../../img/icons/hidepassword.png'))}
                                                            style={{
                                                                height: 18, width: 18
                                                            }}
                                                        />
                                                }

                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'left' }}>
                                            <span style={{ color: COLORS.red }}>{confirmPasswordLabelRedWords}</span>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <Row className="justify-content-center align-items-center">
                                            <Col>
                                                <Button className='orange-btn text-center' onClick={() => handleFormSubmit()}>{t('resetPassword.Reset Password')}</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ResetPassword