import React from 'react';
import { Container, Row, Col, Image, Badge } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme'

function MessageUserCard({ photo, name, date, msg, hasRead, isSelected, unreadCount, onClick }) {

  return (

    <div onClick={onClick}>

      <Row>
        <Col xs={3} md={2}>
          <Image src={photo} className='round-profile-image' />
        </Col>

        <Col xs={6} md={6}>
          <div className='fw-bold smaller-font' >{name}</div>
          <div className='smaller-font'>{msg}</div>
        </Col>

        <Col xs={3} md={4}>
          <div className='caption-small-text'>{date}</div>
          {unreadCount > 0 && (
            <Badge bg='danger' className='mt-1'>{unreadCount}</Badge>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <hr style={{ borderTop: '1px solid #ccc' }} />
        </Col>
      </Row>



    </div >
  );
}

export default MessageUserCard;
