import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
// import { div, div } from 'react';
import { SIZES, COLORS, FONTS } from '../colorTheme/colorTheme';
import { Button, Container, Row, Col, Image, Dropdown } from 'react-bootstrap';
import domainName from '../domainName';
import AdvisorCard from '../components/AdvisorCard';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useNavigate, useDispatch, json } from "react-router-dom";
import Slider from 'react-slider';
import Banner from '../components/Banner';
import { useTranslation } from "react-i18next";

const ServiceListing = ({ }) => {


    const [t, i18n] = useTranslation("global");

    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    // const dispatch = useDispatch();
    const navigate = useNavigate();
    //get latitude and longitude
    const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);

    // params from previous page
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
    // const [category, setCategory] = useState([]); // the details of category which user clicked and come from // open when needed
    const [categoryid, setCategoryId] = useState();
    const [fromHomePageListingCategory, setFromHomePageListingCategory] = useState();
    const [subCategoryLists, setSubCategoryList] = useState([]);

    const [activeIndex, setActiveIndex] = useState(0);
    // param from filter or category filter
    const [categoryName, setCategoryName] = useState(t('serviceListing.All Categories'));
    const [distanceFiltering, setDistanceFiltering] = useState(50);
    const [rateStar, setRateStar] = useState(0);
    const [categoryFiltering, setCategoryFiltering] = useState();


    //set data from api
    const [serviceListings, setServiceListings] = useState([]);
    //clone one for filter subcategory
    const [serviceListingsForFilterSubCategory, setServiceListingsForFilterSubCategory] = useState([]);

    //banner
    const [banner, setBanner] = useState([]);

    //FilterCategories
    const [categories, setCategories] = useState([])
    //set category id which want to pass back
    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [selectedSubcategories, setSelectedSubcategories] = useState()
    const [selectedCategoryName, setSelectedCategoryName] = useState(t('serviceListing.All Categories'))

    const [selectedSubcategory, setSelectedSubcategory] = useState('Select Subcategory');

    //distance
    const [distanceValue, setDistanceValue] = useState(50)

    const handleCategoryChange = (e) => {
        if (e.target.value === '') {
            setCategoryId('');
            setSelectedCategoryId(undefined);
            setSelectedSubcategories(undefined);
            setSelectedCategoryName(t('serviceListing.All Categories'));
            setSelectedSubcategory(undefined);
        } else {
            const selectedCategory = categories.find((category) => category._id === e.target.value);
            setCategoryId(selectedCategory._id);
            setSelectedCategoryId(selectedCategory._id);
            setSelectedSubcategories(selectedCategory?.SubCategoryList ?? []);
            setSelectedCategoryName(selectedCategory?.CategoryName ?? t('serviceListing.All Categories'));
            setSelectedSubcategory('Select Subcategory');
        }
    };

    const handleApplyButtonClick = () => {
        //console.log('selectedCategoryId', selectedCategoryId);
        setCategoryId(selectedCategoryId);
        // navigate('/ServiceListing', {
        //     // category: item,
        //     categoryId: selectedCategoryId,
        //     SubCategoryList: selectedSubcategories,
        //     categoryName: selectedCategoryName
        // })
    };

    const handleClearButtonClick = () => {
        setCategoryId(undefined);
        setSelectedCategoryId();
        setSelectedSubcategories();
        setSelectedCategoryName(t('serviceListing.All Categories'));
    };



    const handleSubcategorySelect = async (item) => {
        //console.log('Sub_CategoryKey here -> ', item.Sub_CategoryKey);

        const filterList = await filterServiceListWithSubcategory(item.Sub_CategoryKey);

        setServiceListingsForFilterSubCategory(filterList);
        setSelectedSubcategory(item.Sub_CategoryName);
    };


    const getCategories = async (cid) => {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/category/listAllCategory",
                "GET"
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                setCategories(responseJson.Data)
                // console.log('abc', responseJson.Data)

                // console.log('categoryid:: ', categoryid)
                // console.log('selectedCategoryId: ', selectedCategoryId)

                if (cid) {
                    console.log('HERE???', categoryid)

                    const foundCategory = responseJson.Data.find(category => category._id === cid);

                    setSelectedSubcategories(foundCategory.SubCategoryList)
                    setSelectedCategoryId(foundCategory._id)
                    setSelectedSubcategories(foundCategory?.SubCategoryList ?? []);
                    setSelectedCategoryName(foundCategory?.CategoryName ?? t('serviceListing.All Categories'));
                    setSelectedSubcategory('Select Subcategory')
                }
            }))
            .catch((error) => {
                alert(
                    t("error.Oops!"),
                   t("error.An error occurred. Please try again later."),
                    error
                );
            });

    }

    //Filter Rating and Distance
    const [value, setValue] = useState(0);

    const handleSelectChange = (event) => {
        setCategories(event.target.value);

    };

    const handleRatingChange = (event) => {
        setRateStar(event.target.value);
    }

    const handleSliderChange = (newValue) => {
        setValue(newValue);
    };

    // Rating Filter Options
    const ratingOptions = [
        { value: 5, label: t('serviceListing.5 stars') },
        { value: 4, label: t('serviceListing.4 stars') },
        { value: 3, label: t('serviceListing.3 stars & above') },
        { value: 2, label: t('serviceListing.2 stars & above') },
        { value: 1, label: t('serviceListing.1 star & above') },
    ];

    const sliderStyles = {
        track: {
            marginTop: '3%',
            backgroundColor: COLORS.orange,
            height: 2
        },
        thumb: {
            backgroundColor: COLORS.orange,
            width: 20,
            height: 20,
            borderRadius: '50%',
        }
    };
    const [view, setView] = useState('');
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setView('mobile');
            } else if (width >= 768 && width < 900) {
                setView('tab');
            } else if (width >= 900 && width < 1200) {
                setView('laptop');
            } else if (width >= 1100 && width <= 3000) {
                setView('monitor');
            } else {
                setView('default');
            }
        };
        //For the website be more responsive
        handleResize();
        // For resize
        window.addEventListener('resize', handleResize);
        // For cleaning up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function renderDistance() {
        const distanceOptions = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);

        return (
            <div className="dropdown">
                {/* Category dropdown */}
                <div
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                        backgroundColor: COLORS.white,
                        borderColor: COLORS.lightGray1,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        color: COLORS.gray,
                        display: 'inline-block',
                        paddingLeft: '12%',
                        paddingRight: '15%',
                        paddingTop: '5%',
                        paddingBottom: '5%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        borderRadius: 5,
                        height: '45px',
                        width: '200px'
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                            src={require('../img/icons/distance.png')}
                            style={{ width: 15, height: 15, alignSelf: 'center', marginRight: '5%' }}
                        />
                        <span style={{ fontSize: 18 }}>{t('serviceListing.Within')} {distanceValue}km</span>
                    </div>
                </div>

                <ul className="dropdown-menu">
                    {distanceOptions.map((option) => (
                        <li key={option}>
                            <a
                                className={`dropdown-item`}
                                href="#"
                                onClick={() => {
                                    setDistanceValue(option);
                                    setSelectedSubcategory('Select Subcategory');
                                }}
                            >
                                {t('serviceListing.Within')} {option}km
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    // Rating Button/ Option
    function renderRatings() {
        return (


            <div className="dropdown">
                {/* Category dropdown */}
                <div
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                        backgroundColor: COLORS.white,
                        borderColor: COLORS.lightGray1,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        color: COLORS.gray,
                        display: 'inline-block',
                        paddingLeft: '12%',
                        paddingRight: '15%',
                        paddingTop: '5%',
                        paddingBottom: '5%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        borderRadius: 5,
                        height: '45px',
                        width: '200px'
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img
                            src={require('../img/icons/filter.png')}
                            style={{ width: 15, height: 15, alignSelf: 'center', marginRight: '5%' }}
                        />
                        <span style={{ fontSize: 18 }}>{rateStar ? `${t("serviceListing.Rating")} ${rateStar}` : t('serviceListing.All Ratings')}</span>
                    </div>

                </div>

                <ul className="dropdown-menu">
                    <li>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => handleRatingChange({ target: { value: '' } })}
                        >
                            {t('serviceListing.All Rating')}
                        </a>
                    </li>
                    {ratingOptions.map((option) => (
                        <li key={option.value}>
                            <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleRatingChange({ target: { value: option.value } })}
                            >
                                {t('serviceListing.Rating')} {option.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    function renderSelection(categoryName, StarNum, selectedStarNum, selectedCategoryid, currentItemid) {
        let isSelected = true;
        if (currentItemid !== undefined) {
            isSelected = currentItemid === selectedCategoryId;
        } else if (StarNum !== undefined) {
            isSelected = StarNum === selectedStarNum;
        }
        return (
            <div className={`rating-item ${isSelected ? 'selected' : ''}`}>
                <span>{categoryName}</span>
            </div>
        );

    }

    async function filterServiceListWithSubcategory(subcategoryKey) {

        //console.log('subcategoryKey', subcategoryKey)
        const filteredData = serviceListings.filter((category) => {
            //console.log('category here', category)
            return category.CategoryList.some(
                (categorylist) => {
                    //console.log('categorylist here', categorylist)
                    return categorylist.SubCategoryList.some((subCategorylist) => {
                        //console.log('subCategorylist here', subCategorylist)
                        return subCategorylist.Sub_CategoryKey == subcategoryKey
                    })
                })
        });

        //console.log(filteredData, typeof filteredData) // object array

        return filteredData
    }

    async function getBannerImage(categoryid) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/banner/listAllBanner" + `${categoryid != undefined ? `?CategoryId=${categoryid}` : ''}`,
                "GET",
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log("/banner/listAllBanner", categoryid != undefined ? `?CategoryId=${categoryid}` : '', responseJson.Data)
                setBanner(responseJson.Data)
            }))
            .catch((error) => {
                alert(
                   t("error.Oops!"),
                    t("error.An error occurred. Please try again later."),
                    error
                );
            });
    }

    async function getServiceListingByCategoryId(categoryid, homelistingCategory, keywordParam) {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/exploreService",
                "POST",
                JSON.stringify({
                    // "FilterCategoryId": categoryid == "" ? null : categoryid,
                    "FilterCategoryId": categoryid ? categoryid : null,
                    "FilterRating0": 0 >= rateStar ? true : false,
                    "FilterRating1": 1 >= rateStar ? true : false,
                    "FilterRating2": 2 >= rateStar ? true : false,
                    "FilterRating3": 3 >= rateStar ? true : false,
                    "FilterRating4": 4 >= rateStar ? true : false,
                    "FilterRating5": 5 >= rateStar ? true : false,
                    "FilterDistance": distanceValue,
                    "PageNum": 1,
                    "ResultNum": 100,
                    "Keyword": keywordParam,
                    "IsSearchPopulor": true, // if other day will insert one more into the {nearby,popular,rating) , then this sorting cannot use anymore
                    "IsSearchRating": true // if other day will insert one more into the {nearby,popular,rating) , then this sorting cannot use anymore
                }),
                UserId,
                latitude,
                longitude

            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/service/exploreHomeListing -> response : ', responseJson.Data)

                setServiceListings(responseJson.Data)
                setServiceListingsForFilterSubCategory(responseJson.Data)

                if (!keywordParam) {
                    setIsInitialLoadComplete(true);
                }
            }))
            .catch((error) => {
                alert(
                    t("error.Oops!"),
                    t("error.An error occurred. Please try again later."),
                    error
                );
            });
    }

    function renderSubCategoryList() {

        return (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1%' }}>
                {/* Render subcategories */}
                {selectedSubcategories && selectedSubcategories.map((item, index) => (
                    <div key={index} onClick={() => handleSubcategorySelect(item)} style={{
                        cursor: 'pointer',
                        backgroundColor: selectedSubcategory === item.Sub_CategoryName ? COLORS.lightOrange : COLORS.lightGray2,
                        padding: '5px 15px',
                        borderRadius: 18, borderWidth: '1px', borderStyle: 'solid',
                        borderColor: selectedSubcategory === item.Sub_CategoryName ? COLORS.orange : 'transparent',
                        marginTop: '1%'
                    }}>
                        {item.Sub_CategoryName}
                    </div>
                ))}
            </div>
        );
    }

    function renderAdvisorListing() {
        return (
            <Row >
              
                {serviceListingsForFilterSubCategory.map((service, index) => (
                    <AdvisorCard key={index} item={service} />
                    // <div>
                    //     {JSON.stringify(item.CategoryDisplayList)}
                    //     <div className="image-container">
                    //         <Image src={item.ServiceData.CoverImageDisplay} alt="Image " fluid />
                    //     </div>
                    // </div>
                ))}
            
            </Row>
        )
    }

    function renderBanner() {

        return (
            <>
                {/* banner here */}
                < Banner bannerObj={banner} />
            </>
        )
    }

    function renderHeader() {
        return (
            <div className="dropdown" style={{}}>
                {/* Category dropdown */}
                <div
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                        backgroundColor: COLORS.white,
                        borderColor: COLORS.lightGray1,
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        color: COLORS.gray,
                        paddingLeft: '12%',
                        paddingRight: '15%',
                        paddingTop: '5%',
                        paddingBottom: '5%',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        borderRadius: 5,
                        height: '45px',
                        width: '200px'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <img
                            src={require('../img/icons/category.png')}
                            style={{ width: 15, height: 15, alignSelf: 'center', marginRight: '5%' }}
                        />
                        <span style={{ fontSize: 18 }}>{selectedCategoryName}</span>
                    </div>


                </div>

                <ul className="dropdown-menu">
                    <li>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => handleCategoryChange({ target: { value: '' } })}
                        >
                            {t('serviceListing.All Categories')}
                        </a>
                    </li>
                    {categories.map((option) => (
                        <li key={option._id}>
                            <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => handleCategoryChange({ target: { value: option._id } })}
                            >
                                {option.CategoryName}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

        )
    }

    // function renderHeader() {
    //     return (
    //         <div>
    //             <div className="container">

    //                 <select value={selectedCategoryId} onChange={(e) => {
    //                     // console.log("e.target key > ",e.target.key)
    //                     // setSelectedCategoryId(e.target.value);

    //                     if (e.target.value == "") { // all categories is selected
    //                         setCategoryId("")
    //                         setSelectedCategoryId(undefined)
    //                         setSelectedSubcategories(undefined);
    //                         setSelectedCategoryName('All Categories');
    //                         setSelectedSubcategory(undefined)

    //                     } else {
    //                         console.log("categories,e.target", categories, e.target.value)
    //                         const selectedCategory = categories.find((category) => category._id === e.target.value);
    //                         // const selectedCategory = categories.find((category) => console.log("category.CategoryName",category.categoryName) );
    //                         console.log("selectedCategory", selectedCategory)
    //                         setCategoryId(selectedCategory._id)
    //                         setSelectedCategoryId(selectedCategory._id)
    //                         setSelectedSubcategories(selectedCategory?.SubCategoryList ?? []);
    //                         setSelectedCategoryName(selectedCategory?.CategoryName ?? 'All Categories');

    //                         setSelectedSubcategory('Select Subcategory')
    //                     }

    //                 }}>
    //                     <option value="">All Categories</option>
    //                     {categories.map((option) => (

    //                         <option key={option.CategoryName} value={option._id}>
    //                             {option.CategoryName}
    //                         </option>
    //                     ))}
    //                 </select>
    //                 <Button
    //                     onClick={() => {
    //                         console.log('selectedCategoryId', selectedCategoryId)
    //                         setCategoryId(selectedCategoryId)
    //                         // navigate('/ServiceListing', {
    //                         //     // category: item,
    //                         //     categoryId: selectedCategoryId,
    //                         //     SubCategoryList: selectedSubcategories,
    //                         //     categoryName: selectedCategoryName
    //                         // })
    //                     }}
    //                 >
    //                     <div>Apply</div>
    //                 </Button>

    //                 <Button onClick={() => {
    //                     setCategoryId(undefined)
    //                     setSelectedCategoryId()

    //                     setSelectedSubcategories()
    //                     setSelectedCategoryName('All Categories')
    //                 }}>
    //                     <div>Clear</div>

    //                 </Button>

    //             </div>
    //             <div>
    //             </div>

    //         </div>
    //     )

    // }

    useEffect(() => {

        const handleResize = () => {
            setIsWebsite(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);


        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const category = searchParams.get('category');
        // const keywordParam = searchParams.get('keyword');

        // setKeyWord(keywordParam)

        const categoryItem = JSON.parse(decodeURIComponent(category));
        // Do something with the serviceId value
        //console.log("category in servicelisting page", JSON.stringify(categoryItem));

        if (categoryItem && categoryid == undefined) {

            //console.log("categoryItem && categoryid == undefined")


            getBannerImage(categoryItem._id)


            getServiceListingByCategoryId(categoryItem._id, undefined)

            getCategories(categoryItem._id)
        } else {
            //console.log("!categoryItem && categoryid !== undefined")
            getCategories()
            getBannerImage(categoryid)
            getServiceListingByCategoryId(categoryid, undefined)
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, [categoryid, rateStar, distanceValue, i18n]);

    useEffect(() => {

        if (isInitialLoadComplete) {
            //console.log("isInitialLoadComplete")
            const searchParams = new URLSearchParams(window.location.search);

            // const category = searchParams.get('category');P
            const keywordParam = searchParams.get('keyword');
            const category = searchParams.get('category');

            if (!category) {
                getServiceListingByCategoryId(undefined, undefined, keywordParam)
            }
            // const categoryItem = JSON.parse(decodeURIComponent(category));
        }

    }, [isInitialLoadComplete])


    return (
        <Container className="width95">


            {
                banner && <Row> {renderBanner()} </Row>
            }


            <div style={{ paddingTop: '1%', borderWidth: '1px', paddingBottom: '1%', marginBottom: '1%', borderStyle: 'solid', borderWidth: 0, borderBottomWidth: '1px', borderColor: COLORS.lightGray1 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1%', flexGrow: 1 }}>
                    {renderHeader()}
                    {renderRatings()}
                    {renderDistance()}
                </div>
            </div>

            {
                selectedSubcategory &&
                <div className='mt-3 mt-md-0' style={{ marginBottom: '2%', padding: '0.2%', paddingLeft: 0 }}>
                    {renderSubCategoryList()}
                </div>
            }

            <Row className='mt-3 mt-md-0'style={{minHeight: "100vh"}}>
                {renderAdvisorListing()}
            </Row>

        </Container>

    )

}
export default ServiceListing