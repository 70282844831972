import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import routing
import { Route, Routes } from 'react-router-dom';

import domainName from './domainName';
// 123
// import component
import Navigation from './components/Navigation';
import DownloadBar from './components/DownloadBar';
import Account from './pages/Account';
import Home from './pages/Home';
import Signin from './pages/SharedScreens/Signin';
import Signup from './pages/SharedScreens/Signup';
import AdvisorDetails from './pages/AdvisorDetails';
import BecomeAdvisor from './pages/BecomeAdvisor';
import SubscriptionStepOne from './pages/SubscriptionPlan/SubscriptionStepOne';
import SubscriptionStepTwo from './pages/SubscriptionPlan/SubscriptionStepTwo';
import SubscriptionStepThree from './pages/SubscriptionPlan/SubscriptionStepThree';
import SubscriptionStepFour from './pages/SubscriptionPlan/SubscriptionStepFour';

import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import Terms from './pages/Terms';

import Community from './pages/SharedScreens/Community';

import ServiceListing from './pages/ServiceListing';

import CommunityPostDetails from './pages/SharedScreens/CommunityPostDetails';
import PostRequest from './pages/PostRequest';


import { AuthContextProvider } from './context/AuthContext';
import Protected from './components/Protected';

import { useDispatch, useSelector } from "react-redux";
import { logInSuccess } from './redux/slices/login';
import { insertAnonymousUserId } from './redux/slices/login';

import EnterVerificationCode from './pages/SharedScreens/EnterVerificationCode';
import SignUpSuccessful from './pages/SharedScreens/SignUpSuccessful';
import PopUserVerifyEmail from './pages/SharedScreens/PopUserVerifyEmail';
import ForgotPassword from './pages/SharedScreens/ForgotPassword';
import ResetPassword from './pages/SharedScreens/ResetPassword';
import VerifyEmail from './pages/VerifyEmail';
import ChangeEmailVerification from './pages/ChangeEmailVerification';

import MyProfile from './pages/SharedScreens/MyProfile';
import MyReviews from './pages/SharedScreens/MyReviews';
import AccountAndSecurity from './pages/SharedScreens/AccountAndSecurity';
import MyBookings from './pages/MyBookings';
import BookingDetailsUser from './pages/BookingDetailsUser';
import RateAndReview from './pages/SharedScreens/RateAndReview';

//screen guest and consumer
import Favourite from './pages/GuestAndConsumerScreens/Favourite';
import MyRequest from './pages/GuestAndConsumerScreens/MyRequest';
import ChangeLocation from './pages/GuestAndConsumerScreens/ChangeLocation';

//advisor
import NewRequestListings from './pages/AdvisorScreens/NewRequestListings';
import NewRequestDetails from './pages/AdvisorScreens/NewRequestDetails';
import BookingsAdvisor from './pages/AdvisorScreens/BookingsAdvisor';
import BookingsAdvisorDetails from './pages/AdvisorScreens/BookingsAdvisorDetails';
import MyListings from './pages/AdvisorScreens/MyListings';
import ServiceManagement from './pages/AdvisorScreens/ServiceManagement';

import SubscriptionPage from './pages/Subscription/index';
import ReferRewardsPage from './pages/ReferRewards/index';
import MessageAndNotificationPage from './pages/MessageAndNotification/index';
import Footer from './components/Footer';

// here is all footer
import Blog from './pages/WordpressPage/Blog';
import AboutUs from './pages/WordpressPage/AboutUs';
import Career from './pages/WordpressPage/Career';
import FutureEmployee from './pages/WordpressPage/FutureEmployee';
import ForAdvisers from './pages/WordpressPage/ForAdvisers';
import Policies from './pages/WordpressPage/Policies';
import PrivacyPolicies from './pages/WordpressPage/PrivacyPolicies';
import ForInvestors from './pages/WordpressPage/ForInvestors';
import AmbassadorProgramme from './pages/WordpressPage/AmbassadorProgramme';

import { setLatitude, setLongitude } from './redux/slices/login';
import { useTranslation } from "react-i18next";

function App() {
  console.log('app running')

  const dispatch = useDispatch();
  const location = useLocation();

  const [isFromAppUser, setIsFromAppUser] = useState(false);
  const isCommunityPage = location.pathname.includes('/community');
  const isMessagePage = location.pathname.includes('/message');
  const searchParams = new URLSearchParams(location.search);


  const { token, UserData, UserId } = useSelector((state) => state.login);

  const [isMobile, setIsMobile] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

  // Retrieving 'advisor' state from local storage
  const advisorState = JSON.parse(localStorage.getItem('advisorState'));

  const [t] = useTranslation("global");


  if (advisorState == undefined || advisorState == null || advisorState == "") {
    // Storing 'advisor' state in local storage
    localStorage.setItem('advisorState', JSON.stringify({ advisor: false, user: true }));
  }

  async function insertAnonymouseUser() {
    //console.log("UserId", UserId)

    if (UserId == "") {
      //console.log('(userState.UserId == "")')
      //do insert anonymous hereeee
      await domainName
        .serverAPICall(
          domainName.debugAPI + "/user/insertAnonymouseUser",
          "POST",
          // JSON.stringify({
          // })
        )
        .then(domainName.handleServerAPICallErrors)
        .then((responseJson => {

          //return data here
          //console.log('responseJson in /user/insertAnonymouseUser', responseJson);

          //here to do dispatch(AutoLogin .. something)
          if (responseJson.Data != null) {

            dispatch(insertAnonymousUserId(responseJson.Data._id))

            // Storing 'advisor' state in local storage
            localStorage.setItem('advisorState', JSON.stringify({ advisor: false, user: true }));

          } else {
            alert(responseJson.ReturnDesc);
          }
          // anonymous still remain true

        }))
        .catch((error) => {
          alert(t("error.An error occurred. Please try again later."));
        });

    } else {
      //console.log('(userState.UserId != "")')
    }
  }

  useEffect(() => {
    //app beginning here, so if userData null then insert id to it
    insertAnonymouseUser()
    const isFromApp = searchParams.get('isTarget') ? searchParams.get('isTarget') == 'app' : false;
    console.log('AAAA', isFromApp)
    setIsFromAppUser(isFromApp)
  }, [])

  return (
    // <div className="App">
    //   <header className="App-header">

    <div className="app-container">

      <AuthContextProvider>



        {/* {<DownloadBar/>} */}
        {/* navigation bar */}
        {(!isCommunityPage && !isMessagePage) && <Navigation />}
        <div className="content">

          {/* route to other screen here */}
          <Routes >
            {/* Conditionally render DownloadBar only on the Home page */}
            <Route
              path='/'
              element={
                <>
                  <DownloadBar />
                  <Home />
                </>
              }
            />
            <Route path='/ServiceListing' element={<ServiceListing />} />

            {/* those working route put here */}
            <Route path='/becomeAnAdvisor' element={<SubscriptionStepOne />} />
            <Route path='/chooseListing' element={<SubscriptionStepTwo />} />
            <Route path='/billingDetails' element={<SubscriptionStepThree />} />
            <Route path='/subscriptionSuccessful' element={<SubscriptionStepFour />} />

            <Route path='/subscription' element={<SubscriptionPage />} />
            <Route path='/referReward' element={<ReferRewardsPage />} />

            <Route path='/message' element={<MessageAndNotificationPage />} />

            <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
            <Route path='/ContactUs' element={<ContactUs />} />
            <Route path='/Terms' element={<Terms />} />

            <Route path='/signin' element={<Signin />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/verifyEmail' element={<VerifyEmail />} />
            <Route path='/changeEmailVerification' element={<ChangeEmailVerification />} />

            <Route path='/account' element={<Protected ><Account /></Protected>} />
            <Route path='/servicedetails' element={<AdvisorDetails />} />
            <Route path='/postRequest' element={<Protected ><PostRequest /></Protected>} />
            <Route path='/community' element={<Community />} />
            <Route path='/communityPostDetails' element={<CommunityPostDetails />} />

            <Route path='/enterverificationcode' element={<EnterVerificationCode />} />
            <Route path='/signupsuccess' element={<SignUpSuccessful />} />
            <Route path='/popuserverifyemail' element={<PopUserVerifyEmail />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/resetpassword' element={<ResetPassword />} />

            <Route path='/myProfile' element={<Protected ><MyProfile /></Protected>} />
            <Route path='/myReview' element={<Protected ><MyReviews /></Protected>} />
            <Route path='/accountAndSecurity' element={<Protected ><AccountAndSecurity /></Protected>} />
            <Route path='/mybookings' element={<Protected ><MyBookings /></Protected>} />
            <Route path='/bookingDetailsUser' element={<Protected ><BookingDetailsUser /></Protected>} />
            <Route path='/reviewrating' element={<Protected ><RateAndReview /></Protected>} />
            <Route path='/favourite' element={<Protected ><Favourite /></Protected>} />
            <Route path='/requestlisting' element={<Protected ><MyRequest /></Protected>} />
            <Route path='/changelocation' element={<ChangeLocation />} />

            {/* <Route path='/' component={ComingSoon} /> */}
            {/* <Route path='/ServiceListing' component={ComingSoon} /> */}
            <Route path='/newrequestadvisor' element={<Protected ><NewRequestListings /></Protected>} />
            <Route path='/newRequestAdvisorDetails' element={<Protected ><NewRequestDetails /></Protected>} />
            <Route path='/bookinglistAdvisor' element={<Protected ><BookingsAdvisor /></Protected>} />
            <Route path='/bookingDetailsAdvisor' element={<Protected ><BookingsAdvisorDetails /></Protected>} />
            <Route path='/myListingsAdvisor' element={<Protected ><MyListings /></Protected>} />
            <Route path='/manageservice' element={<Protected ><ServiceManagement /></Protected>} />

            <Route path='/Blog' element={<Blog />} />
            <Route path='/AboutUs' element={<AboutUs />} />
            <Route path='/Career' element={<Career />} />
            <Route path='/FutureEmployee' element={<FutureEmployee />} />
            <Route path='/ForAdvisers' element={<ForAdvisers />} />
            <Route path='/Policies' element={<Policies />} />
            <Route path='/PrivacyPolicies' element={<PrivacyPolicies />} />
            <Route path='/ForInvestors' element={<ForInvestors />} />
            <Route path='/AmbassadorProgramme' element={<AmbassadorProgramme />} />
          </Routes>
        </div>

        {/* Conditionally render Footer based on the route and device type */}
        {/* {(!isMessagePage || !isMobile ) || !isFromAppUser && <Footer />} */}
        {!isFromAppUser && <Footer />}
      </AuthContextProvider>
    </div>

  );
}


export default App;
