import React, { useState, useEffect, useRef } from 'react'
import queryString from 'query-string';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import domainName from '../../domainName';
import { COLORS } from '../../colorTheme/colorTheme';
import AWS from 'aws-sdk';
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

import Cropper, { ReactCropperElement } from "react-cropper";
import "../../../node_modules/cropperjs/dist/cropper.css";


const ServiceManagement = () => {

    const [t] = useTranslation("global");

    const accessKey = process.env.REACT_APP_S3_ACCESS_KEY;
    const secretKey = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

    AWS.config.update({
        accessKeyId: accessKey, // 2nd
        secretAccessKey: secretKey, // 2nd 
    });

    const s3 = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_S3_BUCKET },
        region: "ap-southeast-1",
    })

    const navigate = useNavigate();
    const location = useLocation();
    const { token, UserData, UserId } = useSelector((state) => state.login);

    //for toggle
    const [isPublicListingOnline, setIsPublicListingOnline] = useState(false)

    const [insertServiceAction, setInsertServiceAction] = useState(true)

    // check user limitation 
    const [maxVideo, setMaxVideo] = useState(0)
    const [maxPhoto, setMaxPhoto] = useState(0)
    const [maxLocation, setMaxLocation] = useState(0)

    //user info
    const [whatsAppNumber, setWhatsAppNumber] = useState();

    // ser service id from edit service
    const [serviceId, setServiceId] = useState()

    //categories
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState()
    const [SubCategoryList, setSubcategoryList] = useState([])
    const [selectedCategoryID, setSelectedCategoryID] = useState()
    const [selectedCategoryKey, setSelectedCategoryKey] = useState()
    const [selectedSubCategoryList, setSelectedSubcategoryList] = useState([])

    //description
    const [description, setDescription] = useState('')

    //title
    const [titleValue, setTitleValue] = useState('')

    // for address location
    const [address, setAddress] = useState([]);
    const [addressValid, setAddressValid] = useState(Array(maxLocation).fill(false));
    const [locationList, setLocationList] = useState([])

    // for cert and award
    const [certificates, setCertificates] = useState([]);
    const [awards, setAwards] = useState([]);
    const certRef = useRef(null);
    const awardRef = useRef(null);
    const cropperRef = useRef(null);
    //for images and videos
    const [coverListingFiles, setCoverListingFiles] = useState([]);

    const [coverListingFilesBackUp, setCoverListingFilesBackUp] = useState()
    const [multiplePhotosFiles, setMultiplePhotosFiles] = useState([]);
    const [multipleVideosFiles, setMultipleVideosFiles] = useState([]);
    const [image, setImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [cropper, setCropper] = useState();

    const [cropModalVisible, setCropModalVisible] = useState(false);

    const onDrop = acceptedFiles => {
        const reader = new FileReader();
        reader.onload = () => {
            setCoverListingFiles([...acceptedFiles]);
        };
        reader.readAsDataURL(acceptedFiles[0]);
    };

    const cropImage = () => {
        if (cropperRef.current && cropperRef.current.cropper.getCroppedCanvas) {
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();

            if (croppedCanvas) {
                const croppedDataURL = croppedCanvas.toDataURL();
                setCroppedImage(croppedDataURL);
                setCropModalVisible(false);
                setCoverListingFiles([croppedDataURL]);
            } else {
                console.error("Error getting cropped canvas");
            }
        } else {
            console.error("Cropper is not initialized or method not available yet");
        }

    };



    const uploadCoverListing = async (croppedImageData) => {
        console.log('got do here??')
        return new Promise((resolve, reject) => {
            const fileExtension = 'png'; // You might want to change this based on your preferences

            const milliseconds = new Date().getTime();
            const fileName = `${milliseconds}.${fileExtension}`;

            const blob = dataURLtoBlob(croppedImageData);

            const params = {
                region: "ap-southeast-1",
                ACL: 'public-read',
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: 'service/' + fileName,
                Body: blob,
                ContentType: 'image/png', // Adjust the content type based on the image format
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error(t('error.Error uploading picture:'), err);
                    reject(err);
                } else {
                    resolve(data.Location.split('/').pop());
                }
            });
        });
    };


    function dataURLtoBlob(dataURL) {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }


    //for modal
    const [modalTitle, setModalTitle] = useState(t('serviceManagement.Uploading...'))
    const [modalMessage, setModalMessage] = useState(t('serviceManagement.Process of uploading all the necessary information ...'))
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [loading, setLoading] = useState(false)

    //for address checking based on plan
    const [countryCodeFromPlan, setCountryCodeFromPlan] = useState()

    //for changing award and certificate name modal
    const [showChangeAwardCertificateNameModal, setShowChangeAwardCertificateNameModal] = useState(false)
    const [displayName, setDisplayName] = useState()
    const [isChangeNameCertificate, setIsChangeNameCertificate] = useState()
    const [indexForChangeName, setIndexForChangeName] = useState()

    //for upload to s3 bucket
    const uploadCertsAwards = async (files, isCertificate) => {
        //console.log('files in uploadCertsAwards', files);
        const uploadPromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                const milliseconds = new Date().getTime();
                const fileName = `${milliseconds}.${fileExtension}`;

                const params = {
                    region: "ap-southeast-1",
                    ACL: 'public-read',
                    Bucket: process.env.REACT_APP_S3_BUCKET,
                    Key: `${isCertificate ? 'certificates/' : 'awards/'}${fileName}`,
                    Body: file,
                    ContentType: file.type,
                };

                //console.log("Uploading file:", fileName);

                s3.upload(params, (err, data) => {
                    if (err) {
                        console.error(`${t("error.Error uploading")} ${isCertificate ? 'certificates/' : 'awards/'}:`, err);
                        reject(err);
                    } else {
                        //console.log(`${isCertificate ? 'Certificates' : 'Awards'} listing image uploaded successfully:`, data.Location);

                        const resultObject = {
                            [`${isCertificate ? 'CertificatesName' : 'AwardsName'}`]: file.name.split('.')[0],
                            [`${isCertificate ? 'CertificatesImage' : 'AwardsImage'}`]: data.Location.split('/').pop()
                        };
                        resolve(resultObject);
                    }
                });
            });
        });

        try {
            const results = await Promise.all(uploadPromises);
            return results;
        } catch (error) {
            console.error(`${t("error.Error uploading files")} ${isCertificate ? 'certificates/' : 'awards/'}:`, error);
            throw error;
        }
    };

    const uploadCoverListingx = async (file) => {
        return new Promise((resolve, reject) => {
            // const fileExtension = file.type.split('/').pop();
            const fileExtension = file.name.split('.').pop().toLowerCase();
            //console.log("fileExtension", fileExtension)

            const milliseconds = new Date().getTime();
            const fileName = `${milliseconds}.${fileExtension}`;

            const params = {
                region: "ap-southeast-1",
                ACL: 'public-read',
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: 'service/' + fileName,
                // Key: 'review/' + file.name,
                Body: file,
                ContentType: file.type,
            };
            //console.log("s3", s3)

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error(t('error.Error uploading picture:'), err);
                    reject(err);
                } else {
                    //console.log('cover listing uploaded successfully:', data.Location);
                    resolve(data.Location.split('/').pop());
                }
            });

        });
    };

    const uploadListingImages = async (files) => {
        //console.log('files in uploadlistingimages', files)
        const uploadPromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                // const fileExtension = file.type.split('/').pop();
                const fileExtension = file.name.split('.').pop().toLowerCase();
                //console.log("fileExtension", fileExtension);

                const milliseconds = new Date().getTime();
                const fileName = `${milliseconds}.${fileExtension}`;

                const params = {
                    region: "ap-southeast-1",
                    ACL: 'public-read',
                    Bucket: process.env.REACT_APP_S3_BUCKET,
                    Key: 'service/' + fileName,
                    Body: file,
                    ContentType: file.type,
                };
                //console.log("s3", s3);

                s3.upload(params, (err, data) => {
                    if (err) {
                        console.error(t('error.Error uploading picture:'), err);
                        reject(err);
                    } else {
                        //console.log('listing image uploaded successfully:', data.Location);
                        resolve(data.Location.split('/').pop());
                    }
                });
            });
        });

        try {
            const results = await Promise.all(uploadPromises);
            return results;
        } catch (error) {
            // Handle any errors occurred during the file uploads
            console.error(t('error.Error uploading files:'), error);
            throw error;
        }
    };

    const uploadListingVideos = async (files) => {
        //console.log('files in uploadlistingvideos', files)
        const uploadPromises = files.map((file) => {
            return new Promise((resolve, reject) => {
                // const fileExtension = file.type.split('/').pop();
                const fileExtension = file.name.split('.').pop().toLowerCase();
                //console.log("fileExtension", fileExtension);

                const milliseconds = new Date().getTime();
                const fileName = `${milliseconds}.${fileExtension}`;

                const params = {
                    region: "ap-southeast-1",
                    ACL: 'public-read',
                    Bucket: process.env.REACT_APP_S3_BUCKET,
                    Key: 'service/' + fileName,
                    Body: file,
                    ContentType: file.type,
                };
                //console.log("s3", s3);

                s3.upload(params, (err, data) => {
                    if (err) {
                        console.error(t('error.Error uploading video:'), err);
                        reject(err);
                    } else {
                        //console.log('listing video uploaded successfully:', data.Location);
                        resolve(data.Location.split('/').pop());
                    }
                });
            });
        });

        try {
            const results = await Promise.all(uploadPromises);
            return results;
        } catch (error) {
            // Handle any errors occurred during the file uploads
            console.error(t('error.Error uploading files:'), error);
            throw error;
        }
    };


    const handleTitleValueChange = async (e) => {
        setTitleValue(e.target.value)
    }

    const handleSelect = async (selectedAddress, index) => {
        try {
            const results = await geocodeByAddress(selectedAddress);
            const latLng = await getLatLng(results[0]);

            // Extract relevant address components from the results
            const addressComponents = results[0].address_components;
            const addressDetails = {
                Address: selectedAddress,
                UnitNo: null,
                City: null,
                Region: null,
                Country: null,
                Lat: latLng.lat,
                Lng: latLng.lng,
            };

            // Loop through address components to find specific details
            addressComponents.forEach((component) => {
                if (component.types.includes('street_number')) {
                    // Set unit number if available
                    addressDetails.UnitNo = component.long_name;
                } else if (component.types.includes('locality')) {
                    // Set city if available
                    addressDetails.City = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    // Set region if available
                    addressDetails.Region = component.long_name;
                } else if (component.types.includes('country')) {
                    // Set country if available
                    addressDetails.Country = component.long_name;
                    addressDetails.CountryCode = component.short_name;
                }
            });

            // Do something with the selected address and its coordinates (latLng)
            //console.log('Selected Address:', selectedAddress);
            //console.log('Coordinates:', latLng);
            //console.log('addressDetails', addressDetails);

            // Update the address and location list at the specific index
            const updatedAddress = [...address];
            updatedAddress[index] = selectedAddress;
            setAddress(updatedAddress);

            const updatedLocationList = [...locationList];
            updatedLocationList[index] = addressDetails;
            setLocationList(updatedLocationList);

            // Update the validity at the specific index
            const updatedAddressValid = [...addressValid];
            updatedAddressValid[index] = true;
            setAddressValid(updatedAddressValid);

        } catch (error) {
            console.error(t('error.Error selecting address:'), error);
        }
    };

    // for awards and certificates
    const handleCertificateChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setCertificates([...certificates, ...selectedFiles]);
    };

    const handleAwardChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setAwards([...awards, ...selectedFiles]);
    };

    const handleDeleteCertificate = (index) => {
        const updatedCertificates = [...certificates];
        updatedCertificates.splice(index, 1);
        setCertificates(updatedCertificates);
    };

    const handleDeleteAward = (index) => {
        const updatedAwards = [...awards];
        updatedAwards.splice(index, 1);
        setAwards(updatedAwards);
    };

    // for cover listing 
    const handleCoverListingChange = (event) => {
        const selectedFile = event.target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
            setCoverListingFiles([reader.result]); // Set as an array with a single file
            setCropModalVisible(true); // Open the crop modal

            // Initialize the cropper when coverListingFiles are set
            if (cropperRef.current) {
                // Make sure cropperRef is initialized using useRef
                setCropper(cropperRef.current);
            }
        };
        reader.readAsDataURL(selectedFile);
    };



    const closeCropModal = () => {
        setCropModalVisible(false);
    };



    //for delete multiple images
    const handleDeletePhoto = (index) => {
        // Create a copy of the original array
        const updatedFiles = [...multiplePhotosFiles];

        // Remove the photo at the specified index
        updatedFiles.splice(index, 1);

        // Update the state with the modified array
        setMultiplePhotosFiles(updatedFiles);
    };


    const handleMultiplePhotosChange = (event) => {
        const selectedFiles = event.target.files;

        if (selectedFiles.length + multiplePhotosFiles.length > maxPhoto) {
            // Limit the selection to the remaining available slots
            const remainingSlots = maxPhoto - multiplePhotosFiles.length;
            const slicedFiles = Array.from(selectedFiles).slice(0, remainingSlots);
            setMultiplePhotosFiles((prevFiles) => [...prevFiles, ...slicedFiles]);
        } else {
            setMultiplePhotosFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        }
    };

    //for delete multiple videos
    const handleDeleteVideos = (index) => {
        // Create a copy of the original array
        const updatedFiles = [...multipleVideosFiles];

        // Remove the photo at the specified index
        updatedFiles.splice(index, 1);

        // Update the state with the modified array
        setMultipleVideosFiles(updatedFiles);
    };

    const handleMultipleVideosChange = (event) => {
        // const selectedFiles = event.target.files;
        // setMultipleVideosFiles([...selectedFiles]);
        const selectedFiles = event.target.files;
        if (selectedFiles.length + multipleVideosFiles.length > maxVideo) {
            // Limit the selection to the remaining available slots
            const remainingSlots = maxVideo - multipleVideosFiles.length;
            const slicedFiles = Array.from(selectedFiles).slice(0, remainingSlots);
            setMultipleVideosFiles((prevFiles) => [...prevFiles, ...slicedFiles]);
        } else {
            setMultipleVideosFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        }
    };

    async function handleSaveButton() {

        //console.log('handlesavebutton here')
        //console.log('certificates', certificates)
        //console.log('awards', awards)

        let errors = [];

        // Iterate over the addressValid array
        addressValid.forEach((valid, index) => {
            if (!valid) {
                errors.push(`${t("errorPush.- Please re-select Location")} ${index + 1} ${t("errorPush.and do not change any words")}`);
            }
        });

        if (selectedSubCategoryList == "") {
            errors.push(t("errorPush.- Please select atleast one subcategory"));
        }

        if (titleValue == "") {
            errors.push(t("errorPush.- Please filled in title"));
        }

        if (description == "") {
            errors.push(t("errorPush.- Please filled in description"));
        }

        if (certificates == "") {
            errors.push(t("errorPush.- Please upload atleast one certificate"));
        }

        // if (awards == "") {
        //     errors.push("- Please upload atleast one award");
        // }


        if (coverListingFiles == "") {
            errors.push(t("errorPush.- Please upload cover listing image"));
        }

        if (multiplePhotosFiles == "") {
            errors.push(t("errorPush.- Please upload atleast one listing image"));
        }

        if (maxVideo > 0 && multipleVideosFiles == "") {
            errors.push(t("errorPush.- Please upload atleast one listing video"));
        }

        if (errors.length > 0) {
            alert(errors.join("\n"))
        } else {

            if (insertServiceAction) {
                // do insert service
                await insertServiceFunction()
            } else {
                // do edit service
                await editServiceFunction()
            }


        }

    }

    async function editServiceFunction() {

        console.log('here 1')

        //set modal true
        setLoading(true)
        setShowSuccessModal(true)

        const advisorCategory = [{
            "CategoryName": categoryName,
            "CategoryKey": selectedCategoryKey,
            "CategoryId": selectedCategoryID,
            "Sub_Enabled": true,
            "SubCategoryList": selectedSubCategoryList
        }]
        //console.log("advisorCategory", advisorCategory)

        // for certificate
        const newCerts = certificates.filter(cert => {
            return cert.name
        });

        const previousData = certificates
            .filter(item => item.CertificatesImage) // Exclude objects without CertificatesImage property
        // .map(item => item.CertificatesImage);

        // upload cert to s3 bucket
        const newCertsUrl = await uploadCertsAwards(newCerts, true)
        const certsUrl = previousData.concat(newCertsUrl);

        // for awards
        const newAwards = awards.filter(award => {
            return award.name
        });

        const previousAwards = awards
            .filter(item => item.AwardsImage) // Exclude objects without CertificatesImage property
        // .map(item => item.AwardsImage);

        //upload cert to s3 bucket
        const newAwardsUrl = await uploadCertsAwards(newAwards, false)
        const awardsUrl = previousAwards.concat(newAwardsUrl);
        //console.log("awardsUrl", awardsUrl)

        // //upload cover listing to s3 bucket 
        let coverListingUrl;

        // if (coverListingFiles[0] == null || coverListingFiles[0] == '') {
        if (!croppedImage) {
            console.log('here 2')
            coverListingUrl = coverListingFilesBackUp
        } else {
            console.log('here 3')
            console.log(coverListingFiles)

            const coverListingUrlFromFunction = await uploadCoverListing(coverListingFiles[0])
            coverListingUrl = coverListingUrlFromFunction
        }


        // if (typeof coverListingFiles[0] === 'string') {
        //     coverListingUrl = coverListingFilesBackUp
        //     console.log('here 2')
        // } else {
        //     console.log('here 3')
        //     const coverListingUrlFromFunction = await uploadCoverListing(coverListingFiles[0])
        //     coverListingUrl = coverListingUrlFromFunction
        // }
        //console.log('coverListingUrl - >>>', coverListingUrl)

        // upload listing images to s3 bucket
        const filterPictureFromAPI = multiplePhotosFiles.filter(image => typeof image === 'string').map((image, index) => {
            return image.split('/').pop()
            // Rest of your code for processing string elements
        });
        const filterPictureFromDevice = multiplePhotosFiles.filter(image => typeof image !== 'string')
        //console.log('filterPictureFromAPI', filterPictureFromAPI)
        //console.log('filterPictureFromDevice', filterPictureFromDevice)

        const listingImagesUrlFromFunction = await uploadListingImages(filterPictureFromDevice)
        const listingImagesUrl = filterPictureFromAPI.concat(listingImagesUrlFromFunction);
        //console.log('listingImagesUrl', listingImagesUrl)

        // upload listing videos to s3 bucket
        const filterVideoFromAPI = multipleVideosFiles.filter(video => typeof video === 'string').map((video, index) => {
            return video.split('/').pop()
            // Rest of your code for processing string elements
        });
        const filterVideoFromDevice = multipleVideosFiles.filter(video => typeof video !== 'string')

        const listingVideosUrlFromFunction = await uploadListingVideos(filterVideoFromDevice)
        const listingVideosUrl = filterVideoFromAPI.concat(listingVideosUrlFromFunction);

        // call edit service api
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/editService",
                "POST",
                JSON.stringify({
                    "ServiceId": serviceId,
                    "WhatAppsMobileNo": whatsAppNumber,
                    "ServiceName": titleValue,
                    "Description": description,
                    "LocationList": locationList,
                    "Certificates": certsUrl,
                    "Awards": awardsUrl,
                    "CoverImage": coverListingUrl,
                    "ListingImage": listingImagesUrl,
                    "ListingVideo": listingVideosUrl,
                    "PosterUserId": UserId,
                    "CategoryList": advisorCategory,
                    "IsPublic": isPublicListingOnline
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/service/editService : response : ', responseJson)
                if (responseJson.ReturnCode == 200) {
                    //console.log("responseJson.ReturnCode == 200")
                    //navigate my listing
                    setModalTitle(t('serviceManagement.EditedSuccessful'))
                    setModalMessage(t('serviceManagement.EditedSuccessfulDesc'))
                    setLoading(false)
                } else if (responseJson.ReturnCode == 400) {
                    setModalTitle(t('serviceManagement.EditedUnsuccessful'))
                    setModalMessage(t('serviceManagement.UnableToProceedInactive'))
                    setLoading(false)
                } else {
                    setModalTitle(t('serviceManagement.EditedUnsuccessful'))
                    setModalMessage(t('serviceManagement.UnableToProceed'))
                    setLoading(false)
                }
            }))
            .catch((error) => {
                //console.log('/service/editService : error : ', error)
            });
    }

    async function insertServiceFunction() {

        //set modal true
        setLoading(true)
        setShowSuccessModal(true)

        const advisorCategory = [{
            "CategoryName": categoryName,
            "CategoryKey": selectedCategoryKey,
            "CategoryId": selectedCategoryID,
            "Sub_Enabled": true,
            "SubCategoryList": selectedSubCategoryList
        }]

        //upload cert and awards to s3 bucket
        const certsUrl = await uploadCertsAwards(certificates, true)
        const awardsUrl = await uploadCertsAwards(awards, false)

        // //console.log("certsUrl",certsUrl)
        // //console.log("awardssUrl",awardsUrl)


        //upload images and videos to s3 bucket 
        const coverListingUrl = await uploadCoverListing(coverListingFiles[0])
        //console.log('coverListingUrl', coverListingUrl)

        const listingImagesUrl = await uploadListingImages(multiplePhotosFiles)
        //console.log('listingImagesUrl', listingImagesUrl)

        const listingVideosUrl = await uploadListingVideos(multipleVideosFiles)
        //console.log('listingVideosUrl', listingVideosUrl)

        // call insert service api
        //set modal loading false
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/insertService",
                "POST",
                JSON.stringify({
                    "ServiceName": titleValue,
                    "Description": description,
                    "WhatAppsMobileNo": whatsAppNumber,
                    "LocationList": locationList,
                    "Certificates": certsUrl,
                    "Awards": awardsUrl,
                    "CoverImage": coverListingUrl,
                    "ListingImage": listingImagesUrl,
                    "ListingVideo": listingVideosUrl,
                    "PosterUserId": UserId,
                    "CategoryList": advisorCategory,
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/service/insertService : response : ', responseJson)
                if (responseJson.Data != null && responseJson.ReturnCode == 200) {
                    //navigate my listing
                    setLoading(false)
                    setModalTitle(t('serviceManagement.UploadSuccessful'))
                    setModalMessage(t('serviceManagement.UploadSuccessDesc'))
                } else {
                    //console.log('/service/insertService responseJson.Data == null , something went wrong')
                    setLoading(false)
                    setModalTitle(t('serviceManagement.UploadUnsuccessful'))
                    setModalMessage(t('serviceManagement.UnableToProceed'))
                }
            }))
            .catch((error) => {
                //console.log('/service/insertService : error : ', error)
            });
    }

    async function getUserInfoForMeMenu() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/meMenu",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "DisplayScreen": "Advisor"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/meMenu responseJson here', responseJson)
                setWhatsAppNumber(responseJson.Data.UserWhatAppsMobileNo)
            }))
            .catch((error) => {
                //console.log('/user/meMenu error here', error)
            });
    }


    async function getAdviserPlanDetails(trueFalse) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + `/adviser_plan/selectAdviserPlanDetailsByUserId?UserId=${UserId}`,
                "GET",
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/adviser_plan/selectAdviserPlanDetailsByUserId  here', responseJson.Data.SubscribePlan)
                setCountryCodeFromPlan(responseJson.Data.SubscribeHistory.CountryCode.toString().toUpperCase())
                setMaxLocation(responseJson.Data.SubscribePlan.MaxLocation)
                if (trueFalse == true) {
                    setAddressValid(Array(responseJson.Data.SubscribePlan.MaxLocation).fill(false))
                }
                setMaxVideo(responseJson.Data.SubscribePlan.MaxVideo)
                setMaxPhoto(responseJson.Data.SubscribePlan.MaxPhoto)
            }))
            .catch((error) => {
                //console.log('/adviser_plan/selectAdviserPlanDetailsByUserId error here', error)
            });
    }

    async function getCategories(serviceItem) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/category/listAllCategory",
                "GET"
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/category/listAllCategory responseJson.Data : ', responseJson.Data)
                setCategories(responseJson.Data)

                if (serviceItem) {

                    //console.log('serviceItem.CategoryList[0]', serviceItem.CategoryList[0])
                    setSelectedCategoryID(serviceItem.CategoryList[0].CategoryId)

                    setSelectedCategoryKey(serviceItem.CategoryList[0].CategoryKey)
                    const filteredCategory = responseJson.Data.find(category => category._id === serviceItem.CategoryList[0].CategoryId);

                    setSubcategoryList(filteredCategory.SubCategoryList)
                    setCategoryName(filteredCategory.CategoryName)
                    setSelectedSubcategoryList(serviceItem.CategoryList[0].SubCategoryList)

                } else {

                    setSelectedCategoryID(responseJson.Data[0]._id)
                    setSelectedCategoryKey(responseJson.Data[0].CategoryKey)
                    setCategoryName(responseJson.Data[0].CategoryName)
                    setSubcategoryList(responseJson.Data[0].SubCategoryList)
                    // setSelectedSubcategoryID(responseJson.Data[0].SubCategoryList[0]._id)
                    // //console.log("  setSelectedSubcategoryList(responseJson.Data[0].SubCategoryList[0])", responseJson.Data[0].SubCategoryList[0])
                    setSelectedSubcategoryList([{
                        "Sub_CategoryKey": responseJson.Data[0].SubCategoryList[0].Sub_CategoryKey,
                        "Sub_CategoryId": responseJson.Data[0].SubCategoryList[0]._id,
                        "Sub_Enabled": responseJson.Data[0].SubCategoryList[0].Sub_Enabled
                    }])

                }


            }))
            .catch((error) => {
                //console.log('/category/listAllCategory error : ', error)
            });
    }

    async function convertLocationArray(locationArray) {
        // Create a new array to store the converted locations
        const convertedArray = [];

        // Iterate over each location object
        for (const location of locationArray) {
            // Extract the required fields from the original location object
            const {
                _id,
                ServiceId,
                Location: {
                    coordinates: [lng, lat],
                    Address,
                    UnitNo,
                    City,
                    Region,
                    Country,
                    CountryCode
                }
            } = location;

            // Create a new object with the desired format
            const convertedLocation = {
                Address,
                UnitNo,
                Lat: lat,
                Lng: lng,
                City,
                Region,
                Country,
                CountryCode
            };

            // Push the converted location object to the new array
            convertedArray.push(convertedLocation);
        }

        // Return the converted array
        setLocationList(convertedArray);
    }


    function renderImagesVideo() {
        return (
            <div>
                <div style={{ paddingTop: '2%' }}>
                    <span style={{ fontWeight: 'bold', fontSize: 25 }}>{t('serviceManagement.Upload some images/ videos')}</span>
                    {renderCoverListings()}
                    {renderListingImages()}
                    {maxVideo > 0 && renderListingVideos()}
                </div>
            </div>
        )
    }

    function renderListingVideos() {
        return (
            <div>
                <div style={{ paddingTop: '2%' }}>
                    <span>{t('serviceManagement.Listing Videos')}</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '1%' }}>
                    <div style={{ width: '35%', backgroundColor: 'white', border: '1px solid', borderColor: COLORS.orange, borderRadius: '5px', marginRight: '5%', position: 'relative', overflow: 'hidden' }}>
                        <input
                            type="file"
                            // accept=".mp4, .mov, .avi"
                            accept=".mp4"
                            multiple
                            style={{ position: 'absolute', top: '0', left: '0', opacity: '0', width: '100%', height: '100%', cursor: 'pointer' }}
                            onChange={handleMultipleVideosChange}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                            <span style={{ color: COLORS.orange }}>+</span>
                            <span style={{ color: COLORS.orange }}>{t('serviceManagement.Add Video')}</span>
                            <span style={{ color: COLORS.orange }}>{`${multipleVideosFiles.length}/${maxVideo}`}</span>
                        </div>
                    </div>
                    {/* Render videos here */}
                    <div style={{ width: '65%' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {multipleVideosFiles.length > 0 &&
                                multipleVideosFiles.map((file, index) => (
                                    <div key={index} style={{ position: 'relative', marginRight: '2%' }}>
                                        {
                                            typeof file === 'string'
                                                ?
                                                <video key={index} src={file} alt="Multiple Videos" style={{ width: '100px', height: '100px', borderRadius: '10px' }} />
                                                :
                                                <video key={index} src={URL.createObjectURL(file)} alt="Multiple Videos" style={{ width: '100px', height: '100px', borderRadius: '10px' }} />
                                        }

                                        <div
                                            onClick={() => handleDeleteVideos(index)}
                                            style={{ position: 'absolute', top: '2px', right: '2px', zIndex: '1' }}
                                        >
                                            <img
                                                src={require('../../img/icons/close.png')}
                                                style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }}
                                                alt="Close"
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderListingImages() {
        return (
            <div>
                <div style={{ paddingTop: '2%' }}>
                    <span>{t('serviceManagement.Listing Image')}</span>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '1%' }}>
                    <div style={{ width: '35%', backgroundColor: 'white', border: '1px solid', borderColor: COLORS.orange, borderRadius: '5px', marginRight: '5%', position: 'relative', overflow: 'hidden' }}>
                        <input
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            multiple
                            style={{ position: 'absolute', top: '0', left: '0', opacity: '0', width: '100%', height: '100%', cursor: 'pointer' }}
                            onChange={handleMultiplePhotosChange}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                            <span style={{ color: COLORS.orange }}>+</span>
                            <span style={{ color: COLORS.orange }}>{t('serviceManagement.Add Photo')}</span>
                            <span style={{ color: COLORS.orange }}>{`${multiplePhotosFiles.length}/${maxPhoto}`}</span>
                        </div>
                    </div>
                    {/* Render image here */}
                    <div style={{ width: '65%' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {multiplePhotosFiles.length > 0 &&
                                multiplePhotosFiles.map((file, index) => (
                                    <div key={index} style={{ position: 'relative', marginRight: '2%' }}>
                                        {
                                            typeof file === 'string'
                                                ?
                                                <img src={file} alt="Multiple Photos" style={{ width: '100px', height: '100px', borderRadius: '10px' }} />
                                                :
                                                <img src={URL.createObjectURL(file)} alt="Multiple Photos" style={{ width: '100px', height: '100px', borderRadius: '10px' }} />
                                        }

                                        <div
                                            onClick={() => handleDeletePhoto(index)}
                                            style={{ position: 'absolute', top: '2px', right: '2px', zIndex: '1' }}
                                        >
                                            <img
                                                src={require('../../img/icons/close.png')}
                                                style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }}
                                                alt="Close"
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    const renderCoverListings = () => {
        return (
            <div>

                {cropModalVisible && (
                    <Modal show={cropModalVisible}>
                        <Cropper
                            style={{ height: 400, width: '100%' }}
                            aspectRatio={1}
                            preview=".img-preview"
                            guides={false}
                            src={coverListingFiles[0]}
                            ref={cropperRef}
                        />
                        <button onClick={cropImage} style={{ width: "100%", color: 'white', padding: 10, backgroundColor: '#C0281C', borderWidth: "0px", borderRadius: "10px", position: "absolute", top: "105%" }} >Crop Image</button>

                    </Modal>
                )}

                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '1%' }}>
                    <div style={{ width: '35%', backgroundColor: 'white', border: '1px solid', borderColor: COLORS.orange, borderRadius: '5px', marginRight: '5%', position: 'relative', overflow: 'hidden' }}>
                        <input
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            style={{ position: 'absolute', top: '0', left: '0', opacity: '0', width: '100%', height: '100%', cursor: 'pointer' }}
                            onChange={handleCoverListingChange}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                            <span style={{ color: COLORS.orange }}>+</span>
                            <span style={{ color: COLORS.orange }}>{t('serviceManagement.Add Photo')}</span>
                        </div>
                    </div>

                    <div style={{ width: '65%' }}>
                        {(coverListingFiles != null && coverListingFiles[0]) && (
                            <div style={{ position: 'relative', display: 'inline-block', marginRight: '2%' }}>
                                <img src={coverListingFiles} alt="Cover Image" style={{ width: '100px', height: '100px', marginRight: '5%', borderRadius: '10px' }} />
                                <div onClick={() => setCoverListingFiles(null)} style={{ position: 'absolute', top: '2px', right: '2px', zIndex: '1' }}>
                                    <img src={require('../../img/icons/close.png')} style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }} alt="Close" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };




    function ChangeAwardCertificateNameModal() {

        const [fileName, setFileName] = useState()
        return (
            <Modal show={showChangeAwardCertificateNameModal} onHide={() => setShowChangeAwardCertificateNameModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('serviceManagement.Change Name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>{isChangeNameCertificate ? 'Certificate Name' : 'nf'}</p>
                        <input type="text" placeholder={displayName} name='inputname' onChange={(event) => setFileName(event.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowChangeAwardCertificateNameModal(false)}>
                        {t('serviceManagement.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setLoading(true)
                        //change the name
                        console.log(`the ${isChangeNameCertificate ? 'Certificate Name' : 'Award Name'} of ${displayName}, index of ${indexForChangeName} going to be edited`)

                        console.log("certificate", certificates)
                        console.log("awards", awards)

                        if (isChangeNameCertificate == true) {
                            // Create a copy of the certificates array to avoid mutating the original state
                            const updatedCertificates = [...certificates];

                            // Update the CertificatesName property at the specified index
                            // updatedCertificates[indexForChangeName].CertificatesName = fileName;

                            if (updatedCertificates[indexForChangeName].CertificatesName != undefined || updatedCertificates[indexForChangeName].CertificatesName != null || updatedCertificates[indexForChangeName].CertificatesName != "") {

                                updatedCertificates[indexForChangeName].CertificatesName = fileName;

                            } else {
                                updatedCertificates[indexForChangeName].name = fileName;
                            }

                            // Now, updatedCertificates contains the modified data
                            console.log(updatedCertificates);

                        } else {

                            // Create a copy of the certificates array to avoid mutating the original state
                            const updatedAwards = [...awards];

                            // Update the AwardsName property at the specified index
                            if (updatedAwards[indexForChangeName].AwardsName != undefined || updatedAwards[indexForChangeName].AwardsName != null || updatedAwards[indexForChangeName].AwardsName != "") {

                                updatedAwards[indexForChangeName].AwardsName = fileName;


                            } else {
                                updatedAwards[indexForChangeName].name = fileName;
                            }

                            // Now, updatedAwards contains the modified data
                            console.log(updatedAwards);
                        }

                        setShowChangeAwardCertificateNameModal(false)
                        setLoading(false)

                    }} disabled={loading}>
                        {loading ? t('serviceManagement.Loading...') : t('serviceManagement.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function renderDetailsListing() {
        return (
            <div>
                <div style={{ paddingTop: '2%' }}>

                    <span style={{ fontWeight: 'bold', fontSize: 25 }}>{t('serviceManagement.Now, tell us more about your listing')}</span>
                    {renderTitle()}
                    {renderDescription()}
                    {renderLocation()}
                    {renderCertAwards()}
                </div>
            </div>
        )
    }

    function renderCertAwards() {
        return (
            <div style={{ paddingTop: '2%' }}>
                <div style={{ paddingTop: '1%' }}>
                    <span>{t('serviceManagement.Certificates or Company Registration')} *</span>
                    <div style={{ marginTop: '0.5%' }}>
                        <img
                            src={require('../../img/icons/addOrange.png')}
                            style={{
                                height: '25px', width: '25px'
                            }}
                        />
                        <span style={{ color: COLORS.orange, cursor: 'pointer', marginLeft: '0.5%' }} onClick={() => {
                            //triggle to open the input tag which is  onChange={handleCertificateChange}
                            certRef.current.click();
                        }}>
                            {t('serviceManagement.Add Certificate')}
                            <input
                                ref={certRef}
                                type="file"
                                accept=".png, .jpeg, .jpg, .pdf, .doc, .docx, .txt"
                                multiple
                                onChange={handleCertificateChange}
                                style={{ display: 'none' }}
                            // Add any additional attributes or event handlers as needed
                            />
                        </span>
                    </div>
                    {certificates.map((certificate, index) => (
                        <div key={index} style={{ marginTop: '0.2%', display: 'flex', flexDirection: 'row' }}>
                            <span>{certificate.CertificatesName ? certificate.CertificatesName : certificate.name}</span>
                            {/* <button onClick={() => handleDeleteCertificate(index)}>Delete</button> */}
                            <div
                                onClick={() => {
                                    setShowChangeAwardCertificateNameModal(true)
                                    setDisplayName(certificate.CertificatesName ? certificate.CertificatesName : certificate.name)
                                    setIsChangeNameCertificate(true)
                                    setIndexForChangeName(index)
                                }}
                                style={{ marginLeft: '1%', cursor: 'pointer', borderStyle: 'solid', borderWidth: 1, borderColor: COLORS.black, borderRadius: '6%', paddingRight: '0.2%', paddingLeft: '0.2%' }}
                            >
                                {t('serviceManagement.Edit Name')}
                            </div>
                            <div
                                onClick={() => handleDeleteCertificate(index)}
                                style={{ marginLeft: '1%', cursor: 'pointer', borderStyle: 'solid', borderWidth: 1, borderColor: COLORS.black, borderRadius: '6%', paddingRight: '0.2%', paddingLeft: '0.2%' }}
                            >
                                {/* <img
                                    src={require('../../img/icons/close.png')}
                                    style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }}
                                    alt="Close"
                                /> */}
                                {t('serviceManagement.Delete')}
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ paddingTop: '1%' }}>
                    <span>{t('serviceManagement.Awards (optional)')}</span>
                    <div style={{ marginTop: '0.5%' }}>
                        <img
                            src={require('../../img/icons/addOrange.png')}
                            style={{
                                height: '25px', width: '25px'
                            }}
                        />
                        <span style={{ color: COLORS.orange, cursor: 'pointer', marginLeft: '0.5%' }} onClick={() => {
                            //triggle to open the input tag which is  onChange={handleCertificateChange}
                            awardRef.current.click();
                        }}>
                            {t('serviceManagement.Add Awards')}
                            <input
                                ref={awardRef}
                                type="file"
                                accept=".png, .jpeg, .jpg, .pdf, .doc, .docx, .txt"
                                multiple
                                onChange={handleAwardChange}
                                style={{ display: 'none' }}
                            // Add any additional attributes or event handlers as needed
                            />
                        </span>
                    </div>
                    {awards.map((award, index) => (
                        <div key={index} style={{ marginTop: '0.2%', display: 'flex', flexDirection: 'row' }}>
                            <span>{award.AwardsName ? award.AwardsName : award.name}</span>
                            {/* <button onClick={() => handleDeleteCertificate(index)}>Delete</button> */}
                            <div
                                onClick={() => {
                                    setShowChangeAwardCertificateNameModal(true)
                                    setDisplayName(award.AwardsName ? award.AwardsName : award.name)
                                    setIsChangeNameCertificate(false)
                                    setIndexForChangeName(index)
                                }}
                                style={{ marginLeft: '1%', cursor: 'pointer', borderStyle: 'solid', borderWidth: 1, borderColor: COLORS.black, borderRadius: '6%', paddingRight: '0.2%', paddingLeft: '0.2%' }}
                            >
                                {t('serviceManagement.Edit Name')}
                            </div>
                            <div
                                onClick={() => handleDeleteAward(index)}
                                style={{ marginLeft: '1%', cursor: 'pointer', borderStyle: 'solid', borderWidth: 1, borderColor: COLORS.black, borderRadius: '6%', paddingRight: '0.2%', paddingLeft: '0.2%' }}
                            >
                                {/* <img
                                    src={require('../../img/icons/close.png')}
                                    style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }}
                                    alt="Close"
                                /> */}
                                {t("serviceManagement.Delete")}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    function renderDescription() {
        return (
            <div style={{ paddingTop: '2%' }}>
                <span>{t('serviceManagement.Description')} *</span>
                <textarea
                    className='form-control'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    // placeholder='Leave your comment here'
                    style={{
                        height: 200,
                        // resize: 'none', // Disable textarea resizing
                    }}
                />
            </div>
        )
    }

    function renderTitle() {
        return (
            <div style={{ paddingTop: '2%' }}>
                <span>{t('serviceManagement.Title')} *</span>
                <div className='col d-flex justify-content-center position-relative'>
                    <input
                        className="form-control"
                        id="titleValue"
                        name="titleValue"
                        value={titleValue}
                        onChange={handleTitleValueChange}
                        maxLength={50}
                        required
                    />
                    <div
                        className='position-absolute bottom-0 end-0 p-1 text-muted'
                        style={{
                            fontSize: '0.8rem',
                        }}
                    >
                        {titleValue.length}/50
                    </div>
                </div>
            </div>
        )
    }

    function renderLocation() {
        const renderedLocations = [];

        for (let index = 0; index < maxLocation; index++) {
            renderedLocations.push(
                <div key={index} style={{ marginTop: '1%' }}>
                    <span>{t('serviceManagement.Location')} {index + 1} *</span>
                    <PlacesAutocomplete
                        value={address[index] || ''}
                        onChange={(a) => {
                            const updatedAddress = [...address];
                            updatedAddress[index] = a;
                            setAddress(updatedAddress);

                            // Update the validity at the specific index to false
                            const updatedAddressValid = [...addressValid];
                            updatedAddressValid[index] = false;
                            setAddressValid(updatedAddressValid);
                        }}
                        onSelect={(a) => handleSelect(a, index)}
                        // searchOptions={{ componentRestrictions: { country: 'MY' } }} // 'MY' is the country code for Malaysia
                        searchOptions={{ componentRestrictions: { country: countryCodeFromPlan } }} // 'MY' is the country code for Malaysia
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: t('placeholder.Enter your address...'),
                                        className: 'form-control',
                                    })}
                                />
                                <div>
                                    {loading && <div>{t('serviceManagement.Loading...')}</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const { description } = suggestion;
                                        return (
                                            <div
                                                key={index}
                                                {...getSuggestionItemProps(suggestion)}
                                                style={{
                                                    backgroundColor: 'white',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {description}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>
            );
        }

        return (
            <div style={{ paddingTop: '1%' }}>
                {renderedLocations}
            </div>
        );
    }


    function renderSelectionForMultipleSelect(
        name,
        onPress,
        currentSubCategoryID,
        selectedSubCategoryList
    ) {
        const isSelected = selectedSubCategoryList.some(
            (item) => item.Sub_CategoryId === currentSubCategoryID
        );

        return (
            <div onClick={onPress} style={{ display: 'inline-block', marginRight: '10px' }}>
                <button
                    className={`btn btn-${isSelected ? 'primary' : 'light'}`}
                    style={{ borderRadius: '25px' }}
                >
                    {name}
                </button>
            </div>
        );
    }


    function renderSubCategories() {
        return (
            <div style={{ paddingTop: '5%' }}>
                <h5 style={{ color: COLORS.black }}>{categoryName}</h5>
                <div style={{ paddingTop: '2%', backgroundColor: COLORS.white, display: 'flex', flexWrap: 'wrap', marginLeft: '-15px' }}>
                    {SubCategoryList.map((item, index) => (
                        <div key={index} style={{
                            marginBottom: '1.5%',
                            marginLeft: '1%',
                            display: 'flex',
                            alignItems: 'center',
                        }} >
                            {renderSelectionForMultipleSelect(
                                item.Sub_CategoryName,
                                () => {
                                    const containedOrNot = selectedSubCategoryList.some(
                                        (category) => category.Sub_CategoryId === item._id
                                    );

                                    if (containedOrNot) {
                                        const updatedList = selectedSubCategoryList.filter(
                                            (category) => category.Sub_CategoryId !== item._id
                                        );
                                        setSelectedSubcategoryList(updatedList);
                                    } else {
                                        setSelectedSubcategoryList([
                                            {
                                                Sub_CategoryKey: item.Sub_CategoryKey,
                                                Sub_CategoryId: item._id,
                                                Sub_Enabled: item.Sub_Enabled,
                                            },
                                            ...selectedSubCategoryList,
                                        ]);
                                    }
                                },
                                item._id,
                                selectedSubCategoryList
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    function renderSelection(name, onPress, currentCategoryID, selectedID) {

        const isSelected = currentCategoryID === selectedID;
        return (
            <div onClick={onPress} style={{ display: 'inline-block', marginRight: '10px', marginTop: '2%' }}>
                <button
                    className={`btn btn-${isSelected ? 'primary' : 'light'}`}
                    style={{ borderRadius: '25px' }}
                >
                    {name}
                </button>
            </div>
        );
    }


    function renderCategory() {
        return (
            <div style={{ paddingTop: '2%' }}>
                <span style={{ fontWeight: 'bold', fontSize: 25 }}>{t('serviceManagement.Hi, please select your specialty.')}</span>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '-15px' }}>
                    {categories.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: '1.5%',
                                marginLeft: '1%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {renderSelection(
                                item.CategoryName,
                                () => {
                                    setSelectedCategoryID(item._id);
                                    setSelectedCategoryKey(item.CategoryKey);
                                    setCategoryName(item.CategoryName);
                                    setSubcategoryList(item.SubCategoryList);
                                    setSelectedSubcategoryList([
                                        {
                                            Sub_CategoryKey: item.SubCategoryList[0].Sub_CategoryKey,
                                            Sub_CategoryId: item.SubCategoryList[0]._id,
                                            Sub_Enabled: item.SubCategoryList[0].Sub_Enabled,
                                        },
                                    ]);
                                },
                                item._id,
                                selectedCategoryID
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }


    function SuccessModal() {
        return (
            <Modal show={showSuccessModal} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{modalMessage}</Row>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
                        Cancel
                    </Button> */}
                    <Button variant="primary" onClick={() => {
                        // navigate to mylisting
                        navigate('/myListingsAdvisor')
                    }} disabled={loading}>
                        {loading ? t('serviceManagement.Loading...') : t('serviceManagement.View listings')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    useEffect(() => {


        let queryParams = queryString.parse(location.search);

        //console.log("queryParams", queryParams, typeof queryParams)

        if (queryParams.service) {
            const serviceItem = JSON.parse(queryParams.service)
            //console.log('serviceItem', serviceItem)
            setServiceId(serviceItem._id)

            getUserInfoForMeMenu()
            // getCategories()
            getAdviserPlanDetails()

            setInsertServiceAction(false)
            setIsPublicListingOnline(serviceItem.isDisplayListed)

            //set category & sub categories
            getCategories(serviceItem)

            //set title
            setTitleValue(serviceItem.ServiceName)
            setDescription(serviceItem.Description)

            //set address
            // Clear the previous data and update the address and addressValid arrays with the current data
            const updatedAddress = serviceItem.ServiceLocationData.map(
                (item) => item.Location.Address
            );
            const updatedAddressValid = new Array(updatedAddress.length).fill(true);
            setAddress(updatedAddress);
            setAddressValid(updatedAddressValid);

            convertLocationArray(serviceItem.ServiceLocationData)

            // set certificates and awards
            setCertificates([...certificates, ...serviceItem.Certificates])
            setAwards([...awards, ...serviceItem.Awards]);

            // for cover listings
            setCoverListingFiles([serviceItem.CoverImageDisplay]);
            setCoverListingFilesBackUp(serviceItem.CoverImage)

            // for listing images
            setMultiplePhotosFiles(serviceItem.ListingImageDisplay)

            // for listing videos
            setMultipleVideosFiles(serviceItem.ListingVideoDisplay)

        } else {
            getCategories()
            getUserInfoForMeMenu()
            // getCategories()
            getAdviserPlanDetails(true)
        }

    }, [])

    return (
        <div style={{ backgroundColor: "#F8F8F8", padding: '2%' }}>
            <Container className="width95" style={{ backgroundColor: COLORS.white, paddingBottom: '2%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <h1>{insertServiceAction ? t(`serviceManagement.Insert Listing`) : t(`serviceManagement.Edit Listing`)}</h1>
                    </div>
                    <div style={{ width: '50%' }}>
                        {
                            !insertServiceAction &&
                            <div style={{ paddingTop: '2%', paddingRight: '2%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <span style={{ fontWeight: 'bold' }}>{t('serviceManagement.Listed online')}</span>
                                <div style={{ marginLeft: '2%' }}>
                                    <Switch onChange={() => setIsPublicListingOnline(!isPublicListingOnline)} checked={isPublicListingOnline}
                                        onColor={COLORS.orange} offColor={COLORS.lightGray1}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {renderCategory()}
                {renderSubCategories()}
                {renderDetailsListing()}
                {renderImagesVideo()}
                <Row className='m-1 mt-3 mb-3'>
                    <Button onClick={handleSaveButton}>{t("serviceManagement.Save")}</Button>
                </Row>

                <SuccessModal />
                <ChangeAwardCertificateNameModal />
            </Container>
        </div>
    )
}

export default ServiceManagement