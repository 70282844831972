import React, { useEffect } from 'react';

function AboutUs() {

    let wplink = 'about-us'
    let lang = localStorage.getItem('i18nextLng')

    return (
        <iframe
            id="wordpress-iframe"
            src= {lang == null || lang == 'en' ? `https://app.adviser2u.com/${wplink}`: `https://app.adviser2u.com/${lang}/${wplink}-${lang}`}
            width="100%"
            height="1000px"
            title="About Us"
        />
    );
}

export default AboutUs;
