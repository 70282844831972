import React, { useEffect, useState } from 'react'
import domainName from '../../domainName'
import { useSelector } from 'react-redux';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { COLORS, SIZES, FONTS } from "../../colorTheme/colorTheme";
import { useNavigate } from 'react-router-dom';
import { SHA256 } from 'crypto-js';
import { useTranslation } from "react-i18next";


const BookingsAdvisor = () => {

    const { UserData, UserId, latitude, longitude } = useSelector((state) => state.login);

    const navigate = useNavigate();

    const [displayListing, setDisplayListing] = useState();

    const [tabSelectionUpcoming, setTabSelectionUpcoming] = useState(true);
    const [tabSelectionCompleted, setTabSelectionCompleted] = useState(false)
    const [tabSelectionCancelled, setTabSelectionCancelled] = useState(false)

    const [showNothingMessage, setShowNothingMessage] = useState("upcoming")
    const [colorStatus, setColorStatus] = useState(COLORS.orange)
    const [t] = useTranslation("global");



    async function getBookingListing(bookinglistType) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/selectUserBookingListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    // "UserId":"6406ac6ad3ab42d2395e625b",
                    "BookingListType": bookinglistType,
                    "UserRole": "Advisor",
                    "PageNum": 1,
                    "ResultNum": 100
                }),
                // undefined, // user id, wait binglun settle backend
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                // console.log("responseJson /user/selectUserBookingListing ->", responseJson);
                setDisplayListing(responseJson.Data)


            }))
            .catch((error) => {
                // console.log("error /user/selectUserBookingListing ->", error);
                // Alert.alert(
                //   "Oops!",
                //   "An error occurred. Please try again later.",
                //   [{ text: "OK", onPress: () => { } }],
                //   { cancelable: false }
                // );
            });
    }

    function renderBookingsAdvisor(item) {
        return (
            <div style={{
                flexDirection: 'column',
                // ...containerStyle
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '0%'
                }}>
                    <span style={{ color: '#000', fontSize: '16px', fontWeight: 'bold' }}>{t('BookingsAdvisor.Booking No')}: {item.Booking_No}</span>
                    <span style={{ color: colorStatus }}>{item.DisplayStatus}</span>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '2%'
                }}>
                    {/* Image */}
                    {/* <img
                        src={item.AdvisorData.ImageDisplay}
                        style={{ height: 35, width: 35 }}
                        alt="Booking Image"
                    /> */}

                    <div style={{
                        flex: 1,
                        flexDirection: 'column',
                        marginLeft: '2%'
                    }}>
                        <div onClick={() => { navigate(`/bookingDetailsAdvisor?bookingid=${item._id}`) }} style={{ cursor: 'pointer' }}>
                            <span style={{ color: '#000', fontSize: '14px' }}>{item.ServiceName}</span>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                                <img
                                    src={require("../../img/icons/user.png")}
                                    style={{ width: 20, height: 20 }}
                                    alt="User Icon"
                                />
                                <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item.UserData.Name}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                                <img
                                    src={require("../../img/icons/dateTime.png")}
                                    style={{ width: 20, height: 20 }}
                                    alt="Date and Time Icon"
                                />
                                <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item.DisplayDateTime}</span>
                            </div>
                            {item.DisplayLocation != undefined &&
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                                    <img
                                        src={require("../../img/icons/location.png")}
                                        style={{ width: 20, height: 20 }}
                                        alt="Location Icon"
                                    />
                                    <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item.DisplayLocation}</span>
                                </div>
                            }
                        </div>

                        {/* Chat and WhatsApp button */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '1%',
                            marginTop: '2%'
                        }}>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                let currentTime = new Date().getTime(); //epoch timestamp now
                                const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime)
                                navigate(`/message?userId=${UserId}&messageId=${item.UserData._id}&uid=${resultEncrypted}&tid=${currentTime}`)

                            }} className="btn-white text-dark  fw-bold btn"
                                style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>{t('BookingsAdvisor.Chat')}</span></button>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                if (item.AdvisorData.CanWhatsapps) {
                                    window.open(item.AdvisorData.WhatappsLink);
                                } else {
                                    alert(t("error.Oops! This service provider didn't register WhatsApp"));
                                }
                            }} className="btn-white text-dark  fw-bold btn"
                                style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>{t('BookingsAdvisor.WhatsApp')}</span></button>
                        </div>
                    </div>
                </div>

                {greyLine()}
            </div>
        )
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '1.8%',
                    marginTop: '1%',
                }}
            />
        );
    }

    function renderNoBookings(title) {
        return (
            <div style={{
                marginHorizontal: SIZES.padding * 0.8,
                borderRadius: 10,
                backgroundColor: COLORS.lightGray1,
                marginTop: '2%',
                marginBottom: '2%',
                padding: '5%',
                // paddingTop: hp('5%'),
                // paddingBottom: hp('5%'),
                // paddingRight: wp('2%'),
                // paddingLeft: wp('2%'),
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                alignContent: 'center',
                textAlign: 'center'
            }}>
                <Image
                    src={require("../../img/icons/bookingImage.png")}
                // style={{ alignItems:'center' }}
                />
                <h3 style={{ paddingTop: '2%', color: COLORS.black }}>{t("BookingsAdvisor.You don't have any")}{title} {t("BookingsAdvisor.bookings yet")}</h3>
            </div>
        )
    }

    function renderTabSelection() {
        return (
            <Row className='mt-3'>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionUpcoming ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionUpcoming ? 1 : 0,
                            borderBottomColor: COLORS.orange
                        }}
                        onClick={() => {
                            getBookingListing("Upcoming")

                            setTabSelectionUpcoming(true)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("upcoming")
                            setColorStatus(COLORS.orange)
                        }}> {t('BookingsAdvisor.Upcoming')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCompleted ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCompleted ? 1 : 0,
                            borderBottomColor: COLORS.orange
                        }}
                        onClick={() => {
                            getBookingListing("Completed")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(true)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("completed")
                            setColorStatus(COLORS.green)
                        }}>
                        {t('BookingsAdvisor.Completed')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCancelled ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCancelled ? 1 : 0,
                            borderBottomColor: COLORS.orange
                        }}
                        onClick={() => {
                            getBookingListing("Cancelled")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(true)

                            setShowNothingMessage("cancelled")
                            setColorStatus(COLORS.gray)
                        }}>
                       {t('BookingsAdvisor.Cancelled')}
                    </button>
                </Col>
            </Row>
        )
    }


    useEffect(() => {
        getBookingListing("Upcoming")
    }, [])

    return (
        <div className="greybk-div p-lg-5" style={{ height: 'auto', minHeight: 1000 }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="box-container p-2">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('BookingsAdvisor.Bookings')}</h5>
                                </Col>
                            </Row>
                            {renderTabSelection()}
                            {displayListing == "" ? <div>{renderNoBookings(showNothingMessage)}</div>
                                : displayListing?.map((item, index) => (
                                    <div style={{ padding: '2%' }}>
                                        {/* <BookingListCard 
                                        item={item} 
                                        imageStyle={{
                                            backgroundColor: COLORS.white,
                                            borderRadius: 10,
                                            height: 100,
                                            width: 100,
                                            resizeMode: 'contain'
                                        }} 
                                        statusColor={colorStatus}
                                        navigation={`/bookingDetailsUser?bookingid=${item._id}`}
                                        /> */}
                                        {renderBookingsAdvisor(item)}

                                    </div>
                                ))}
                        </Container>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default BookingsAdvisor