import React from 'react'
import domainName from '../domainName';
import { useEffect, useState } from 'react';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme';
import { Container, Row, Col, Button, NavDropdown, Modal, Image } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SHA256 } from 'crypto-js';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const BookingDetailsUser = () => {

    const { token, UserData, UserId } = useSelector((state) => state.login);
    const navigate = useNavigate();

    const [item, setItem] = useState();
    const [bookingId, setBookingId] = useState()

    //for cancel booking modal
    const [showCancelBookingModal, setShowCancelBookingModal] = useState(false)
    const [loading, setLoading] = useState(false);

    const [t] = useTranslation("global");


    async function getBookingDetailsById(bookingid) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/exploreUserBookingServiceDetails",
                "POST",
                JSON.stringify({
                    "BookingServiceId": bookingId ? bookingId : bookingid,
                    "UserRole": "User"
                }),
                // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log("/service/exploreUserBookingServiceDetails response here", responseJson)
                // route.params.bookingid = undefined
                setBookingId(responseJson.Data._id)
                setItem(responseJson.Data)
            }))
            .catch((error) => {
                //console.log("/service/exploreUserBookingServiceDetails error here", error)
            });
    }

    async function handleCancelBooking() {
        setLoading(true);

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/cancelBookingService",
                "POST",
                JSON.stringify({
                    "BookingServiceId": item._id,
                    "Status": "ADVISOR_CANCELLED"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                //console.log(" /service/cancelBookingService responseJson here oh !", responseJson);
                if (responseJson.ReturnCode == 200) {
                    navigate("/mybookings");
                }
            }))
            .catch((error) => {
                //console.log(" /service/cancelBookingService error here oh !", error);
            });

        setLoading(false);
        setShowCancelBookingModal(false);
    }

    function CancelBookingModal() {
        return (
            <Modal show={showCancelBookingModal} onHide={() => setShowCancelBookingModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('BookingsAdvisor.Cancel Booking')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('BookingsAdvisor.Are you sure you want to cancel this booking?')}</Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCancelBookingModal(false)}>
                    {t('BookingsAdvisor.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleCancelBooking()
                    }} disabled={loading}>
                        {loading ? t('BookingsAdvisor.Loading...') : t('BookingsAdvisor.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    function renderLabel(itemtitle, onclick, linkto) {
        return (
            <Link style={{ textDecorationLine: 'none', color: COLORS.gray }} onClick={onclick} to={linkto}>
                <Row style={{ margin: '2%' }}>{greyLine()}</Row>
                <Row className="justify-content-center m-3 greybk" style={{ borderRadius: 10 }}>
                    <Col className="col-lg-12">
                        <Container className=" p-3">

                            <Row className="mt-3 mb-3">
                                <Col xs={9} md={9} lg={9} style={{ alignSelf: 'center' }}>{itemtitle}</Col>
                                <Col xs={3} md={3} lg={3} className="text-end" style={{ alignSelf: 'center' }}><Image src={require('../img/icons/arrow.png')} style={{ marginRight: '1%' }} /></Col>

                            </Row>
                        </Container>
                    </Col>

                </Row>
            </Link>
        )
    }

    function renderInformation() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '2%',
                marginTop: '4%',
                marginBottom: '4%'
            }}>
                {/* Image */}
                <img
                    src={item?.AdvisorData?.ImageDisplay}
                    style={{
                        backgroundColor: COLORS.white,
                        borderRadius: 10,
                        height: 100,
                        width: 100,
                        resizeMode: 'contain'
                    }}
                    alt="Booking Image"
                />

                <div style={{
                    flex: 1,
                    flexDirection: 'column',
                    marginLeft: '2%'
                }}>
                    <div onClick={() => {
                        console.log('xd')
                    }}>
                        <span style={{ color: '#000', fontSize: '14px' }}>{item?.ServiceName}</span>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                            <img
                                src={require("../img/icons/user.png")}
                                style={{ width: 20, height: 20 }}
                                alt="User Icon"
                            />
                            <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item?.AdvisorData?.Name}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                            <img
                                src={require("../img/icons/dateTime.png")}
                                style={{ width: 20, height: 20 }}
                                alt="Date and Time Icon"
                            />
                            <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item?.DisplayDateTime}</span>
                        </div>
                        {item?.DisplayLocation != undefined &&
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                                <img
                                    src={require("../img/icons/location.png")}
                                    style={{ width: 20, height: 20 }}
                                    alt="Location Icon"
                                />
                                <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item?.DisplayLocation}</span>
                            </div>
                        }
                    </div>

                    {/* Chat and WhatsApp button */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '1%',
                        marginTop: '2%'
                    }}>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            //console.log("go to message screen", item);
                            // navigation.navigate('Message', {
                            //   senderId: item.RequesterUserId,
                            //   receiverId: item.AdvisorData._id
                            // });
                            let currentTime = new Date().getTime(); //epoch timestamp now
                            const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime)
                            navigate(`/message?userId=${UserId}&messageId=${item.ServiceUserId}&uid=${resultEncrypted}&tid=${currentTime}`)

                        }} className="btn-white text-dark  fw-bold btn"
                            style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>{t('BookingsAdvisor.Chat')}</span></button>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            if (item?.AdvisorData.CanWhatsapps) {
                                window.open(item?.AdvisorData.WhatappsLink);
                            } else {
                                alert(t("error.Oops! This service provider didn't register WhatsApp"));
                            }
                        }} className="btn-white text-dark  fw-bold btn"
                            style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>WhatsApp</span></button>
                    </div>
                </div>
            </div>
        );
    }

    function renderGrayBar() {
        return (
            // <div
            //     style={{
            //         marginTop: '2%',
            //         backgroundColor: COLORS.lightGray1,
            //         marginHorizontal: SIZES.padding * 0.8,
            //         paddingHorizontal: SIZES.padding * 0.5,
            //         paddingTop: '2%',
            //         paddingBottom: '2%',
            //         borderRadius: 10,
            //     }}
            // >
            //     <span style={{ color: COLORS.orange, ...FONTS.h3 }}>{item?.DisplayStatus}</span>
            //     <span style={{ color: COLORS.black, fontWeight: 'bold', paddingTop: '0.5%', ...FONTS.body3 }}>Booking No: {item?.Booking_No}</span>
            //     <span style={{ color: COLORS.black, fontWeight: 'bold', paddingTop: '0.5%', ...FONTS.body3 }}>Booking Date: {item?.DisplayDateTime}</span>
            // </div>

            <Row style={{ backgroundColor: COLORS.lightGray1, padding: '2%', margin: '2%', borderRadius: 20 }}>
                <span style={{ color: item?.DisplayStatus != t('BookingsAdvisor.Upcoming') && item?.DisplayStatus != t('BookingsAdvisor.Completed') ? COLORS.gray : item?.DisplayStatus == t('BookingsAdvisor.Upcoming') ? COLORS.orange : COLORS.green }}>
                    {item?.DisplayStatus}
                </span>
                <span style={{ fontWeight: 'bold', marginTop: '0.5%' }}>
                {t('BookingsAdvisor.Booking No')}: {item?.Booking_No}
                </span>
                <span style={{ fontWeight: 'bold', marginTop: '0.5%' }}>
                {t('BookingsAdvisor.Booking Date')}: {item?.DisplayDateTime}
                </span>
            </Row>
        );
    }

    function renderHeader() {
        return (
            <div
                style={{
                    marginTop: '5%',
                    paddingBottom: '2.5%',
                    backgroundColor: COLORS.white,
                    paddingHorizontal: SIZES.padding * 0.5,
                    borderBottomColor: COLORS.lightGray1,
                    borderBottomWidth: 0.5,
                }}
            >
                <div
                    style={{
                        flexDirection: 'row',
                    }}
                >
                    <div
                        onClick={() => {
                        }}
                    >
                        <img
                            src={require("../img/icons/back.png")}
                            style={{
                                width: 12,
                                height: 22,
                                marginTop: '0.6%',
                                marginRight: '3%',
                            }}
                        />
                    </div>
                    <span style={{ color: COLORS.black, ...FONTS.h2 }}>{t('BookingsAdvisor.Booking Details')}</span>
                </div>
            </div>
        );
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid black',
                    borderColor: '#D9D9D9',
                    // margin: '10px 0',
                }}
            ></div>
        )
    }

    useEffect(() => {
        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const bookingId = searchParams.get('bookingid');

        // Do something with the serviceId value
        //console.log("bookingId in bookingdetailsuser page", bookingId);


        getBookingDetailsById(bookingId)


    }, [])


    return (


        <div className="greybk-div p-lg-5" style={{ height: 'auto', minHeight: 1000 }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="box-container p-2">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('BookingsAdvisor.Booking Details')}</h5>
                                </Col>
                            </Row>
                            {renderGrayBar()}
                            {renderInformation()}

                            {/* {renderLabel('Reschedule', () => { console.log('Go reschedule') })} */}
                            {item?.DisplayStatus != "Cancelled" ? (
                                renderLabel(t('BookingsAdvisor.Cancel Booking'), async () => {
                                    // handleCancelBooking(item);
                                    //console.log("handle cancel booking")
                                    setShowCancelBookingModal(true)
                                })
                            ) : (
                                ""
                            )}
                            {renderLabel(t('BookingsAdvisor.View Listing'), () => {
                                // navigation.navigate('AdvisorDetails', { bookingItem: item });
                                //console.log('clicked view listing',item?.ServiceId)

                            }, `/servicedetails?serviceid=${item?.ServiceId}`)}
                            {renderLabel(t('BookingsAdvisor.Review'), () => {
                                // navigation.navigate('RateAndReview', { bookingItem: item });
                                //console.log('go review')
                            }, `/reviewrating?bookingid=${item?._id}`)}
                            <Row style={{ margin: '2%' }}>{greyLine()}</Row>
                        </Container>
                    </Col>
                </Row>
                <CancelBookingModal />
            </Container>
        </div>

    )
}

export default BookingDetailsUser

