import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import domainName from '../../domainName';
import queryString from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { COLORS } from '../../colorTheme/colorTheme';
import { SHA256 } from 'crypto-js';
import { logOut, insertAnonymousUserId } from '../../redux/slices/login';
import { useTranslation } from "react-i18next";

const EnterVerificationCode = () => {
    const [t] = useTranslation("global");
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const smsKey = process.env.REACT_APP_SMS_SECRET_KEY;
    const apiKey = process.env.REACT_APP_API_KEY;

    const { UserId } = useSelector((state) => state.login);
    
    const [timeLeft, setTimeLeft] = useState(60);
    const [resendCodeVisible, setResendCodeVisible] = useState(false);
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);

    const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phoneNumber"));
    const [userName, setUserName] = useState(localStorage.getItem("userName"));
    const [password, setPassword] = useState(localStorage.getItem("password"));
    const [referralCode, setReferralCode] = useState(localStorage.getItem("referralCode"));

    const [disabledButton, setDisabledButton] = useState(false);

    const handleChange = (e, index) => {
        const { value } = e.target;

        setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[index] = value;
            return newOtp;
        });

        if (value !== '' && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const insertAnonymouseUser = async () => {
        try {
            const responseJson = await domainName.serverAPICall(
                `${domainName.debugAPI}/user/insertAnonymouseUser`,
                "POST"
            ).then(domainName.handleServerAPICallErrors);

            if (responseJson.Data) {
                dispatch(logOut());
                dispatch(insertAnonymousUserId(responseJson.Data._id));
                localStorage.setItem("UserId", responseJson.Data._id);  // Store UserId in localStorage
            } else {
                alert(responseJson.ReturnDesc);
            }
        } catch (error) {
            alert(t("error.An error occurred. Please try again later."));
        }
    };

    const insertUser = async (loginType, password) => {
        const diversifyKey = Math.random().toString(36).substring(2, 12);
        try {
            const responseJson = await domainName.serverAPICall(
                `${domainName.debugAPI}/user/updateAnonymouseUserToUserWithEmailAndMobileNo`,
                "POST",
                JSON.stringify({
                    "Email": null,
                    "MobileNo": phoneNumber,
                    "LoginType": loginType,
                    "Password": password,
                    "DiversifyKey": diversifyKey,
                    "UserName": userName,
                    "FirstRegisterReferralCode": referralCode || null
                }),
                UserId
            ).then(domainName.handleServerAPICallErrors);

            if (responseJson.ReturnCode === 200) {
                await insertAnonymouseUser();
                navigate('/signupsuccess');
            } else {
                alert(responseJson.ReturnDesc || t("error.Something went wrong. Please contact your technical staff."));
            }
        } catch (error) {
            console.error("/user/updateAnonymouseUserToUserWithEmailAndMobileNo error:", error);
        }
    };

    const insertUserWithPhoneNumber = async () => {
        const otpValue = otp.join('');
        setDisabledButton(true);

        try {
            const responseJson = await domainName.serverAPICall(
                `${domainName.debugAPI}/user/verifyVerificationTwilioOtp`,
                "POST",
                JSON.stringify({
                    "MobileNo": phoneNumber,
                    "OTP": otpValue
                })
            ).then(domainName.handleServerAPICallErrors);

            if (responseJson.ReturnCode === 200) {
                if (password) {
                    await insertUser('MOBILE_NO', password);
                } else {
                    const randomId = (Math.random() + 1).toString(36).substring(2);
                    const currentTime = new Date().getTime();
                    const phoneHash = SHA256(phoneNumber);
                    const resetId = SHA256(phoneHash + currentTime + randomId);
                    navigate(`/resetpassword?${queryString.stringify({ phoneNumber, tid: currentTime, id: randomId, resetid: resetId })}`);
                }
            } else {
                alert(`${t("error.Oops!")}${responseJson.ReturnDesc}`);
            }
        } catch (error) {
            console.error('/user/verifyVerificationTwilioOtp error:', error);
        } finally {
            setDisabledButton(false);
        }
    };

    const resendOTP = async () => {
        console.log('UserId 3:', UserId);

        const smsid = SHA256(phoneNumber + "Adviser2u" + apiKey + smsKey);

        try {
            const responseJson = await domainName.serverAPICall(
                `${domainName.debugAPI}/user/sendTwilioOtp`,
                "POST",
                JSON.stringify({ "MobileNo": phoneNumber, "SMS_ID": smsid.toString() })
            ).then(domainName.handleServerAPICallErrors);

            if (responseJson.ReturnCode === 200) {
                setResendCodeVisible(false);
                setOtp(Array(6).fill(''));
                setTimeLeft(60);
                alert(t("error.OTP re-sent! Please enter again."));
            } else {
                alert(t('error.Send otp got error, may contact administrator'));
            }
        } catch (error) {
            console.error('/user/sendTwilioOtp error:', error);
        }
    };

    useEffect(() => {
        const queryParams = queryString.parse(location.search);

        if (queryParams.phoneNumber) {
            setPhoneNumber(queryParams.phoneNumber);
            // localStorage.setItem("phoneNumber", queryParams.phoneNumber);
        }
        if (queryParams.username) {
            setUserName(queryParams.username);
            // localStorage.setItem("userName", queryParams.username);
        }
        if (queryParams.password) {
            setPassword(queryParams.password);
            // localStorage.setItem("password", queryParams.password);
        }
        if (queryParams.referalcode) {
            setReferralCode(queryParams.referalcode);
            // localStorage.setItem("referralCode", queryParams.referalcode);
        }
    }, [location.search]);

    useEffect(() => {
        const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
        if (timeLeft === 0) clearInterval(timer);
        return () => clearInterval(timer);
    }, [timeLeft]);

    useEffect(() => {
        if (timeLeft === 0) setResendCodeVisible(true);
    }, [timeLeft]);

    const seconds = timeLeft % 60;
    const isOtpFilled = otp.every((digit) => digit !== '');

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h2 className="fw-bold">{t('enterVerificationCode.Enter Verification Code')}</h2>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <span style={{ textAlign: 'center' }}>
                                    {t('enterVerificationCode.Please type the verification code sent to')} <h3>{phoneNumber}</h3>
                                </span>
                            </Row>
                            <Row className="mt-4">
                                {otp.map((digit, index) => (
                                    <Col xs={2} md={2} key={index}>
                                        <input
                                            ref={(ref) => (inputRefs.current[index] = ref)}
                                            type="text"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleChange(e, index)}
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                fontSize: '20px',
                                                textAlign: 'center',
                                                border: '1px solid gray',
                                                borderRadius: '4px',
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && index === otp.length - 1) {
                                                    insertUserWithPhoneNumber();
                                                }
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            <Row className='mt-4'>
                                <Button disabled={!isOtpFilled || disabledButton} onClick={insertUserWithPhoneNumber}>
                                    {t('enterVerificationCode.next')}
                                </Button>
                            </Row>
                            <Row className="justify-content-center mt-4">
                                <Col className="d-flex justify-content-center">
                                    <div>
                                        <span>
                                            {t('enterVerificationCode.Please wait')}
                                            <span style={{ color: COLORS.orange }}> {seconds} </span>
                                            {t('enterVerificationCode.seconds to resend.')}
                                        </span>
                                        <br/>
                                        {resendCodeVisible && (
                                            <span
                                                onClick={resendOTP}
                                                style={{ color: COLORS.orange, textDecoration: 'underline', cursor: 'pointer' }}
                                            >
                                                Resend Code
                                            </span>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default EnterVerificationCode;
