import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";


const ContactUs = () => {
  const { pathname, search } = useLocation();

  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    let queryParam = queryString.parse(search);
    let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue')
    i18n.changeLanguage(_lang);
  }, [])

  return (
    <div className='greybk-div p-lg-5'>

      <Container className='box-container p-5'>
        <Row>
          <Col><h4 className='fw-bold pb-3'>{t('contactUs.Contact Us')}</h4></Col>
        </Row>

        <Row>
          <Col>
            <h5 className='pb-1'>{t('contactUs.Address')}</h5>
            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.Malaysia')}</h6>
              <div>{t('contactUs.OperationOffice')}: Kompleks Maluri, No 160-3-8, Jalan Jejaka, Taman Maluri, Kuala Lumpur, Malaysia</div>
              <div className='pt-2'>{t('contactUs.CorrespondenceAddress')}: Level 23, Menara Exchange 106 Lingkaran TRX, Tun Razak Exchange, Kuala Lumpur, Malaysia</div>
            </p>

            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.Singapore')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: 21B Bukit Pasoh Road Singapore 089835</div>
            </p>

            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.SouthKorea')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: Spaces Gran Seoul, 7F Tower 1, Gran, 33 Jong-ro, Seoul, South Korea</div>
            </p>


            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.Taiwan')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: No. 68, Section 5 of Zhongxiao East RoadSection 5 of Zhongxiao E Rd, Xinyi District, Taipei, Taiwan</div>
            </p>


            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.HongKong')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: 86/F, International Commerce Centre, Kowloon, Hong Kong</div>
            </p>

            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.Japan')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: 2-16-4 Konan,Tokyo, Japan</div>
            </p>

            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.Vietnam')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: 02 Ngo Duc Ke Street, Ho Chi Minh City, Vietnam</div>
            </p>

            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.Indonesia')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: Menara Astra,  37th Floor. Jl. Jend. Sudirman Kav. 5-6<br/>Jakarta,DKI Jakarta, Indonesia </div>
            </p>

            <p className='pb-1'>
              <h6 className='fw-bold'>{t('contactUs.Australia')}</h6>
              <div>{t('contactUs.CorrespondenceAddress')}: 8 Parramatta Square, Sydney, Australia</div>
            </p>
            

          </Col>
        </Row>


        <Row className='mt-3'>
          <Col>
            <h5>{t('contactUs.Email')}</h5>
            <p>
            <a href="mailto:help@adviser2u.com">help@adviser2u.com</a>
            </p>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col>
            <h5>{t('contactUs.Tel')}</h5>
            <p>
            +603-2333 9233
            </p>
          </Col>
        </Row>


      </Container>

    </div>
  )
}

export default ContactUs