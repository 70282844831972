import React, { useState, useEffect } from 'react';
import domainName from '../../domainName'
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme'
import { Tooltip } from 'react-tooltip';
import { useTranslation } from "react-i18next";


import './SubscriptionStepOne.css';



const SubscriptionStepTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [category, setCategory] = useState([])
  const [value, setValue] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState({});

  const [showProceedChangePlanListing, setShowProceedChangePlanListing] = useState(false)
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation("global");


  const getCategoryNameList = async () => {

    await domainName
      .serverAPICall(
        domainName.debugAPI + "/category/getAllCategoryName",
        "GET"
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        setCategory(responseJson.Data)
      }))
      .catch((error) => {
        alert(
          t("error.Oops!"),
          t("error.An error occurred. Please try again later."),
          error
        );
      });

  }

  const handleIncrement = () => {
    if (selectedPlan.PlanKey != 'PLAN_FREE' || (selectedPlan.PlanKey == 'PLAN_FREE' && value < 1)) {
      setValue(prevValue => prevValue + 1);
    }
  };

  const handleDecrement = () => {
    setValue(prevValue => prevValue > 0 ? prevValue - 1 : 0);
  };

  const goToStep3 = () => {
    selectedPlan.PassInListing = value

    // changePlan == 1, meaning to change plan, 
    // if listing from many to less need to prompt msg will delisted all service
    if (selectedPlan.changePlan == 1 && selectedPlan.listedService > value) {
      // 
      setShowProceedChangePlanListing(true)
    } else {
      navigate(`/billingDetails?${queryString.stringify(selectedPlan)}`);
    }

    // console.log('selectedPlan.changePlan::', selectedPlan.changePlan)
  }


  function ProceedChangePlanListingModal() {
    return (
      <Modal show={showProceedChangePlanListing} onHide={() => setShowProceedChangePlanListing(false)} fade={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('subscriptionStepTwo.Change Listing')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingLeft: '2%' }}>{t("subscriptionStepTwo.Changing the listing number will delist all your current listings. Don't worry; your listings won't be deleted, and you can relist them in the 'My Listing'. Do you want to proceed?")}</Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProceedChangePlanListing(false)}>No</Button>
          <Button variant="primary" disabled={loading} onClick={() => {
            navigate(`/billingDetails?${queryString.stringify(selectedPlan)}`);
          }}>
            {loading ? 'Loading...' : t('Yes')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }


  useEffect(() => {
    getCategoryNameList()

    let queryParams = queryString.parse(location.search);
    // console.log(queryParams)
    setSelectedPlan(queryParams)

    let _lang = queryParams.lang ? queryParams.lang : localStorage.getItem('selectedValue')
    i18n.changeLanguage(_lang);

  }, [])

  return (
    <div className='greybk-div p-lg-5'>

      <Container className='box-container p-lg-5' >
        <Row>

          <Col className='col-lg-6 col-md-12' >
            <div className='box-container p-5 m-4' style={{ 'background-color': '#F4F4F4' }}>
              <h5 className='pb-2 fw-bold fs-5'>{t('subscriptionStepTwo.Categories you can have:')}</h5>

              <ul className="two-column-list fs-5">
                {category.map((item, index) => (
                  <li key={index}>{item.CategoryName}</li>
                ))}
              </ul>
            </div>

          </Col>


          <Col className='col-lg-6 col-md-12'>
            <div className='p-4'>
              <h5 className='fw-bold fs-5 mb-4'>
              {t('subscriptionStepTwo.HowManyListingYouNeed')} {selectedPlan.Currency + ' ' + (selectedPlan.OfferPrice &&  Number(selectedPlan.OfferPrice).toFixed(2))}.
              </h5>

              <span className='p-2 ' style={{ border: '1px solid #D9D9D9', borderRadius: '5px' }}>
                <button onClick={handleDecrement} style={{ backgroundColor: '#fff', border: 'none' }}>-</button>
                <span className='p-3' style={{ display: 'inline-block', width: '45px' }}>{value}</span>

                {/* <CustomTooltip content={selectedPlan.PlanKey == 'PLAN_FREE' && value >= 1 ? 'Maximum reached' : ''}>
                  <button onClick={handleIncrement} style={{ backgroundColor: '#fff', border: 'none' }}>+</button>
                </CustomTooltip> */}



                <button
                  onClick={handleIncrement}
                  style={{
                    backgroundColor: '#fff',
                    border: 'none',
                  }}

                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={selectedPlan.PlanKey == 'PLAN_FREE' && value >= 1 ? 'Free plan maximum listing reached' : ''}
                >
                  +
                </button>
                <Tooltip id="my-tooltip" effect="solid" />

              </span>

              <Row className="justify-content-end mt-3">
                <Col className='col-xl-3 col-lg-4'>
                  <Button className='orange-btn text-center' onClick={goToStep3} disabled={value === 0}>{t('subscriptionStepTwo.Continue')}</Button>
                </Col>
              </Row>
            </div>



          </Col>
        </Row>

        <ProceedChangePlanListingModal />
      </Container>

    </div>
  )

}

export default SubscriptionStepTwo