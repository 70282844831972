import React from 'react';
import Button from 'react-bootstrap/Button';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme'
import { useTranslation } from "react-i18next";



function SubscriptionCard({ planName, price, photo, video, location, description, isSelected, onSelect }) {
  const cardClassName = isSelected ? 'orange-border-box' : 'grey-border-box';
  const btnClassName = isSelected ? 'orange-btn' : 'orange-transparent-btn';
  const [t, i18n] = useTranslation("global");


  return (
    <div className={`p-md-5 p-4 pt-5 pb-5 ${cardClassName}`}>
      {planName == 'FREE' ? (<div style={{ height: '35px' }}></div>) : (<div className='red-offer-label'>{t('subscriptionCard.New Launching 50% Off')}</div>)}


      <div className='text-center text-uppercase p-3 fs-6' style={{ color: COLORS.gray }}>{planName}</div>

      <div className='text-center p-3 fs-5 fw-bold'
        style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', color: COLORS.orange }}>{price}</div>

      <ul className='mt-4 fs-6 fw-bold' style={{ minHeight: '90px' }}>
        {photo !== 0 && (
          <li>{photo} {t('subscriptionCard.Image Uploads')}</li>
        )}

        {video !== 0 && (
          <li>{video} {t('subscriptionCard.Video Uploads')}</li>
        )}

        {location !== 1 ? (
          <li>{location} {t('subscriptionCard.Location Options')}</li>
        ) : (
          <li>{location} {t('subscriptionCard.Location Option')}</li>
        )}

        {/* updated 13/11/2024, commented this part as they dont want to show this */}
        {/* {description && description.map((item, index) => (
          <li key={index}>{item}</li>
        ))} */}

      </ul>

      <Button className={`${btnClassName}`}
        onClick={onSelect}>
        {isSelected ? t('subscriptionCard.Selected') : t('subscriptionCard.Select')}
      </Button>
    </div>
  );
}

export default SubscriptionCard;
