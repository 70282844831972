import React from 'react'
import { Container, Row, Col, Button, NavDropdown, Modal, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import domainName from '../../domainName';
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme';
import { useEffect, useState } from 'react';
import ReactStarRatings from 'react-star-ratings';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const MyReviews = () => {
    const navigate = useNavigate();

    const { token, UserData, UserId } = useSelector((state) => state.login);

    // set data from API
    const [postReviewListing, setPostReviewListing] = useState([])
    const [receivedReviewListing, setReceivedReviewListing] = useState([])
    const [userRatingDetails, setUserRatingDetails] = useState()

    //tab selection
    const [tabReceived, setTabReceived] = useState(true)
    const [tabSent, setTabSent] = useState(false)

    const [t, i18n] = useTranslation("global");


    async function getUserPostReviewListing(targetUserId) {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userPostReviewListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "TargetUserId": targetUserId != undefined ? targetUserId : null,
                    "PageNum": 1,
                    "ResultNum": 10
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userPostReviewListing : response : ', responseJson.Data.PostReviewListing)
                setPostReviewListing(responseJson.Data.PostReviewListing)
            }))
            .catch((error) => {
                //console.log('/user/userPostReviewListing : error : ', error)
            });
    }

    async function getReceivedReviewListing(reviewUserId) {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userReceivedReviewListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "ReviewerUserId": reviewUserId != undefined ? reviewUserId : null,
                    "PageNum": 1,
                    "ResultNum": 10
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userReceivedReviewListing : response : ', responseJson.Data.ReceivedReviewListing)
                setReceivedReviewListing(responseJson.Data.ReceivedReviewListing)
                setUserRatingDetails(responseJson.Data.UserRatingDetails)
            }))
            .catch((error) => {
                //console.log('/user/userReceivedReviewListing : error : ', error)
            });
    }

    function renderTabSelection() {
        return (
            <Row className='mt-3'>
                <Col xs={6} md={6} lg={6} className="d-flex">
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: tabReceived ? COLORS.orange : COLORS.black,
                        borderBottomWidth: tabReceived ? 1 : 0,
                        borderBottomColor: COLORS.orange
                    }} onClick={() => { setTabReceived(true); setTabSent(false) }}>
                        {t('MyReviews.Receive')}
                    </button>
                </Col>
                <Col xs={6} md={6} lg={6} className="d-flex">
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: tabSent ? COLORS.orange : COLORS.black,
                        borderBottomWidth: tabSent ? 1 : 0,
                        borderBottomColor: COLORS.orange
                    }} onClick={() => { setTabReceived(false); setTabSent(true) }}>
                        {t('MyReviews.sent')}
                    </button>
                </Col>
            </Row>
        )
    }

    function renderReviewCardForSentTab(reviewitem) {
        return (
            <div>
                <div style={{ marginTop: '5%', cursor: 'pointer' }} onClick={() => {
                    navigate(`/myProfile?userId=${reviewitem.TargetUserId}`)
                }}>
                    <Row className="my-3">
                        <Col xs={1} md={1} lg={1}><Image src={reviewitem.TargetUserImage} width={50} height={50} style={{ borderRadius: 50 }} /></Col>
                        <Col xs={2} md={4} lg={4}>{reviewitem.TargetUserName}</Col>
                        <Col className="text-end">{reviewitem.CreatedTimestampDisplay}</Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <ReactStarRatings
                                rating={reviewitem.Likes}  // Set the initial rating value
                                starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                                numberOfStars={5}  // Set the total number of stars to display
                                starDimension="20px"  // Set the size of each star
                                starSpacing="1px"  // Set the spacing between each sta
                            />
                        </Col>
                    </Row>
                    {
                        reviewitem.DisplayAttachmentPhoto.length > 0 &&
                        <Row className="my-3">
                            <Image
                                src={reviewitem.DisplayAttachmentPhoto[0]}
                                style={{
                                    width: 100, height: 100
                                }}
                            />
                        </Row>
                    }
                    <Row className="my-3">
                        <Col>{reviewitem.ReviewMessage}</Col>
                    </Row>
                </div>
                <Row className='mt-2'>
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: COLORS.black,
                        borderWidth: 1,
                        borderColor: COLORS.black
                    }} onClick={() => {
                        //console.log(reviewitem)
                        navigate(`/reviewrating?${queryString.stringify({
                            imageDisplay: reviewitem.TargetUserImage,
                            name: reviewitem.TargetUserName,
                            ratingStar: reviewitem.Likes,
                            description: reviewitem.ReviewMessage,
                            targetUserId: reviewitem.TargetUserId,
                            attachmentPhotos: reviewitem.AttachmentPhoto,
                            attachmentPhotosURL: reviewitem.DisplayAttachmentPhoto[0]
                        })}`)
                    }}>
                        {t('MyReviews.Edit')}
                    </button>
                </Row>
            </div>
        )
    }

    function renderReviewCard(reviewitem) {
        return (
            <div style={{ marginTop: '5%', cursor: 'pointer' }} onClick={() => {
                navigate(`/myProfile?userId=${reviewitem.ReviewerUserId}`)
            }}>
                <Row className="my-3">
                    <Col xs={1} md={1} lg={1}><Image src={reviewitem.ReviewerImage} width={50} height={50} style={{ borderRadius: 50 }} /></Col>
                    <Col xs={2} md={4} lg={4}>{reviewitem.ReviewerName}</Col>
                    <Col className="text-end">{reviewitem.CreatedTimestampDisplay}</Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <ReactStarRatings
                            rating={reviewitem.Likes}  // Set the initial rating value
                            starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                            numberOfStars={5}  // Set the total number of stars to display
                            starDimension="20px"  // Set the size of each star
                            starSpacing="1px"  // Set the spacing between each sta
                        />
                    </Col>
                </Row>
                {
                    reviewitem.DisplayAttachmentPhoto.length > 0 &&
                    <Row className="my-3">
                        <Image
                            src={reviewitem.DisplayAttachmentPhoto[0]}
                            style={{
                                width: 100, height: 100
                            }}
                        />
                    </Row>
                }

                <Row className="my-3">
                    <Col>{reviewitem.ReviewMessage}</Col>
                </Row>
            </div>
        )
    }

    function renderSentListing() {
        return (
            <div>
                {
                    postReviewListing == "" ?
                        <div style={{ marginTop: '5%' }}>{t("MyReviews.Don't have any reviews yet")}</div>
                        : postReviewListing.map((item, index) => (
                            renderReviewCardForSentTab(item)
                        ))
                }
            </div>
        )
    }


    function renderReceivedListing() {
        return (
            <div>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="p-5">
                            <Row>
                                <Col>
                                    <h1 className="fw-bold" style={{ textAlign: 'center' }}>{userRatingDetails?.UserRating}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <ReactStarRatings
                                        rating={userRatingDetails?.UserRating}  // Set the initial rating value
                                        starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                                        numberOfStars={5}  // Set the total number of stars to display
                                        starDimension="20px"  // Set the size of each star
                                        starSpacing="1px"  // Set the spacing between each sta
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <h5 className="fw-bold" style={{ textAlign: 'center' }}>{t('MyReviews.based on')} {userRatingDetails?.UserReviewListCount} {t('MyReviews.reviews')}</h5>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                {
                    receivedReviewListing == "" ?
                        <div style={{ marginTop: '5%' }}>{t("MyReviews.Don't have any reviews yet")}</div>
                        : receivedReviewListing.map((item, index) => (
                            renderReviewCard(item)
                        ))
                }
            </div>
        )
    }


    useEffect(() => {
        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const tab = searchParams.get('tab');

        if (tab == "1") {
            setTabSent(true)
            setTabReceived(false)

        }


        getUserPostReviewListing()
        getReceivedReviewListing()
    }, [])

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('MyReviews.My Reviews')}</h5>
                                </Col>
                            </Row>
                            {renderTabSelection()}
                            {tabReceived ? renderReceivedListing() : renderSentListing()}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyReviews