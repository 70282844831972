const getCountryCode = async () => {
    try {

        const geoResponse = await fetch(process.env.REACT_APP_BASE_URL + '/user/getCountry2', {
            headers: {'Authorization': process.env.REACT_APP_API_KEY}
        });

        let country = 'SG'

        const geoData = await geoResponse.json();
        if(geoData.ReturnCode == 200){
            country = geoData.GeoData
        }

        return country;
    } catch (error) {
        console.error(error);
    }
}

export default {
    isDevelopmentMode: true,
    debugAPI: process.env.REACT_APP_BASE_URL,
    serverAPIKey: process.env.REACT_APP_API_KEY,
    slackWebhookURI: process.env.REACT_APP_SLACK,
    slackWebhookURI_API_STRING: '',

    serverAPICall: async function (api, method, body, userid, latitude, longitude, countryCode) {
        // console.log('index.js countryCode: ', countryCode);
        // console.log('body pass into isDevelopmentMode: true', api, method, body, userid, latitude, longitude)
        const response = await fetch(api, {
            method: method,
            // headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': this.serverAPIKey,
            //     // 'CountryCode': await getCountryCode(),
            //     'UserId': userid != undefined ? userid : null,
            //     'Latitude': 1.445,
            //     'Longitude': 103.860008
            // }
            headers:
                userid ?
                    {
                        'Content-Type': 'application/json',
                        'Authorization': this.serverAPIKey,
                        'CountryCode': countryCode ? countryCode : await getCountryCode(),
                        'UserId': userid,
                        'Latitude': latitude ? latitude : 1.445,
                        'Longitude': longitude ? longitude : 103.860008,
                        'Lang': localStorage.getItem('i18nextLng'),
                        // 'Latitude': 1.445,
                        // 'Longitude': 103.860008
                    }
                    :
                    {
                        'Content-Type': 'application/json',
                        'Authorization': this.serverAPIKey,
                        'CountryCode': countryCode ? countryCode : await getCountryCode(),
                        'Latitude': latitude ? latitude : 1.445,
                        'Longitude': longitude ? longitude : 103.860008,
                        'Lang': localStorage.getItem('i18nextLng'),
                        // 'Latitude': 1.445,
                        // 'Longitude': 103.860008
                    }
            ,
            body: body != undefined ? body : null
        });

        // if (this.isDevelopmentMode == true) {
        //     this.slackWebhook_API_STRING(
        //         "testUser",
        //         new Date(),
        //         api,
        //         body,
        //         JSON.stringify(
        //             {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': this.serverAPIKey,
        //             }
        //         )
        //     );
        // }

        return (response);
    },

    getCountry: async function(){
        return await getCountryCode();
    },

    getStripeKey: async function (cc) {
        let countryCode = cc == null ? await getCountryCode() : cc
        // countryCode = countryCode == null ? 'SG' : countryCode
        if (countryCode == 'MY') {
            console.log('getStripeKey 1')
            return process.env.REACT_APP_STRIPE_PK_MY;
        } else {
            console.log('getStripeKey 2')
            return process.env.REACT_APP_STRIPE_PK_SG;
        }
    },

    handleServerAPICallErrors: function (response) {
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
            throw new TypeError("Server responsed " + contentType);
        }
        return response.json();
    },
    slackWebhook: function (userId, timestamp, api, error, body) {
        var id = '*User Id*  \t\t\t\t: "' + userId + '"';
        var time = '*Time stamp*  \t\t: "' + timestamp + '"';
        var APIfunction = '*API*\t\t\t\t\t\t: "' + api + '"';
        var errorMessage = '*Error Message* \t: "' + error.toString() + '"';
        var data = '*Body* \t\t\t\t\t: ' + (body != undefined ? body : '"GET Request no body"');
        var payload = '<---Start--->\n' + id + '\n' + time + '\n' + APIfunction + '\n' + errorMessage + '\n' + data + '\n' + '<---End--->';
        fetch(this.slackWebhookURI, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "text": payload,
            }),
        })
            .catch((error) => {
                // console.log(error)
            });
    },
    slackWebhook_API_STRING: function (userId, timestamp, api, body, headers) {
        var id = '*User Id*  \t\t\t\t: "' + userId + '"';
        var time = '*Time stamp*  \t\t: "' + timestamp + '"';
        var APIfunction = '*API*\t\t\t\t\t\t: "' + api + '"';
        var header = '*Headers*  \t\t\t\t: ' + headers;
        var data = '*Body* \t\t\t\t\t: ' + (body != undefined ? body : '"GET Request no body"');
        var payload = '<---Start--->\n' + id + '\n' + time + '\n' + APIfunction + '\n' + header + '\n' + data + '\n' + '<---End--->';
        fetch(this.slackWebhookURI_API_STRING, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "text": payload,
            }),
        })
            .catch((error) => {
                // console.log(error) 
            });
    },

}