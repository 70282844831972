import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";

const Terms = () => {
  const { pathname, search } = useLocation();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    let queryParam = queryString.parse(search);
    let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue')
    i18n.changeLanguage(_lang);
  }, [])

  return (
    <div className='greybk-div p-lg-5'>
      <Container className='box-container p-5'>
        <Row>
          <Col><h4 className='fw-bold pb-3'>{t('terms.Terms of Use')}</h4></Col>
        </Row>

        <Row>
          <Col>
            <p>
              {t('terms.Terms Title')}
            </p>

            <h5>{t('terms.1Title')}</h5>
            <h6>{t('terms.1.1Title')}</h6>
            <p>
              {t('terms.1.1Point1')}
            </p>

            <h6>{t('terms.1.2Title')}</h6>
            <p>
              {t('terms.1.2Point1')}
            </p>
            <h6>{t('terms.1.3Title')}</h6>
            <p>
              {t('terms.1.3Point1')}
            </p>
            <ul>
              <li>{t('terms.1.3Point1a')}</li>
              <li>{t('terms.1.3Point1b')}</li>
              <li>{t('terms.1.3Point1c')}</li>
              <li>{t('terms.1.3Point1d')}</li>
              <li>{t('terms.1.3Point1e')}</li>
              <li>{t('terms.1.3Point1f')}</li>
            </ul>

            <h5>{t('terms.2Title')}</h5>
            <h6>{t('terms.2.1Title')}</h6>
            <p>
              {t('terms.2.1Point1')}
            </p>

            <h6>{t('terms.2.2Title')}</h6>
            <p>
              {t('terms.2.2Point1')}
            </p>
            <h6>{t('terms.2.3Title')}</h6>
            <p>
              {t('terms.2.3Point1')}
            </p>
            <ul>
              <li>{t('terms.2.3Point1a')}</li>
              <li>{t('terms.2.3Point1b')}</li>
              <li>{t('terms.2.3Point1c')}</li>
            </ul>

            <h5>{t('terms.3Title')}</h5>
            <h6>{t('terms.3.1Title')}</h6>
            <p>
              {t('terms.3.1Point1')}
            </p>

            <h6>{t('terms.3.2Title')}</h6>
            <p>
              {t('terms.3.2Point1')}
            </p>

            <h5>{t('terms.4Title')}</h5>
            <p>
              {t('terms.4Point1')}
            </p>

            <h5>{t('terms.5Title')}</h5>
            <p>
              {t('terms.5Point1')}
            </p>

            <h5>{t('terms.6Title')}</h5>
            <p>
              {t('terms.6Point1')}
            </p>

            <h5>{t('terms.7Title')}</h5>
            <p>
              {t('terms.7Point1')}
            </p>

            <h5>{t('terms.8Title')}</h5>
            <p>
              {t('terms.8Point1')}
            </p>

            <h5>{t('terms.9Title')}</h5>
            <p>
              {t('terms.9Point1')}<a href="mailto:help@adviser2u.com">help@adviser2u.com</a>.
            </p>
          </Col>
        </Row>


      </Container>

    </div>
  )
}

export default Terms