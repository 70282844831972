import React, { useState, useEffect } from 'react';
import Chat from './Chat';
import Notification from './Notification';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { SHA256 } from "crypto-js";
import { useSelector } from "react-redux";
import './chatstyle.css';
import Navigation from '../../components/Navigation';
import domainName from '../../domainName';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";

const App = () => {
    const { pathname, search } = useLocation();

    const { UserData, UserId } = useSelector((state) => state.login);
    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);
    const [activeTab, setActiveTab] = useState(1);
    const [passInUserId, setPassInUserId] = useState('');
    const [passInMessageId, setPassInMessageId] = useState('');
    const [isFromAppUser, setIsFromAppUser] = useState(false);
    // const [needToHideNavBar, setNeedToHideNavBar] = useState(false);

    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    const userAgent = navigator.userAgent;

    //for navigation bar visibility
    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [showTabNavigation, setShowTabNavigation] = useState(true);

    const [t, i18n] = useTranslation("global");

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);

        // this is to append &tab=notification, so that when they click back can back to the correct tab
        if (tabNumber === 2) {
            const currentURL = window.location.href;
            const newText = '&tab=notification';

            if (currentURL.indexOf(newText) === -1) {
                const newURL = currentURL + newText;
                window.history.pushState({ path: newURL }, '', newURL);
            }
        } else {
        }
    };

    const [currentUrl, setCurrentUrl] = useState('');
    const [oneSignalNotificationId, setOneSignalNotificationId] = useState('');
    const [notiId, setNotiId] = useState('');

    const handleMessageComeInRefreshPage = (data) => {
        // Set the API result in App's state
        setOneSignalNotificationId(data);
    };

    useEffect(() => {
        console.log('HERE!!', oneSignalNotificationId);
        setNotiId(oneSignalNotificationId);
    }, [oneSignalNotificationId]);

    useEffect(() => {
        setIsIOS(!!userAgent.match(/iPad|iPhone|iPod/)); // Match iOS devices
        setIsAndroid(!!userAgent.match(/Android/)); // Match Android devices

        setCurrentUrl(window.location.href);

        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const encryptedID = searchParams.get('uid');
        const userid = searchParams.get('userId');
        const timeid = searchParams.get('tid');
        const messageId = searchParams.get('messageId');
        const isFromApp = searchParams.get('isTarget') ? searchParams.get('isTarget') == 'app' : false;
        setIsFromAppUser(isFromApp)
        // setNeedToHideNavBar(isFromApp ? true : false)

        const tab = searchParams.get('tab');

        getTotalUnread(userid);

        if (tab == 'notification') {
            setActiveTab(2);
            return;
        }

        let isValidUser = false;

        // here to check if user have login or have uid only could proceed.
        if (encryptedID) {
            const key = process.env.REACT_APP_COMMUNITY_APP_KEY;
            const ciphertext = SHA256(userid + key + process.env.REACT_APP_KEY + timeid).toString();

            // if match only proceed here
            setPassInUserId(userid);
            isValidUser = true;
        } else {
            // check got login?
            if (UserData) {
                setPassInUserId(UserId);
                isValidUser = true;
            }
        }

        if (isValidUser) {
            // check message isValid24hexstring
            let isValidMessageId = false;

            if (messageId == 1) {
                // setNeedToHideNavBar(true)
                // show list only
            } else {
                // show target conversation
                if (messageId != null) {
                    var hex24Pattern = /^[0-9a-fA-F]{24}$/;
                    if (hex24Pattern.test(messageId)) {
                        isValidMessageId = true;
                        setPassInMessageId(messageId);
                    }
                }
            }
        }

        // setNeedToHideNavBar(true)


        let queryParam = queryString.parse(search);
        let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue');
        i18n.changeLanguage(_lang);
    }, []);

    useEffect(() => {
        // Check screen width and update isSmallScreen state
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768); // Adjust the threshold as needed
        };

        // Initial check and add event listener
        handleResize();
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // get latest unread count
    async function getTotalUnread(id) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/meMenu",
                "POST",
                JSON.stringify({
                    "UserId": id,
                    "DisplayScreen": "User"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                console.log('responseJson.TotalUnreadMessage:: ', responseJson.TotalUnreadMessage);
                setUnreadMessageCount(responseJson.TotalUnreadMessage);
                setUnreadNotificationCount(responseJson.NotificationUnreadCount);
            }))
            .catch((error) => {
                // console.log('/user/meMenu error here', error)
            });
    }

    return (
        <div>
            {/* i want to hide the navigation bar if  is true */}
            {/* {!isIOS && !isAndroid && <Navigation onMessageComeIn={handleMessageComeInRefreshPage} />} */}

            {!(passInUserId && passInMessageId && isSmallScreen) && !isFromAppUser && (
                <Navigation onMessageComeIn={handleMessageComeInRefreshPage} />
            )}


            <div className={isWebsite ? 'greybk-div p-lg-5' : ''} >
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col className='col-12'>
                            <div className='box-container p-md-4' style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none' }}>
                                {showTabNavigation && (
                                    <ul className="nav nav-tabs tab-navigation">
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                                onClick={() => handleTabChange(1)}
                                                style={{
                                                    marginBottom: activeTab === 1 ? '1px' : '0',
                                                    borderBottom: activeTab === 1 ? '2px solid #C0281C' : 'none',
                                                    borderTop: 'none',
                                                    borderLeft: 'none',
                                                    borderRight: 'none',
                                                    color: activeTab === 1 ? 'black' : 'grey',
                                                    fontWeight: activeTab === 1 ? 'bold' : 'normal',
                                                }}
                                            >{t('notification.Chat')}
                                                {isSmallScreen && unreadMessageCount > 0 && (
                                                    <Badge bg='danger' style={{ marginLeft: '15px' }}>{unreadMessageCount}</Badge>
                                                )}
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                                onClick={() => handleTabChange(2)}
                                                style={{
                                                    marginBottom: activeTab === 2 ? '1px' : '0',
                                                    borderBottom: activeTab === 2 ? '2px solid #C0281C' : 'none',
                                                    borderTop: 'none',
                                                    borderLeft: 'none',
                                                    borderRight: 'none',
                                                    color: activeTab === 2 ? 'black' : 'grey',
                                                    fontWeight: activeTab === 2 ? 'bold' : 'bold',
                                                }}
                                            >{t('notification.Notification')}
                                                {isSmallScreen && unreadNotificationCount > 0 && (
                                                    <Badge bg='danger' style={{ marginLeft: '15px' }}>{unreadNotificationCount}</Badge>
                                                )}
                                            </button>
                                        </li>
                                    </ul>
                                )}
                                <div className="tab-content">
                                    <div className={`tab-pane ${activeTab === 1 ? 'show active' : ''}`}>
                                        <Chat userId={passInUserId} messageId={passInMessageId} onesignalId={notiId} setShowTabNavigation={setShowTabNavigation} />
                                    </div>
                                    <div className={`tab-pane ${activeTab === 2 ? 'show active' : ''}`}>
                                        <Notification />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default App;
