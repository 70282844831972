import React, { useEffect } from 'react'
import { useState } from 'react';
import { Container, Row, Col, Button, Dropdown, Modal } from 'react-bootstrap';
import { COLORS, SIZES, FONTS } from '../../colorTheme/colorTheme';
import domainName from '../../domainName';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { SHA256 } from 'crypto-js';
import { useTranslation } from "react-i18next";


const ForgotPassword = () => {
    const [t, i18n] = useTranslation("global");
    const { search } = useLocation();
    const smsKey = process.env.REACT_APP_SMS_SECRET_KEY;
    const apiKey = process.env.REACT_APP_API_KEY;

    // for otp count down
    const [isRequestingOTP, setIsRequestingOTP] = useState(false);
    const cooldownDuration = 30; // cooldown period in seconds

    const navigate = useNavigate();

    const [email, setEmail] = useState('')

    const [isEmail, setIsEmail] = useState(true)

    const [buttonMessage, setButtonMessage] = useState(t('forgotPassword.EnterTheEmailAddress'))

    const [resetMethodMessage, setResetMethodMessage] = useState(t("forgotPassword.ResetViaPhoneNumber"))

    const [frontCountryPhoneNumber, setFrontCountryPhoneNumber] = useState('+60');

    const [mobileNumber, setMobileNumber] = useState('');

    const [emailLabelRedWords, setEmailLabelRedWords] = useState('')
    const [mobileLabelRedWords, setMobileLabelRedWords] = useState('')



    useEffect(() => {
        let queryParam = queryString.parse(search);
        let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue')
        i18n.changeLanguage(_lang);
    }, [])

    useEffect(() => {
        console.log('isEmail 2 :: ', isEmail);
        if (isEmail) {
            setButtonMessage(t("forgotPassword.EnterTheEmailAddress"));
            setResetMethodMessage(t("forgotPassword.ResetViaPhoneNumber"));
        } else {
            setButtonMessage(t("forgotPassword.EnterThePhoneNumber"));
            setResetMethodMessage(t("forgotPassword.ResetViaEmail"));
        }
    }, [isEmail]);


    // for country code selector 
    const countryData = [
        { code: '+93', name: 'Afghanistan' },
        { code: '+355', name: 'Albania' },
        { code: '+213', name: 'Algeria' },
        { code: '+1-684', name: 'American Samoa' },
        { code: '+376', name: 'Andorra' },
        { code: '+244', name: 'Angola' },
        { code: '+1-264', name: 'Anguilla' },
        { code: '+672', name: 'Antarctica' },
        { code: '+1-268', name: 'Antigua and Barbuda' },
        { code: '+54', name: 'Argentina' },
        { code: '+374', name: 'Armenia' },
        { code: '+297', name: 'Aruba' },
        { code: '+61', name: 'Australia' },
        { code: '+43', name: 'Austria' },
        { code: '+994', name: 'Azerbaijan' },
        { code: '+1-242', name: 'Bahamas' },
        { code: '+973', name: 'Bahrain' },
        { code: '+880', name: 'Bangladesh' },
        { code: '+1-246', name: 'Barbados' },
        { code: '+375', name: 'Belarus' },
        { code: '+32', name: 'Belgium' },
        { code: '+501', name: 'Belize' },
        { code: '+229', name: 'Benin' },
        { code: '+1-441', name: 'Bermuda' },
        { code: '+975', name: 'Bhutan' },
        { code: '+591', name: 'Bolivia' },
        { code: '+387', name: 'Bosnia and Herzegovina' },
        { code: '+267', name: 'Botswana' },
        { code: '+55', name: 'Brazil' },
        { code: '+246', name: 'British Indian Ocean Territory' },
        { code: '+1-284', name: 'British Virgin Islands' },
        { code: '+673', name: 'Brunei' },
        { code: '+359', name: 'Bulgaria' },
        { code: '+226', name: 'Burkina Faso' },
        { code: '+257', name: 'Burundi' },
        { code: '+855', name: 'Cambodia' },
        { code: '+237', name: 'Cameroon' },
        { code: '+1', name: 'Canada' },
        { code: '+238', name: 'Cape Verde' },
        { code: '+1-345', name: 'Cayman Islands' },
        { code: '+236', name: 'Central African Republic' },
        { code: '+235', name: 'Chad' },
        { code: '+56', name: 'Chile' },
        { code: '+86', name: 'China' },
        { code: '+61', name: 'Christmas Island' },
        { code: '+61', name: 'Cocos Islands' },
        { code: '+57', name: 'Colombia' },
        { code: '+269', name: 'Comoros' },
        { code: '+682', name: 'Cook Islands' },
        { code: '+506', name: 'Costa Rica' },
        { code: '+385', name: 'Croatia' },
        { code: '+53', name: 'Cuba' },
        { code: '+599', name: 'Curacao' },
        { code: '+357', name: 'Cyprus' },
        { code: '+420', name: 'Czech Republic' },
        { code: '+243', name: 'Democratic Republic of the Congo' },
        { code: '+45', name: 'Denmark' },
        { code: '+253', name: 'Djibouti' },
        { code: '+1-767', name: 'Dominica' },
        { code: '+1-809, +1-829, +1-849', name: 'Dominican Republic' },
        { code: '+670', name: 'East Timor' },
        { code: '+593', name: 'Ecuador' },
        { code: '+20', name: 'Egypt' },
        { code: '+503', name: 'El Salvador' },
        { code: '+240', name: 'Equatorial Guinea' },
        { code: '+291', name: 'Eritrea' },
        { code: '+372', name: 'Estonia' },
        { code: '+251', name: 'Ethiopia' },
        { code: '+500', name: 'Falkland Islands' },
        { code: '+298', name: 'Faroe Islands' },
        { code: '+679', name: 'Fiji' },
        { code: '+358', name: 'Finland' },
        { code: '+33', name: 'France' },
        { code: '+689', name: 'French Polynesia' },
        { code: '+241', name: 'Gabon' },
        { code: '+220', name: 'Gambia' },
        { code: '+995', name: 'Georgia' },
        { code: '+49', name: 'Germany' },
        { code: '+233', name: 'Ghana' },
        { code: '+350', name: 'Gibraltar' },
        { code: '+30', name: 'Greece' },
        { code: '+299', name: 'Greenland' },
        { code: '+1-473', name: 'Grenada' },
        { code: '+1-671', name: 'Guam' },
        { code: '+502', name: 'Guatemala' },
        { code: '+44-1481', name: 'Guernsey' },
        { code: '+224', name: 'Guinea' },
        { code: '+245', name: 'Guinea-Bissau' },
        { code: '+592', name: 'Guyana' },
        { code: '+509', name: 'Haiti' },
        { code: '+504', name: 'Honduras' },
        { code: '+852', name: 'Hong Kong' },
        { code: '+36', name: 'Hungary' },
        { code: '+354', name: 'Iceland' },
        { code: '+91', name: 'India' },
        { code: '+62', name: 'Indonesia' },
        { code: '+98', name: 'Iran' },
        { code: '+964', name: 'Iraq' },
        { code: '+353', name: 'Ireland' },
        { code: '+44-1624', name: 'Isle of Man' },
        { code: '+972', name: 'Israel' },
        { code: '+39', name: 'Italy' },
        { code: '+225', name: 'Ivory Coast' },
        { code: '+1-876', name: 'Jamaica' },
        { code: '+81', name: 'Japan' },
        { code: '+44-1534', name: 'Jersey' },
        { code: '+962', name: 'Jordan' },
        { code: '+7', name: 'Kazakhstan' },
        { code: '+254', name: 'Kenya' },
        { code: '+686', name: 'Kiribati' },
        { code: '+383', name: 'Kosovo' },
        { code: '+965', name: 'Kuwait' },
        { code: '+996', name: 'Kyrgyzstan' },
        { code: '+856', name: 'Laos' },
        { code: '+371', name: 'Latvia' },
        { code: '+961', name: 'Lebanon' },
        { code: '+266', name: 'Lesotho' },
        { code: '+231', name: 'Liberia' },
        { code: '+218', name: 'Libya' },
        { code: '+423', name: 'Liechtenstein' },
        { code: '+370', name: 'Lithuania' },
        { code: '+352', name: 'Luxembourg' },
        { code: '+853', name: 'Macao' },
        { code: '+389', name: 'Macedonia' },
        { code: '+261', name: 'Madagascar' },
        { code: '+265', name: 'Malawi' },
        { code: '+60', name: 'Malaysia' },
        { code: '+960', name: 'Maldives' },
        { code: '+223', name: 'Mali' },
        { code: '+356', name: 'Malta' },
        { code: '+692', name: 'Marshall Islands' },
        { code: '+222', name: 'Mauritania' },
        { code: '+230', name: 'Mauritius' },
        { code: '+262', name: 'Mayotte' },
        { code: '+52', name: 'Mexico' },
        { code: '+691', name: 'Micronesia' },
        { code: '+373', name: 'Moldova' },
        { code: '+377', name: 'Monaco' },
        { code: '+976', name: 'Mongolia' },
        { code: '+382', name: 'Montenegro' },
        { code: '+1-664', name: 'Montserrat' },
        { code: '+212', name: 'Morocco' },
        { code: '+258', name: 'Mozambique' },
        { code: '+95', name: 'Myanmar' },
        { code: '+264', name: 'Namibia' },
        { code: '+674', name: 'Nauru' },
        { code: '+977', name: 'Nepal' },
        { code: '+31', name: 'Netherlands' },
        { code: '+599', name: 'Netherlands Antilles' },
        { code: '+687', name: 'New Caledonia' },
        { code: '+64', name: 'New Zealand' },
        { code: '+505', name: 'Nicaragua' },
        { code: '+227', name: 'Niger' },
        { code: '+234', name: 'Nigeria' },
        { code: '+683', name: 'Niue' },
        { code: '+850', name: 'North Korea' },
        { code: '+1-670', name: 'Northern Mariana Islands' },
        { code: '+47', name: 'Norway' },
        { code: '+968', name: 'Oman' },
        { code: '+92', name: 'Pakistan' },
        { code: '+680', name: 'Palau' },
        { code: '+970', name: 'Palestine' },
        { code: '+507', name: 'Panama' },
        { code: '+675', name: 'Papua New Guinea' },
        { code: '+595', name: 'Paraguay' },
        { code: '+51', name: 'Peru' },
        { code: '+63', name: 'Philippines' },
        { code: '+870', name: 'Pitcairn' },
        { code: '+48', name: 'Poland' },
        { code: '+351', name: 'Portugal' },
        { code: '+1-787, +1-939', name: 'Puerto Rico' },
        { code: '+974', name: 'Qatar' },
        { code: '+242', name: 'Republic of the Congo' },
        { code: '+262', name: 'Reunion' },
        { code: '+40', name: 'Romania' },
        { code: '+7', name: 'Russia' },
        { code: '+250', name: 'Rwanda' },
        { code: '+590', name: 'Saint Barthelemy' },
        { code: '+290', name: 'Saint Helena' },
        { code: '+1-869', name: 'Saint Kitts and Nevis' },
        { code: '+1-758', name: 'Saint Lucia' },
        { code: '+590', name: 'Saint Martin' },
        { code: '+508', name: 'Saint Pierre and Miquelon' },
        { code: '+1-784', name: 'Saint Vincent and the Grenadines' },
        { code: '+685', name: 'Samoa' },
        { code: '+378', name: 'San Marino' },
        { code: '+239', name: 'Sao Tome and Principe' },
        { code: '+966', name: 'Saudi Arabia' },
        { code: '+221', name: 'Senegal' },
        { code: '+381', name: 'Serbia' },
        { code: '+248', name: 'Seychelles' },
        { code: '+232', name: 'Sierra Leone' },
        { code: '+65', name: 'Singapore' },
        { code: '+1-721', name: 'Sint Maarten' },
        { code: '+421', name: 'Slovakia' },
        { code: '+386', name: 'Slovenia' },
        { code: '+677', name: 'Solomon Islands' },
        { code: '+252', name: 'Somalia' },
        { code: '+27', name: 'South Africa' },
        { code: '+82', name: 'South Korea' },
        { code: '+211', name: 'South Sudan' },
        { code: '+34', name: 'Spain' },
        { code: '+94', name: 'Sri Lanka' },
        { code: '+249', name: 'Sudan' },
        { code: '+597', name: 'Suriname' },
        { code: '+47', name: 'Svalbard and Jan Mayen' },
        { code: '+268', name: 'Swaziland' },
        { code: '+46', name: 'Sweden' },
        { code: '+41', name: 'Switzerland' },
        { code: '+963', name: 'Syria' },
        { code: '+886', name: 'Taiwan' },
        { code: '+992', name: 'Tajikistan' },
        { code: '+255', name: 'Tanzania' },
        { code: '+66', name: 'Thailand' },
        { code: '+228', name: 'Togo' },
        { code: '+690', name: 'Tokelau' },
        { code: '+676', name: 'Tonga' },
        { code: '+1-868', name: 'Trinidad and Tobago' },
        { code: '+216', name: 'Tunisia' },
        { code: '+90', name: 'Turkey' },
        { code: '+993', name: 'Turkmenistan' },
        { code: '+1-649', name: 'Turks and Caicos Islands' },
        { code: '+688', name: 'Tuvalu' },
        { code: '+1-340', name: 'U.S. Virgin Islands' },
        { code: '+256', name: 'Uganda' },
        { code: '+380', name: 'Ukraine' },
        { code: '+971', name: 'United Arab Emirates' },
        { code: '+44', name: 'United Kingdom' },
        { code: '+1', name: 'United States' },
        { code: '+598', name: 'Uruguay' },
        { code: '+998', name: 'Uzbekistan' },
        { code: '+678', name: 'Vanuatu' },
        { code: '+379', name: 'Vatican' },
        { code: '+58', name: 'Venezuela' },
        { code: '+84', name: 'Vietnam' },
        { code: '+681', name: 'Wallis and Futuna' },
        { code: '+212', name: 'Western Sahara' },
        { code: '+967', name: 'Yemen' },
        { code: '+260', name: 'Zambia' },
        { code: '+263', name: 'Zimbabwe' },
    ];

    //allow only digit input in phone input textbox
    const handlePhoneInput = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9]*$/;

        if (regex.test(inputValue)) {
            setMobileNumber(inputValue);
            if (frontCountryPhoneNumber == '+60' && inputValue.startsWith('0')) { // if Malaysia phone number only
                // Remove the leading '0'
                setMobileNumber(inputValue.slice(1));
            } else {
                // If the input doesn't start with '0', set the number input directly
                setMobileNumber(inputValue);
            }
        } else {
            setMobileNumber('');
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleViaClicked = async () => {
        setIsEmail(!isEmail)
    }

    const sendEmailLinkForgotPassword = async (emailInput, memberName) => {
        let returnRecipient;

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/sendEmailWhenForgotPassword",
                "POST",
                JSON.stringify({
                    "Email": emailInput,
                    "MemberName": memberName
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                ////console.log(responseJson);
                returnRecipient = responseJson.Data.Recipient[0];
            }))
            .catch((error) => {
                ////console.log("/user/sendEmailWhenForgotPassword error: ", error)
            });

        return returnRecipient;
    }

    // processing email existing valid or not
    const processCheckEmailValid = async () => {

        ////console.log("processCheckEmailValid , email :", email)

        if (email == "") {
            setEmailLabelRedWords('Email cannot be empty')
        } else {
            // // here need to do validation , if the user go next label, then call APi to check this Email no is whether valid
            const dataCode = await checkEmailValid()

            //console.log('dataCode.Data', dataCode.Data)
            if (dataCode.Data != null) {
                // means existed, show success image 
                setEmailLabelRedWords('')
                let recipientEmail = await sendEmailLinkForgotPassword(email, dataCode.Data.Name)

                if (recipientEmail == email) {
                    navigate('/signin')
                    alert(t("error.Success! Please go your email box and reset the password."))
                } else {
                    alert(`${t("error.Fail to sent link to")} ${email} ${t("error., may contact administrator !")}`)
                }
            } else {
                // means not existed, need to show red words
                setEmailLabelRedWords('Invalid email')
            }
        }

    }

    const checkEmailValid = async () => {
        let returnResponseData;
        //console.log('checkEmailNumberValid')

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "Email": email,
                    "LoginType": "EMAIL"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                //console.log("/user/checkingMobileNoOrEmailIsExisting", responseJson);
                returnResponseData = responseJson;
            }))
            .catch((error) => {
                //console.log("/user/checkingMobileNoOrEmailIsExisting error:", error)
            });
        return returnResponseData
    }

    async function sendOTP(phoneNumber) {

        //console.log(`  "/user/sendTwilioOtp": phoneNumber,`, phoneNumber)
        const smsid = SHA256(phoneNumber + "Adviser2u" + apiKey + smsKey)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/sendTwilioOtp",
                "POST",
                JSON.stringify({
                    "MobileNo": phoneNumber,
                    "SMS_ID": smsid.toString()
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/sendTwilioOtp responseJson here', responseJson)
                if (responseJson.ReturnCode == 200) {
                    setMobileLabelRedWords('')
                    navigate(`/enterverificationcode?${queryString.stringify({
                        phoneNumber: phoneNumber,
                        password: undefined
                    })}`)
                } else {
                    alert(t('error.Send otp got error, may contact administrator'))
                }
            }))
            .catch((error) => {
                //console.log('/user/sendTwilioOtp error here', error)
            });

    }


    // processing mobile is valid or not
    const processCheckMobileNumberValid = async () => {

        //console.log("processCheckMobileNumberValid phone number :", frontCountryPhoneNumber + mobileNumber)
        // // here need to do validation , if the user go next label, then call APi to check this mobile no is whether valid
        const dataCode = await checkMobileNumberValid(frontCountryPhoneNumber + mobileNumber)
        // console.log('dataCode.Data', dataCode.Data)
        if (dataCode.Data != null) {
            // means not existed, show nothings
            if (!isRequestingOTP) {

                setIsRequestingOTP(true);
                // Proceed with OTP request
                await sendOTP(frontCountryPhoneNumber + mobileNumber)

                setTimeout(() => {
                    setIsRequestingOTP(false);
                }, cooldownDuration * 1000);

            } else {
                alert(t('error.Oops! Please wait 30 seconds before requesting OTP again.'))
            }

        } else {
            // means not existed, need to show red words
            setMobileLabelRedWords('Invalid mobile no.')


        }
    }

    const checkMobileNumberValid = async (phonenumber) => {
        let returnResponseData;
        //console.log('checkMobileNumberValid')
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "MobileNo": phonenumber,
                    "LoginType": "MOBILE_NO"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                //console.log(responseJson);
                returnResponseData = responseJson;
            }))
            .catch((error) => {
                //console.log("/user/checkingMobileNoOrEmailIsExisting error: ", error)
            });
        return returnResponseData
    }

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('forgotPassword.Forgot Password')}</h5>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col>
                                    <span>
                                        {buttonMessage}
                                    </span>
                                </Col>
                            </Row>

                            {
                                isEmail ?
                                    <Row className='mt-3'>
                                        <Col>
                                            <div>
                                                <label htmlFor="email">{t('forgotPassword.Email')}</label>
                                                <div className="input-group mt-2">
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={handleEmailChange}
                                                        required
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                processCheckEmailValid();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ textAlign: 'right' }}>
                                                    <span style={{ color: COLORS.red }}>{emailLabelRedWords}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    :
                                    <Row className='mt-3'>
                                        <Col>
                                            <div>
                                                <label htmlFor="email">{t('forgotPassword.Mobile No.')}</label>
                                                <Row>
                                                    <Col className="col-4">
                                                        <Dropdown className="mt-2">
                                                            <Dropdown.Toggle className="form-control" variant="light" id="countryDropdown" style={{ backgroundColor: COLORS.white, borderColor: COLORS.lightGray1 }}>
                                                                {frontCountryPhoneNumber}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu alignRight>
                                                                {/* <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+60")}>+60</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+65")}>+65</Dropdown.Item> */}

                                                                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>=
                                                                    {countryData.map((country, index) => (
                                                                        <Dropdown.Item key={index} onClick={() => setFrontCountryPhoneNumber(country.code)}>
                                                                            {country.name} ({country.code})
                                                                        </Dropdown.Item>
                                                                    ))}
                                                                </div>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                    <Col className="col-8">
                                                        <div className="input-group mt-2">
                                                            <input
                                                                className="form-control"
                                                                type="tel"
                                                                id="mobileNumber"
                                                                name="mobileNumber"
                                                                value={mobileNumber}
                                                                onChange={handlePhoneInput}
                                                                required
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        processCheckMobileNumberValid();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <span style={{ color: COLORS.red }}>{mobileLabelRedWords}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>

                            }
                            {
                                isEmail ?
                                    <Row className='mt-3'>
                                        <Col>
                                            <Button className="input-group mt-2" onClick={processCheckEmailValid}>
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {t('forgotPassword.Send Verification Link via Email')}
                                                </span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row className='mt-3'>
                                        <Col>
                                            <Button className="input-group mt-2" onClick={processCheckMobileNumberValid}>
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {t('forgotPassword.Send Verification Code')}
                                                </span>
                                            </Button>
                                        </Col>
                                    </Row>

                            }


                            <Row className='mt-3'>
                                <Col className='col-5'>
                                    <div style={{ backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: '8%' }}></div>
                                </Col>
                                <Col className='col-2'>
                                    <div style={{ marginTop: '1%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>{t('forgotPassword.or')}</div>
                                </Col>
                                <Col className='col-5'>
                                    <div style={{ backgroundColor: '#D8D8D8', width: '100%', height: 1, marginTop: '8%' }}></div>
                                </Col>
                            </Row>

                            <Row className='mt-3'>
                                <Col>
                                    <button type="button" class="input-group btn btn-outline-secondary" style={{ backgroundColor: COLORS.white, borderColor: COLORS.orange, borderWidth: 1, color: COLORS.orange }} onClick={() => { handleViaClicked() }}>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {resetMethodMessage}
                                        </span>
                                    </button>
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ForgotPassword