import React, { useState, useEffect } from 'react';
import domainName from '../../domainName'
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';

import SubscriptionCard from '../../components/SubscriptionCard';
import { useNavigate, useLocation } from "react-router-dom";

import queryString from 'query-string';

import './SubscriptionStepOne.css';
import countryList from 'country-list';

import { useTranslation } from "react-i18next";


const SubscriptionStepOne = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, UserData } = useSelector((state) => state.login);

  const [cardNumber, setCardNumber] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');

  const [subscriptionPlan, setSubscriptionPlan] = useState([])
  const [postDetails, setPostDetails] = useState('')


  const [selectedPlan, setSelectedPlan] = useState({});

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true);

  // const queryStringParams = queryString.stringify({ selectedPlan });
  const [queryStringParams, setQueryStringParams] = useState('');
  const [uid, setUid] = useState(''); // this is userid pass in from url param. we should move this to token
  const [pathLang, setPathLang] = useState(''); 
  const [changePlan, setChangePlan] = useState('');
  const [showPleaseSignUpModal, setShowPleaseSignUpModal] = useState(false)

  const [loading, setLoading] = useState(false);
  // const [registeredCountry, setRegisteredCountry] = useState('');
  const [countryName, setCountryName] = useState('');

  const [t, i18n] = useTranslation("global");

  function handleSelectPlan(plan) {
    plan.uid = uid
    plan.changePlan = changePlan
    plan.listedService = (postDetails != null && postDetails.listed_service != null) ? postDetails.listed_service : null
    plan.lang = pathLang
    setSelectedPlan(plan);
    setIsContinueButtonDisabled(plan === null);
    // console.log(queryString.stringify(plan))
    setQueryStringParams(queryString.stringify(plan))
  }

  const getSubscriptionPlanList = async (countrycode, id) => {

    // console.log('getSubscriptionPlanList countrycode::', countrycode)
    // console.log('getSubscriptionPlanList id::', id)

    // check if have userId, 
    let registeredCountry = await domainName.getCountry();
    if (id != '' && id != undefined) {
      let cc = await fetchRegisteradivserCountry(id);
      registeredCountry = cc == null ? registeredCountry : cc;
    }

    // console.log('registeredCountry:: ', registeredCountry);
    // console.log('getCountryName:: ',getCountryName(registeredCountry));
    setCountryName(getCountryName(registeredCountry))

    await domainName
      .serverAPICall(
        domainName.debugAPI + "/adviser_plan/selectAdviserPlanlisting" + `${id !== '' && id != undefined ? `?UserId=${id}` : ''}`,
        "GET"
        , null, null, null, null
        , registeredCountry
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        setSubscriptionPlan(responseJson.Data)
        setPostDetails(responseJson.PostDetails)
      }))
      .catch((error) => {
        console.log(error)
        alert(
          t("error.Oops!"),
          t("An error occurred. Please try again later."),
          error
        );
      });

  }

  const getCurrentSubscribePlan = async (id) => {

    await domainName
      .serverAPICall(
        domainName.debugAPI + `/adviser_plan/selectAdviserPlanDetailsByUserId?UserId=${id}`,
        "GET"
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        ////console.log('responseJson:: ', responseJson)

        let to_change_plan = 0 // default if both SubscribeHistory & LastSubscribeHistory is null
        if (responseJson.Data.LastSubscribeHistory && responseJson.Data.SubscribeHistory)
          to_change_plan = 1 // go to change plan
        else if (responseJson.Data.LastSubscribeHistory && !responseJson.Data.SubscribeHistory)
          to_change_plan = 2// cancelled before, this time go to buy plan

        setChangePlan(to_change_plan)
        console.log('to_change_plan::', to_change_plan)

      }))
      .catch((error) => {
        alert(
          t("error.Oops!"),
          t("error.An error occurred. Please try again later."),
          error
        );
      });

  }

  function continueToStepTwo() {
    let isValidUser = false;

    if (uid != null) {
      var hex24Pattern = /^[0-9a-fA-F]{24}$/;
      if (hex24Pattern.test(uid)) {
        isValidUser = true
      }
    }


    if (isValidUser) {
      navigate(`/chooseListing?${queryStringParams}`);
    } else {
      // pop up modal
      setShowPleaseSignUpModal(true)
    }

    //
  }

  function PleaseSignUpModal() {
    return (
      <Modal show={showPleaseSignUpModal} onHide={() => setShowPleaseSignUpModal(false)} fade={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('subscriptionStepOne.Become an Adviser')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingLeft: '2%' }}>{t('subscriptionStepOne.Sign up now to become an Adviser!')}</Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={loading} onClick={() => {
            navigate(`/signup`);
          }}>
            {loading ? t('subscriptionStepOne.Loading...') : t('subscriptionStepOne.Sign up')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const fetchRegisteradivserCountry = async (id) => {
    return new Promise(async (resolve, reject) => {
      await domainName
        .serverAPICall(
          domainName.debugAPI + "/user/meMenu",
          "POST",
          JSON.stringify({
            "UserId": id,
            "DisplayScreen": "User"
          })
        )
        .then(domainName.handleServerAPICallErrors)
        .then((responseJson => {
          if (responseJson.RegisteradivserCountry) {
            resolve(responseJson.RegisteradivserCountry)
          } else {
            resolve(null)
          }
        }))
        .catch((error) => {
          // console.log('/user/meMenu error here', error)
          reject(error)
        });
    })
  }

  useEffect(() => {
    // console.log(selectedPlan)
    let uidObj = queryString.parse(search);
    console.log(uidObj)
    setUid(uidObj.uid)
    setPathLang(uidObj.lang)
    // setChangePlan(uidObj.changePlan)

    // Check if the 'lang' parameter is present in the URL
    let _lang = uidObj.lang ? uidObj.lang : localStorage.getItem('selectedValue')
    i18n.changeLanguage(_lang);

    // if (uidObj.lang) {
    //   i18n.changeLanguage(uidObj.lang);
    // }

    // check current subscription plan
    if (uidObj.uid != null && uidObj.uid != '') {
      getCurrentSubscribePlan(uidObj.uid)
    }

    getSubscriptionPlanList(uidObj.countrycode, uidObj.uid)
    // console.log('uid::',uidObj.uid)
  }, [])

  function getCountryName(countryCode) {
    console.log('11countryCode', countryCode)
    const countryName = countryList.getName(countryCode);
    return countryName ? countryName : `Country Name not found for code: ${countryCode}`
  }

  return (
    <div className='greybk-div p-lg-5'>
      <Container className='box-container p-md-5 p-4 pt-5 pb-5'>
        <Row>
          <Col><h5 className='fw-bold'>{t('subscriptionStepOne.Select a plan')}</h5></Col>
        </Row>
        <Row className='mb-3'>
          <Col>{t('subscriptionStepOne.You are currently on the')} {countryName} {t('subscriptionStepOne.plan, which allows you to post listings exclusively in')} {countryName}.</Col>
        </Row>


        <Row>
          {subscriptionPlan.map((item) => (
            <Col className='p-3' xs={12} md={6} xl={4} key={item._id}>
              <SubscriptionCard
                planName={item.Title}
                price={item.PlanName}
                photo={item.MaxPhoto}
                video={item.MaxVideo}
                location={item.MaxLocation}
                description={item.Description}
                isSelected={selectedPlan.PlanKey === item.PlanKey}
                onSelect={() => handleSelectPlan(item)}
              />
            </Col>
          ))}
        </Row>

        <Row className="justify-content-center align-items-center">
          <Col className='col-xl-2 col-lg-3'>
            <Button className='orange-btn text-center' onClick={() => {
              continueToStepTwo()
            }} disabled={isContinueButtonDisabled}>{t('subscriptionStepOne.Continue')}</Button>

          </Col>
        </Row>

        <PleaseSignUpModal />

      </Container>

    </div>
  )
}

export default SubscriptionStepOne