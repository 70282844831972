import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image } from 'react-bootstrap';
import domainName from '../../domainName';
import queryString from 'query-string';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Notification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [currentUrl, setCurrentUrl] = useState('');

    const [userId, setUserId] = useState('');
    const [notiList, setNotiList] = useState([]);

    const { UserData, UserId } = useSelector((state) => state.login);

    // for verify device
    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);
    const [isWebView, setIsWebView] = useState(false);
    const userAgent = navigator.userAgent;

    const [t] = useTranslation("global");

    const getNotiList = async () => {
        if (userId) {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + `/user/listNotificationsActivity`,
                    "POST",
                    JSON.stringify({
                        "UserId": userId,
                        "Filter_Type": 'ALL',
                        "PageNum": 1,
                        "ResultNum": 100
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    setNotiList(responseJson.Data);
                }))
                .catch((error) => {
                    alert(
                        t("error.Oops!"),
                        t("error.An error occurred. Please try again later."),
                        error
                    );
                });
        }
    };

    const unreadNotification = async (notificationId) => {
        if (userId) {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + `/user/updateNotificationsActivityToSeenById`,
                    "POST",
                    JSON.stringify({
                        "UserId": userId,
                        "NotificationId": notificationId,
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then(() => {
                    console.log('Notification marked as read!');
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        setCurrentUrl(window.location.href);

        const fetchData = async () => {
            // Detect iOS and Android devices
            const isIOSDevice = !!userAgent.match(/iPad|iPhone|iPod/);
            const isAndroidDevice = !!userAgent.match(/Android/);
            
            // Detect WebView based on userAgent or window object
            const isWebViewDevice = (() => {
                // For iOS WebView
                if (isIOSDevice && (window.navigator.standalone || window.webkit)) {
                    return true;
                }
                // For Android WebView
                if (isAndroidDevice && (window.chrome === undefined)) {
                    return true;
                }
                return false;
            })();

            setIsIOS(isIOSDevice);
            setIsAndroid(isAndroidDevice);
            setIsWebView(isWebViewDevice);

            let queryParams = queryString.parse(location.search);

            if (queryParams && queryParams.userId) {
                setUserId(queryParams.userId);
            } else {
                setUserId(UserId);
            }

            await getNotiList();
        };

        fetchData();
    }, [userId]);

    return (
        <div style={{ minHeight: "200px" }}>
            <Container>
                <Row>
                    <Col className='col-12'>
                        {(notiList && notiList.length > 0) ? notiList.map((item, index) => (
                            <div 
                                key={index} 
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    await unreadNotification(item._id);

                                    if (isIOS || isAndroid) {
                                        if (isWebView) {
                                            // Inside a WebView, send postMessage to the WebView
                                            window.postMessage(`${item.Link}`);
                                        } else {
                                            // In a mobile browser, navigate normally
                                            navigate(`/${item.Link.toString().split("advisor2uapp://")[1]}`);
                                        }
                                    } else {
                                        // For desktop or other devices, handle navigation normally
                                        navigate(`/${item.Link.toString().split("advisor2uapp://")[1]}`);
                                    }
                                }}
                            >
                                <Row>
                                    <Col className='d-flex p-3' style={{ backgroundColor: item.Seen ? 'transparent' : '#F8F8F8', borderBottom: '1px solid #D9D9D9' }}>
                                        <Image src={item.ImageDisplay} className='round-profile-image' />
                                        <div style={{ marginLeft: '15px' }}>
                                            <div className={`smaller-font ${item.Seen ? '' : 'fw-bold'}`}>{item.Message}</div>
                                            <div className='smaller-font'>{item.DisplayDateTime}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )) : <div>{t('notification.No notification')}</div>}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Notification;
