import React, { useEffect } from 'react';

function Blog() {


    return (
        <iframe
            id="wordpress-iframe"
            src="https://app.adviser2u.com/"
            width="100%"
            height="1000px"
            title="Embedded WordPress Site"
        />
    );
}

export default Blog;
