import React, { useState,useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import './DownloadBar.css';
import logo from '../img/Adviser2u-logo-new-transparent-bg-logo.png';
import Button from 'react-bootstrap/Button';
import { COLORS } from '../colorTheme/colorTheme';

const DownloadBar = () => {
    const userAgent = navigator.userAgent;

    const [progress, setProgress] = useState(0);
    const [view, setView] = useState('');
    const [isIOS, setIsIOS] = useState(!!userAgent.match(/iPad|iPhone|iPod/));
    const [isAndroid, setIsAndroid] = useState(!!userAgent.match(/Android/));

    const [showDownloadBar, setShowDownloadBar] = useState(true);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isMobileOrTabletDevice, setIsMobileOrTabletDevice] = useState(checkIfMobileOrTabletDevice());

    function checkIfMobileOrTabletDevice(){//check is mobile or not
        const thresholdWidth = 1024;//mobile width<768, 768<tablet width<1024
        return window.innerWidth <= thresholdWidth;
    }
    const closeBar = () => {
        console.log('Close button clicked');
        setShowDownloadBar(false);
      };
      const downloadFile = () => {//check android or ios
        const iosFileUrl = 'https://apps.apple.com/us/app/adviser2u/id6448983215';
        const androidFileUrl = 'https://play.google.com/store/apps/details?id=com.advisor2uapp';
        if (isIOS){
            window.open(iosFileUrl);
        }
        else{
            window.open(androidFileUrl);
        }
      };
  
    return (
        <div>
            {isMobileOrTabletDevice&&showDownloadBar&&(
                <div className="d-flex flex-row align-items-center mt-1 mb-3"> 
        
                    <button className="me-1 btn " onClick={closeBar} 
                    onMouseEnter={() => setIsHovered1(true)}
                    onMouseLeave={() => setIsHovered1(false)}
                    style={{ fontSize: '12px' }}
                    >
                        &#10005; {/* Unicode "X" character for close button */}
                    </button>
                    
                    
                    <div className="ml-2 mr-3">
                        <img className="img-fluid border rounded p-1"
                        src={logo} 
                        width="84" 
                        height="84"
                        alt="Download Image" 
                        style={{ borderColor: '#d0d0d0'}}/>
                    </div>
                    <div className="d-flex flex-column" style={{ marginLeft: '18px', marginRight: '2px'}}>
                        <div className="mb-1" style={{ fontWeight: 'bold', fontSize:'14px'}}>Adviser2u</div>
                        <div style={{fontSize:'10px'}}>Your nearby & reliable Adviser, all in adviser2u</div>
                    </div>
                    <div className='me-2 ms-auto'>
                        <button className="me-3 ms-3 btn btn-sm text-white" onClick={downloadFile}
                        onMouseEnter={() => setIsHovered1(true)}
                        onMouseLeave={() => setIsHovered1(false)}
                        style={{ 
                            alignItems: 'flex-end', 
                            display:'flex',
                            backgroundColor:'#C0281C',
                            whiteSpace: 'nowrap', // Prevents the text from wrapping
                            overflow: 'hidden', // Handles overflow if text is too long
                            textOverflow: 'ellipsis',
                            fontSize:'14px',
                        }}
                        >
                            Download
                        </button>
                    </div>
                </div>
            )}
        </div>
        
        // <div>
        //     {/* function renderDownloadBar(){
        //         return(<DownloadBar>Testing</DownloadBar>)
        //     } */}
        //     <h5>Test download</h5>
        //     {view == 'mobile' &&
        //     <>
        //         <Nav.Link href={isIOS ? "https://apps.apple.com/us/app/adviser2u/id6448983215" : "https://play.google.com/store/apps/details?id=com.advisor2uapp"}>Get the app</Nav.Link>
                

        //     </>
        //     }
            
        // </div>
        
    );
  };
  
  export default DownloadBar;