import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const [t, i18n] = useTranslation("global");
  const { pathname, search } = useLocation();

  useEffect(() => {
    let queryParam = queryString.parse(search);
    let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue')
    i18n.changeLanguage(_lang);
  }, [])

  return (
    <div className='greybk-div p-lg-5'>
      <Container className='box-container p-5'>
        <Row>
          <Col><h4 className='fw-bold pb-3'>{t('privacyPolicy.Privacy Policy')}</h4></Col>
        </Row>

        <Row>
          <Col>
            <p>
              {t('privacyPolicy.Title')}
            </p>
            <h5>{t('privacyPolicy.1Title')}</h5>
            <h6>{t('privacyPolicy.1.1Title')}</h6>
            <ul>
              <li>{t('privacyPolicy.1.1Point1')}</li>
              <li>{t('privacyPolicy.1.1Point2')}</li>
            </ul>
            <h6>{t('privacyPolicy.1.2Title')}</h6>
            <ul>
              <li>{t('privacyPolicy.1.2Point1')}</li>
              <li>{t('privacyPolicy.1.1Point2')}</li>
            </ul>
            <h5>{t('privacyPolicy.2Title')}</h5>
            <ul>
              <li>{t('privacyPolicy.2Point1')}</li>
              <li>{t('privacyPolicy.2Point2')}</li>
              <li>{t('privacyPolicy.2Point3')}</li>
            </ul>
            <h5>{t('privacyPolicy.3Title')}</h5>
            <ul>
              <li>{t('privacyPolicy.3Point1')}</li>
              <li>{t('privacyPolicy.3Point2')}</li>
              <li>{t('privacyPolicy.3Point3')}</li>
            </ul>
            <h5>{t('privacyPolicy.4Title')}</h5>
            <ul>
              <li>{t('privacyPolicy.4Point1')}</li>
              <li>{t('privacyPolicy.4Point2')}</li>
            </ul>
            <h5>{t('privacyPolicy.5Title')}</h5>
            <ul>
              <li>{t('privacyPolicy.5Point1')}</li>
              <li>{t('privacyPolicy.5Point2')}</li>
            </ul>
            <h5>{t('privacyPolicy.6Title')}</h5>
            <ul>
              <li>{t('privacyPolicy.6Point1')}</li>
            </ul>
            <h5>{t('privacyPolicy.7Title')}</h5>
            <ul>
              <li>{t('privacyPolicy.7Point1')}</li>
            </ul>
            <h5>{t('privacyPolicy.8Title')}</h5>
            <p>
              {t('privacyPolicy.8Point1')} <a href="mailto:help@adviser2u.com">help@adviser2u.com</a>.
            </p>
          </Col>
        </Row>


      </Container>

    </div>
  )
}

export default PrivacyPolicy