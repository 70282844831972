import React from 'react'
import { useState, useEffect } from 'react';
import domainName from '../../domainName'
import { useSelector } from 'react-redux';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { COLORS } from "../../colorTheme/colorTheme";
import AdvisorCard from '../../components/AdvisorCard';
import { useTranslation } from "react-i18next";


const Favourite = () => {

    const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);

    // set data from API
    const [favouriteListing, setFavouriteListing] = useState([])

    const [t] = useTranslation("global");


    async function getFavouriteServiceLsiting() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/exploreFavouriteSearviceListing",
                "POST",
                JSON.stringify({
                    "PageNum": 1,
                    "ResultNum": 100,
                }),
                UserId,
                latitude, // latitude
                longitude // longitude

            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log('/service/exploreFavouriteSearviceListing -> response : ', responseJson)
                setFavouriteListing(responseJson.Data)

            }))
            .catch((error) => {
                // console.log("/service/exploreFavouriteSearviceListing error", error)
            });
    }
    useEffect(() => {
        getFavouriteServiceLsiting()
    }, [])

    return (
        <Container className="width95"style={{ height: '100vh' }}>
            <Row>
                <Col>
                    <div className="w-100 mt-2 mb-1"><span style={{ fontWeight: 'bold', fontSize: 25 }}>{t('favourite.Favourite')}</span></div>
                    <br />
                </Col>
            </Row>


            {/* <div>{JSON.stringify(advisorSfavouriteListingerviceListings)}</div> */}

            <Row>

                {favouriteListing.map((item, index) => (
                    <AdvisorCard key={index} item={item} />
                ))}

            </Row>



        </Container>
    )
}

export default Favourite