import React, { useEffect } from 'react';

function FutureEmployee() {

    return (
        <iframe
            id="wordpress-iframe"
            src="https://app.adviser2u.com/future-employee"
            width="100%"
            height="1000px"
            title="Embedded WordPress Site"
        />
    );
}

export default FutureEmployee;
