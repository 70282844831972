import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import planIcon from '../../img/valid.svg';
import domainName from '../../domainName';
import { useNavigate, useLocation } from "react-router-dom";
import { COLORS, SIZES } from '../../colorTheme/colorTheme';
import bowser from 'bowser';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';


const App = () => {
    const { pathname, search } = useLocation();

    const [userIdFromParam, setUserIdFromParam] = useState('');
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)


    const { token, UserData, UserId } = useSelector((state) => state.login);
    const [subscriptionData, setSubscriptionData] = useState({})
    const [subscriptionHistoryData, setsubscriptionHistoryData] = useState({})
    const [lastSubscribeHistory, setLastSubscribeHistory] = useState({})
    const [changePlanButtonDisplay, setChangePlanButtonDisplay] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    const [t, i18n] = useTranslation("global");


    // const userAgent = navigator.userAgent;
    const [isMobile, setIsMobile] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const downloadSafari = () => {
        const qrCodeImageURL = subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeImage : null;

        if (qrCodeImageURL) {
            fetch(qrCodeImageURL)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'qr_code.png');
                    link.target = '_blank';
                    link.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error(t('error.Error fetching or downloading the image:'), error);
                });
        }
    };

    const download = () => {
        const isSafari = /^((?!chrome|android|ios).)*safari/i.test(navigator.userAgent);
        console.log(isSafari);
        if (isSafari) {
            downloadSafari();
        } else {
            const qrCodeImageURL = subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeImage : null;
            if (qrCodeImageURL) {
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = qrCodeImageURL;
                link.setAttribute('download', 'qr_code.png'); // Specify the desired filename here
                link.target = '_blank'; // Open in a new tab/window
                link.click();
            }
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copyText = () => {
        inputRef.current.select();
        document.execCommand('copy');
        // alert('Copied the text: ' + inputRef.current.value);
    };

    const changePlan = (action) => {
        // 0: newly subscribe. Go to free trial
        // 1: change plan. Go to change plan
        // 2: cancelled plan. Go to buy plan

        let _url = ``
        if (action > 0)
            _url = `&changePlan=${action}`

        if (subscriptionHistoryData && subscriptionHistoryData.CountryCode)
            _url += `&countrycode=${subscriptionHistoryData.CountryCode}`

        navigate(`/BecomeAnAdvisor?uid=${userIdFromParam}${_url}`)
    };

    const cancelSubscription = () => {
        setShowCancelSubscriptionModal(true)
    };

    const proceedToCancel = async () => {


        await domainName
            .serverAPICall(
                domainName.debugAPI + "/adviser_plan/adminCancelAdviserPlanSubscription",
                "POST",
                JSON.stringify({
                    "UserId": userIdFromParam,
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                if (responseJson.ReturnCode === 200) {
                    navigate(`/subscriptionSuccessful?changePlan=3&userId=${userIdFromParam}`);
                } else {
                    alert(
                        t("error.Unable to cancel plan. Please contact support team. Thank you.")
                    );
                }

            }))
            .catch((error) => {
                alert(
                    t("error.Oops!"),
                    t("error.An error occurred. Please try again later."),
                    error
                );
            });

        setShowCancelSubscriptionModal(false)
    };

    const getUserPlanDetails = async () => {

        ////console.log('xxx UserId::', UserId)
        ////console.log('xxx UserData::', UserData)


        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // check if have url querystring
        const userid = searchParams.get('userId');

        let uid = UserId

        if (userid != null && userid != '') {
            uid = userid
        }

        setUserIdFromParam(uid)

        await domainName
            .serverAPICall(
                domainName.debugAPI + `/adviser_plan/selectAdviserPlanDetailsByUserId?UserId=${uid}`,
                "GET"
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                ////console.log('responseJson:: ', responseJson)
                setSubscriptionData(responseJson.Data.SubscribePlanDisplay)
                setsubscriptionHistoryData(responseJson.Data.SubscribeHistory)
                setLastSubscribeHistory(responseJson.Data.LastSubscribeHistory)

                let changePlanBtnDisplay = 0 // default if both SubscribeHistory & LastSubscribeHistory is null
                if (responseJson.Data.LastSubscribeHistory && responseJson.Data.SubscribeHistory)
                    changePlanBtnDisplay = 1 // go to change plan
                else if (responseJson.Data.LastSubscribeHistory && !responseJson.Data.SubscribeHistory)
                    changePlanBtnDisplay = 2// cancelled before, this time go to buy plan


                setChangePlanButtonDisplay(changePlanBtnDisplay)
                console.log('changePlanBtnDisplay::', changePlanBtnDisplay)

                // setSubscriptionPlan(responseJson.Data)
            }))
            .catch((error) => {
                alert(
                    t("error.Oops!"),
                    t("error.An error occurred. Please try again later."),
                    error
                );
            });

    }


    const openWhatsApp = () => {
        console.log('isMobile:: ', isMobile)
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.ShareLinkContent : '');

        if (!isMobile) {
            const whatsappWebURL = `https://web.whatsapp.com/send?text=${message}`;
            window.open(whatsappWebURL);
        } else {

            const whatsappAppURL = `whatsapp://send?text=${message}`;
            window.location.href = whatsappAppURL;
        }
    };

    const openFacebook = () => {
        // const appID = '1384587305428357';
        // const link = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');
        // const redirectUri = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');
        // const quote = 'Your default text here'; // Replace with the default text you want to pre-fill.

        // const facebookURL = `https://www.facebook.com/dialog/feed?` +
        //     `app_id=${appID}&` +
        //     `link=${link}&` +
        //     `redirect_uri=${redirectUri}&` +
        //     `display=popup&` +
        //     `caption=YourCaptionHere&` +
        //     `description=YourDescriptionHere&` +
        //     `quote=${encodeURIComponent(quote)}`;

        // window.open(facebookURL, '_blank');
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');

        const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${message}`;
        window.open(facebookURL)
    };


    const openTwitter = () => {
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.ShareLinkContent_x_fb_linkedin : '');
        const instagramWebURL = `https://twitter.com/intent/tweet?text=${message}`;
        window.open(instagramWebURL);
    };

    const openLinkedIn = () => {
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${message}`);

    };

    const openTelegram = () => {
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.ShareLinkContent : '');

        if (!isMobile) {
            const telegramWebURL = `https://t.me/share/url?url=${message}`;
            window.open(telegramWebURL);
        } else {
            window.location.href = `tg://msg?text=${message}`;
        }
    };

    function renderNextPaymentDetails(d) {
        return (
            <Row>
                <Col className='col-lg-6'>{d.Key}</Col>
                <Col className='col-lg-6'>{d.Value}</Col>
            </Row>
        )
    }

    function CancelSubscriptionModal() {
        return (
            <Modal show={showCancelSubscriptionModal} onHide={() => setShowCancelSubscriptionModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('referRewards.Cancel Subscription')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('referRewards.Are you sure you want to cancel subscription?')} </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={proceedToCancel}>
                        {t('referRewards.Proceed to cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {

        let queryParam = queryString.parse(search);
        let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue')
        i18n.changeLanguage(_lang);

        const handleResize = () => {
            setIsWebsite(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        getUserPlanDetails()

    }, [])



    return (
        <div className='greybk-div p-lg-5 ' >
            <Container className='box-container p-4 mt-5 '>

                <Row>
                    <Col className='col-12 col-lg-3'>
                        <Image className='mb-3' src={require("../../img/icons/refer & rewards.png")} width={35} height={35} style={{ marginRight: '5%', marginTop: '5%' }} />
                        <span style={{ fontSize: isWebsite ? '15px' : '17px' }}>{t('referRewards.Refer & Rewards')}</span>
                    </Col>

                    <Col className='col-12 col-lg-7'>
                        {/* <div>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.Title : null}</div> */}
                        <div style={{ color: '#5B5D6B' }}>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.Desc : null}</div>
                        {/* <div style={{ color: '#5B5D6B' }}>Share this referral code with your friends to invite them to join Adviser2u, and you can take part in our giveaway and rewards campaign.</div> */}

                        <div className="input-group mt-3 mb-3">
                            <input
                                type="text"
                                className="form-control-custom"
                                style={{ width: '50%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', borderWidth: '1px', paddingLeft: '10px' }}
                                defaultValue={subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.Code : null}
                                readOnly
                                ref={inputRef}
                            />
                            <button className="btn btn-primary" onClick={copyText}>
                                {t('referRewards.Copy')}
                            </button>
                        </div>


                        {isWebsite && (
                            <div style={{ background: '#F4F4F4', height: '180px', marginTop: '35px', display: 'flex' }}>

                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Image className='mb-3' src={require("../../img/icons/sign up.png")} width={35} height={35} style={{ marginRight: '5%', marginTop: '5%' }} />
                                    <div>{t('referRewards.Total friend joined')}</div>
                                    <div>{t('referRewards.Adviser2u')}</div>
                                    <strong>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.TotalJoinAdviser : null}</strong>
                                </div>
                                <div style={{ borderRight: '1px solid #C4C2C2', margin: '20px 0' }}></div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Image className='mb-3' src={require("../../img/icons/become adviser.png")} width={35} height={35} style={{ marginRight: '5%', marginTop: '5%' }} />
                                    <div>{t('referRewards.Total friend become')}</div>
                                    <div>{t('referRewards.adviser')}</div>
                                    <strong>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.TotalBecomeAdviser : null}</strong>
                                </div>


                                <div style={{ borderRight: '1px solid #C4C2C2', margin: '20px 0' }}></div>


                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Image className='mb-3' src={require("../../img/icons/rewards.png")} width={35} height={35} style={{ marginRight: '5%', marginTop: '5%' }} />
                                    <div style={{ fontSize: SIZES.font }}>{t('referRewards.(Applicable to Adviser Only)')}</div>
                                    <div>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.DisplayDiscountDesc : null} </div>
                                    <strong>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.TotalBalancePromo : null}</strong>

                                </div>
                            </div>
                        )}
                        {!isWebsite && (
                            <div style={{ background: '#F4F4F4', marginTop: '35px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
                                    <div style={{ borderRadius: '50%', background: 'white', width: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                                        <Image src={require("../../img/icons/sign up.png")} width={35} height={35} />
                                    </div>
                                    <div>{t('referRewards.Total friend joined Adviser2u')}</div>
                                    <strong>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.TotalJoinAdviser : null}</strong>
                                </div>
                                <div style={{ borderBottom: '1px solid #C4C2C2', width: '80%', marginBottom: '20px' }}></div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                    <div style={{ borderRadius: '50%', background: 'white', width: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                                        <Image src={require("../../img/icons/become adviser.png")} width={35} height={35} />
                                    </div>
                                    <div>{t('referRewards.Total friend become adviser')}</div>
                                    <strong>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.TotalBecomeAdviser : null}</strong>
                                </div>
                                <div style={{ borderBottom: '1px solid #C4C2C2', width: '80%', marginBottom: '20px' }}></div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                    <div style={{ borderRadius: '50%', background: 'white', width: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                                        <Image src={require("../../img/icons/rewards.png")} width={35} height={35} />
                                    </div>
                                    <div style={{ fontSize: SIZES.font }}>{t('referRewards.(Applicable to Adviser Only)')}</div>
                                    <div>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.DisplayDiscountDesc : null} </div>
                                    <strong>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.TotalBalancePromo : null}</strong>
                                </div>
                            </div>

                        )}
                        <div style={{ color: '#5B5D6B', marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '13px' }}>{t('referRewards.(Applicable to Adviser Only)')}</div>
                        <div style={{ color: '#5B5D6B', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '13px' }}>{subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.TotalDiscountDesc : null}</div>
                    </Col>
                    <Col className='col-lg-2 d-flex flex-column align-items-start'>
                        <button
                            className="btn-white fw-bold btn"
                            style={{ borderColor: COLORS.gray, marginLeft: "25%", marginTop: "5%" }}
                            onClick={handleOpenModal}
                        >{t('referRewards.Invite Friend')}</button>

                    </Col>

                    <Modal show={showModal} onHide={handleCloseModal} style={{ marginTop: "10%" }}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('referRewards.Invite Friends!')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="text-center" style={{ marginBottom: "10%" }}>
                                <img
                                    // {subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.ShareLinkContent : null}
                                    src={subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeImage : null}
                                    alt="QR Code"
                                    style={{ maxWidth: '100%', height: '100%' }}
                                />
                            </div>
                            <div className="text-center mt-3" style={{ marginTop: "10%", marginBottom: "10%" }}>
                                {/* Share buttons */}
                                <div className="row share-icons">
                                    <div className="col-2 share-icon">
                                        <a href="#" onClick={download}>
                                            <Image className='mb-3' src={require("../../img/icons/dwld.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                                            {isWebsite && <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.Download')}</div>}
                                        </a>
                                    </div>
                                    <div className="col-2 share-icon">
                                        <a href="#" onClick={openWhatsApp}>
                                            <Image className='mb-3' src={require("../../img/icons/wa.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                                            {isWebsite && <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.WhatsApp')}</div>}
                                        </a>
                                    </div>
                                    <div className="col-2 share-icon">
                                        <a href="#" onClick={openFacebook}>
                                            <Image className='mb-3' src={require("../../img/icons/facebook.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                                            {isWebsite && <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.Facebook')}</div>}
                                        </a>
                                    </div>
                                    <div className="col-2 share-icon">
                                        <a href="#" onClick={openTwitter}>
                                            <Image className='mb-3' src={require("../../img/icons/twitter_icon.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                                            {isWebsite && <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('X')}</div>}
                                        </a>
                                    </div>
                                    <div className="col-2 share-icon">
                                        <a href="#" onClick={openLinkedIn}>
                                            <Image className='mb-3' src={require("../../img/icons/in.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                                            {isWebsite && <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.LinkedIn')}</div>}
                                        </a>
                                    </div>
                                    <div className="col-2 share-icon">
                                        <a href="#" onClick={openTelegram}>
                                            <Image className='mb-3' src={require("../../img/icons/telegram.png")} width={55} height={55} style={{ marginRight: '5%', marginTop: '5%' }} />
                                            {isWebsite && <div className="share-label" style={{ fontSize: "12px", alignItems: "center" }}>{t('referRewards.Telegram')}</div>}
                                        </a>
                                    </div>
                                    {/* Add more share icons with labels as needed */}
                                </div>
                            </div>

                            <div className="input-group mt-3 mb-3" style={{ justifyContent: 'center' }}>
                                <input
                                    type="text"
                                    className="form-control-custom"
                                    style={{ width: '70%', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', borderWidth: '1px', paddingLeft: '10px' }}
                                    defaultValue={subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.Code : null}
                                    readOnly
                                    ref={inputRef}
                                />
                                <button className="btn btn-primary" onClick={copyText}>
                                    {t('referRewards.Copy')}
                                </button>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>

                        </Modal.Footer> */}
                    </Modal>
                </Row>


            </Container>

        </div>

    );
};

export default App;
