import React from 'react'
import { Container, Row, Col, Image, Button, Card, Modal, Dropdown } from 'react-bootstrap';
import domainName from '../../domainName';
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme';
import { useEffect, useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { SHA256 } from "crypto-js";
import { setUserId } from 'firebase/analytics';
import { useSelector } from 'react-redux';
// import navigation component
import Navigation from '../../components/Navigation';
import { useNavigate, useLocation } from "react-router-dom";
import queryString from 'query-string';
import { useTranslation } from "react-i18next";

const CommunityPostDetails = () => {

    const navigate = useNavigate();
    const userAgent = navigator.userAgent;
    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    const [userIdForCommunity, setUserIdForCommunity] = useState()
    const [userIdValid, setUserIdValid] = useState(false)

    const [view, setView] = useState('');

    const [isLikeHovered, setIsLikeHovered] = useState(false);
    const [isCommentHovered, setIsCommentHovered] = useState(false);

    const [isIOS, setIsIOS] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false);

    const [isLike, setIsLike] = useState()

    const [listingImageGlobal, setListingImageGlobal] = useState([])

    const [imageDisplay, setImageDisplay] = useState([]);
    const [userimage, setUserImageDisplay] = useState();
    const [username, setUserName] = useState();
    const [time, setPosttimeCreate] = useState();
    const [title, setPostTitle] = useState();
    const [comment, setComment] = useState([]);
    const [postId, setPostID] = useState();
    const [posterUserId, setPosterUserId] = useState();

    const [totalLikes, setTotalLikes] = useState();
    const [totalComments, setTotalComments] = useState();


    const [commentLabelVisibility, setCommentLabelVisibility] = useState(false);

    const location = useLocation();

    // for edit post modal
    const [showEditPostModal, setShowEditPostModal] = useState();

    //for delete post modal
    const [showDeletePostModal, setShowDeletePostModal] = useState(false)

    const [loading, setLoading] = useState(false)

    const [t, i18n] = useTranslation("global");


    async function convertImageTo64ForEdit(postid, imagePassed, messagePAss) {

        let result64Image;
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64Data = reader.result;
            //// console.log("base64Data", base64Data);

            editCommunityNews(postid, [base64Data], messagePAss)
        };

        reader.onerror = (error) => {
            alert(`${t("error.Error reading image file :")} ${error}`);
        };

        reader.readAsDataURL(imagePassed);


        return result64Image
    }


    async function editCommunityNews(postsid, imagePassedIn, messageText) {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/community/editCommunityNews",
                "POST",
                JSON.stringify({
                    "PostsId": postsid,
                    "Type": "POST",
                    "Voting": [
                        {
                            "title": "option 1",
                            "userIdList": []
                        },
                        {
                            "title": "option 2",
                            "userIdList": []
                        },
                        {
                            "title": "option 3",
                            "userIdList": []
                        }
                    ],
                    "Title": messageText,
                    "Photos": imagePassedIn
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log(`/community/editCommunityNews responseJson here`, responseJson)
                if (responseJson.ReturnCode == 200) {
                    setLoading(false)
                    setShowEditPostModal(false)
                    // window.location.reload();
                    getCommentByPostId(postId);
                } else {
                    setLoading(false)
                    alert(t(`error.Cannot edit Feed post. Please try it again later.`))
                }
            }))
            .catch((error) => {
                //console.log(`/community/editCommunityNews error here`, error)
            });

    }


    async function submitComment(commentText) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/community/userCreateComment",
                "POST",
                JSON.stringify({
                    "PostId": postId,
                    "UserId": userIdForCommunity,
                    "PostUserId": posterUserId,
                    "CommentText": commentText
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log("/community/userCreateComment responseJson: ", responseJson)

                if (responseJson.ReturnCode == 200) {
                    getCommentByPostId(postId);
                } else {
                    alert(t('error.Comment failed, please try it again later.'))
                }
            }))
            .catch((error) => {
                //console.log("/community/userCreateComment error: ", error)
            });
    }



    async function handleLikeAction() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/community/userLikePost",
                "POST",
                JSON.stringify({
                    "PostId": postId,
                    "UserId": userIdForCommunity,
                    "PostUserId": posterUserId
                    // "PostUserId": "645d940368bcf0c3128cdf0f"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log("/community/userLikePost responseJson: ", responseJson)

                if (responseJson.ReturnCode == 200) {
                    getCommentByPostId(postId);
                } else {
                    alert(t('error.Like failed, please try it again later.'))
                }
            }))
            .catch((error) => {
                //console.log("/community/userLikePost error: ", error)
            });
    }



    async function checkAnonymousUserid(checkUserID) {
        //call api to check the user is still anonymous
        let returnBoolean;
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkIsAnonymouseUser",
                "POST",
                JSON.stringify({
                    "UserId": checkUserID
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                if (responseJson.ReturnCode == 200) {
                    returnBoolean = true
                } else {
                    returnBoolean = false
                }
            }))
            .catch((error) => {
                //console.log('/user/checkIsAnonymouseUser error here', error)
            });

        return returnBoolean
    }


    async function getCommentByPostId(postid, userID) {

        // console.log("postid in getcommentbypostid : ",  JSON.stringify({
        //     "PostId": postid,
        //     "UserId": userIdForCommunity,
        // }))

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/community/listAllCommentByPostId",
                "POST",
                JSON.stringify({
                    "PostId": postid,
                    "UserId": userID ? userID : userIdForCommunity,
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log("/community/listAllCommentByPostId responseJson: ", responseJson)

                // console.log(responseJson.PostData.UserImageDisplay)
                // console.log(responseJson.PostData.ListingImageDisplay)
                // console.log(responseJson.PostData.UserName)
                // console.log(responseJson.PostData.title)
                // console.log(responseJson.PostData.CreatedAtDisplay)

                // // console.log(responseJson.PostData.comments)
                setIsLike(responseJson.PostData.IsLike)

                setTotalLikes(responseJson.PostData.TotalLikes)
                setTotalComments(responseJson.PostData.Totalcomments)

                setListingImageGlobal(responseJson.PostData.listingImage)

                setImageDisplay(responseJson.PostData.ListingImageDisplay)
                setUserImageDisplay(responseJson.PostData.UserImageDisplay)
                setUserName(responseJson.PostData.UserName)
                setPostTitle(responseJson.PostData.title)
                setPosttimeCreate(responseJson.PostData.CreatedAtDisplay)

                setPostID(responseJson.PostData._id)
                setPosterUserId(responseJson.PostData.author)


                setComment(responseJson.Data)

            }))
            .catch((error) => {
                // console.log("/community/listAllCommentByPostId error: ", error)
            });

    }


    function EditPostModal() {

        const [messageValue, setMessageValue] = useState(title)
        const [selectedPicture, setSelectedPicture] = useState(null);
        const [previewImage, setPreviewImage] = useState(imageDisplay[0]);
        const [listingImage, setListingImage] = useState(listingImageGlobal)


        const handlePictureChange = (e) => {
            const file = e.target.files[0];
            if (file && file.type.startsWith('image/')) {
                setSelectedPicture(file);
                setPreviewImage(URL.createObjectURL(file));
            } else {
                setSelectedPicture(null);
                setPreviewImage(null);
            }
        };

        return (
            <Modal show={showEditPostModal} onHide={() => {
                setShowEditPostModal(false)
                setSelectedPicture(null);
                setPreviewImage(null);
                setMessageValue('');
            }} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('community.Edit Feed Post')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        id="messageInput"
                        name="messageInput"
                        style={{
                            height: '120px',
                            borderColor: COLORS.lightGray1,
                            borderWidth: 1,
                            borderRadius: 5,
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '5px',
                            fontSize: '16px',
                            // marginTop: '5%',
                            resize: 'none', // Disable textarea resizing
                        }}
                        placeholder={t("placeholder.What's on your mind...")}
                        onChange={(e) => { setMessageValue(e.target.value) }}
                        value={messageValue}
                    ></textarea>
                    <div >
                        <label htmlFor="imageUpload" className="custom-file-upload" style={{ cursor: 'pointer' }}>
                            <span style={{ color: COLORS.orange }}>{t('community.Choose an image')}</span>
                        </label>
                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={handlePictureChange}
                            className="mt-3"
                            style={{ display: 'none' }}
                        />
                    </div>
                    {previewImage && (
                        <div style={{ position: 'relative', marginTop: '3%', width: '150px' }}>
                            <img src={previewImage} width={150} height={150} style={{ borderRadius: '10px' }} />
                            <div
                                onClick={() => {
                                    setSelectedPicture(null);
                                    setPreviewImage(null);
                                }}
                                style={{ position: 'absolute', top: '2px', right: '2px', zIndex: '1' }}
                            >
                                <img
                                    src={require('../../img/icons/close.png')}
                                    style={{ width: '20px', height: '20px', backgroundColor: 'white', borderRadius: '10px', border: '2px solid white', cursor: 'pointer' }}
                                    alt="Close"
                                />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowEditPostModal(false)
                        setSelectedPicture(null);
                        setPreviewImage(null);
                        setMessageValue('');

                    }}>
                        {t('community.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        setLoading(true)
                        const messageInput = document.querySelector('textarea[name="messageInput"]').value;
                        // // console.log("listingImage", listingImage)
                        if (selectedPicture) {
                            await convertImageTo64ForEdit(postId, selectedPicture, messageInput)
                        } else {
                            await editCommunityNews(postId, previewImage ? listingImage : null, messageInput)
                        }

                    }} disabled={loading}>
                        {loading ? t('community.Loading...') : t('community.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function DeletePostModal() {
        return (
            <Modal show={showDeletePostModal} onHide={() => setShowDeletePostModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('community.Delete Post')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('community.Are you sure you want to delete this post?')}</Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeletePostModal(false)}>
                    {t('community.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        await domainName
                            .serverAPICall(
                                domainName.debugAPI + "/community/adminUpdateCommunityEnabled",
                                "POST",
                                JSON.stringify({
                                    "PostsId": postId,
                                    "Enabled": "false"
                                })
                            )
                            .then(domainName.handleServerAPICallErrors)
                            .then((responseJson => {
                                if (responseJson.ReturnCode != 200) {
                                    alert(t("error.Failed to delete this post, please try it again later."))
                                }

                                setShowDeletePostModal(false)
                                navigate('/community')
                            }))
                            .catch((error) => {
                                //// console.log('/community/listAllCommunityNewsForCMS error here', error)
                            });
                    }}>
                        {t('community.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }




    useEffect(() => {
        const fetchData = async () => {
            setIsIOS(!!userAgent.match(/iPad|iPhone|iPod/)); // Match iOS devices
            setIsAndroid(!!userAgent.match(/Android/)); // Match Android devices

            const searchParams = new URLSearchParams(window.location.search);

            // Get the value of the 'serviceid' parameter
            const communityPostId = searchParams.get('communityPostId');
            const encryptedID = searchParams.get('uid');
            const userid = searchParams.get('userid');
            const timeid = searchParams.get('tid');

            const key = process.env.REACT_APP_COMMUNITY_APP_KEY;
            const ciphertext = SHA256(userid + key + "Adviser2u" + timeid).toString();

            console.log("ciphertext", ciphertext)
            console.log("encryptedID.toString()", encryptedID.toString())

            if (ciphertext == encryptedID.toString()) {
                // here to proceed with
                setUserIdForCommunity(userid)

                const trueFalse = await checkAnonymousUserid(userid)

                if (trueFalse == true) {

                    // console.log(` setUserIdValid(true)`)
                    setUserIdValid(true)
                    await getCommentByPostId(communityPostId, userid);
                } else {
                    // console.log(` setUserIdValid(false)`)
                    setUserIdValid(false)
                }
            }

            let _lang = searchParams.get('lang') ? searchParams.get('lang') : localStorage.getItem('selectedValue')
            i18n.changeLanguage(_lang);

        }


        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setView('mobile');
            } else if (width >= 768 && width < 1500) {
                setView('laptop');
            } else if (width >= 1500 && width <= 3000) {
                setView('monitor');
            } else {
                setView('default');
            }
        };


        fetchData();

        // For the website to be more responsive
        handleResize();

        // For resize
        window.addEventListener('resize', handleResize);

        // For cleaning up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function renderPostDescription(title) {
        if (title != null) {
            const descriptionLines = decodeURIComponent(encodeURIComponent(title)).split('\n');

            // Function to convert URLs in text to clickable links
            const convertUrlsToLinks = (text) => {
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                return text.replace(urlRegex, (url) => {
                    return `<a href="${url}" target="_blank">${url}</a>`;
                });
            };

            return (
                <div>
                    {
                        descriptionLines.map((line, index) => (
                            // Render HTML using dangerouslySetInnerHTML to allow HTML tags
                            <p key={index} dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(line) }} />
                        ))
                    }
                </div>
            );
        }
    }

    return (

        <div>
            {!isIOS && !isAndroid && <Navigation />}
            <div style={{ backgroundColor: "#F8F8F8", paddingTop: '2%', minHeight: '100vh', height: 'auto' }}>

                <Container style={{ padding: '10px', borderRadius: '8px' }}>
                    <Row style={{ marginLeft: '10%' }}>

                        <div className="container">
                            <div className="row align-items-center mb-4">
                                {view === 'laptop' || view === 'monitor' ? (
                                    <>
                                        <div className="col-auto">
                                            <img
                                                src={userimage}
                                                alt="User Profile"
                                                style={{ width: '120px', height: '120px', borderRadius: '50%' }}
                                            />
                                        </div>
                                        <div className="col">
                                            <h2 className="m-0">{username}</h2>
                                            <span style={{ color: COLORS.gray }}>{time}</span>
                                        </div>


                                        <Row style={{ marginTop: '2%' }}>
                                            <div className="col">
                                                <h3 className="m-0">{renderPostDescription(title)}</h3>
                                                {/* <p className="m-0 text-muted" style={{ fontSize: '14px' }}>{time}</p> */}
                                            </div>
                                        </Row>
                                    </>
                                ) : null}

                            </div>
                        </div>
                    </Row>
                    {view == 'mobile' && (isAndroid || isIOS) &&
                        <>
                            <Row style={{ padding: '4%' }}>
                                <Col xs={20} lg={9} className="d-flex flex-column"
                                //style={{ backgroundColor: COLORS.blue }}
                                >
                                    <Row style={{ alignItems: 'center' }}>
                                        <img
                                            src={userimage}
                                            alt="Image"
                                            className="img-fluid"
                                            style={{ width: '100%', height: 'auto', maxWidth: '90px', maxHeight: '70px', borderRadius: '50%' }}
                                        />
                                        <div style={{ marginLeft: '10px', flex: '1' }}>
                                            <Row>
                                                <span style={{ fontWeight: 'bold' }}>{username}</span>
                                            </Row>
                                            <Row style={{ justifyContent: 'flex-end' }}>
                                                <span style={{ color: COLORS.gray }}>{time}</span>
                                            </Row>
                                        </div>
                                    </Row>
                                    {/* {
                                        userIdForCommunity == posterUserId &&
                                        <Row>
                                            <div className="dropdown">
                                                <div
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                    className="col"
                                                    style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', position: 'relative' }}
                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <img
                                                            src={require('../../img/icons/more.png')}
                                                            style={{ width: '20px', height: '20px', marginRight: '5%' }}
                                                            alt="More icon"
                                                            fluid
                                                        />
                                                    </div>
                                                </div>

                                                <ul className="dropdown-menu" style={{ top: '100%', left: 0, zIndex: 1000 }}>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item`}
                                                            href="#"
                                                            onClick={() => {
                                                                if (userIdValid) {
                                                                    // setEditItem(e);
                                                                    setShowEditPostModal(true);
                                                                } else {
                                                                    alert('Kindly sign in first.');
                                                                }
                                                            }}
                                                        >
                                                            Edit
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item`}
                                                            href="#"
                                                            onClick={() => {
                                                                if (userIdValid) {
                                                                    setShowDeletePostModal(true);
                                                                } else {
                                                                    alert('Kindly sign in first.');
                                                                }
                                                            }}
                                                        >
                                                            Delete
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Row>
                                    } */}

                                    <Row style={{ marginTop: '2%' }}>
                                        <div className="col">
                                            <h3 className="m-0">{title}</h3>
                                            {/* <p className="m-0 text-muted" style={{ fontSize: '14px' }}>{time}</p> */}
                                        </div>
                                    </Row>

                                </Col>
                            </Row>
                        </>
                    }
                    {(view == "laptop" || view == "monitor") && imageDisplay.length > 0 &&
                        <Container style={{ maxWidth: '80%', maxHeight: '50%', borderRadius: '8px', objectFit: 'cover', border: '1px solid #ccc', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                            <Col>
                                <Row style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', objectFit: 'cover' }}>

                                    <Image src={imageDisplay[0]} alt="Post Image" className="post-image" style={{ marginBottom: '2%', marginTop: '2%', width: '50%', height: '50%', objectFit: 'cover', }} />

                                </Row>
                            </Col>
                        </Container>
                    }
                    {view == "mobile" && imageDisplay.length > 0 &&
                        <Container style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '8px', objectFit: 'cover', border: '1px solid #ccc', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                            <Col>
                                <Row style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', objectFit: 'cover' }}>

                                    <Image src={imageDisplay[0]} alt="Post Image" className="post-image" style={{ marginBottom: '2%', marginTop: '2%', width: '100%', height: '100%', objectFit: 'cover', }} />

                                </Row>
                            </Col>
                        </Container>
                    }
                    <Row style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '2%' }}>
                        <Col
                            xs={3}
                            lg={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'left',
                                alignItems: 'left',
                                cursor: userIdValid ? 'pointer' : 'not-allowed',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: isLikeHovered ? 'transparent' : 'transparent',
                            }}
                            onClick={() => {
                                if (userIdValid) {
                                    handleLikeAction();
                                } else {
                                    alert(t('error.Kindly sign in first.'));
                                }

                            }}

                        >
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div >
                                    <Image src={require(isLike ? '../../img/icons/liked.png' : '../../img/icons/like.png')} fluid
                                        style={{
                                            width: '20px',
                                            height: '20px'
                                        }}
                                        onMouseEnter={() => setIsLikeHovered(true)}
                                        onMouseLeave={() => setIsLikeHovered(false)}
                                    />
                                </div>
                                <div style={{
                                    marginLeft: '3%', marginTop: '2%'
                                }}>
                                    <span style={{
                                        color: COLORS.gray
                                    }}>
                                        {totalLikes > 0 && totalLikes}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={3}
                            lg={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: userIdValid ? 'pointer' : 'not-allowed',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: isCommentHovered ? '#1A73E8' : 'transparent',
                            }}
                            onClick={() => {
                                if (userIdValid) {
                                    // // console.log('clicked comment');
                                    // toggleCommentLabel(index);
                                    // getCommentByPostId(e._id, index);
                                    setCommentLabelVisibility(!commentLabelVisibility)
                                } else {
                                    alert(t('error.Kindly sign in first.'));
                                }
                            }}

                            onMouseEnter={() => { setIsCommentHovered() }}
                            onMouseLeave={() => { setIsCommentHovered() }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div>
                                    <Image
                                        src={require('../../img/icons/comments.png')}
                                        fluid
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                        }}
                                    />
                                </div>
                                <div style={{ marginLeft: '3%', marginTop: '2%' }}>
                                    <span style={{ color: COLORS.gray }}>{totalComments > 0 && totalComments}</span>
                                </div>
                            </div>
                        </Col>
                        {/* <Col
                            xs={3}
                            lg={2}
                            style={{
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'right',
                                // cursor: userIdValid ? 'pointer' : 'not-allowed',
                                borderRadius: '5px',
                                padding: '5px',
                                backgroundColor: isLikeHovered ? 'transparent' : 'transparent',
                            }}
                        // onClick={() => {
                        //     if (userIdValid) {
                        //         handleLikeAction(e);
                        //     } else {
                        //         alert('Kindly sign in first.');
                        //     }

                        // }}

                        >
                            <div></div>
                        </Col> */}
                        {
                            userIdForCommunity == posterUserId &&

                            <Col xs={3} lg={2}>
                                {/* Option */}
                                <div className="dropdown">
                                    {/* Category dropdown */}
                                    <div
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        className="col"
                                        style={{ display: 'flex', justifyContent: 'right', alignItems: 'right', position: 'relative' }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img
                                                src={require('../../img/icons/more.png')}
                                                style={{ width: '20px', height: '20px', marginRight: '5%' }}
                                                alt="More icon"
                                                fluid
                                            />
                                        </div>
                                    </div>

                                    <ul className="dropdown-menu" style={{ top: '100%', left: 0, zIndex: 1000 }}>
                                        <li>
                                            <a
                                                className={`dropdown-item`}
                                                href="#"
                                                onClick={() => {
                                                    if (userIdValid) {
                                                        // setEditItem(e);
                                                        setShowEditPostModal(true);
                                                    } else {
                                                         alert(t('error.Kindly sign in first.'));
                                                    }
                                                }}
                                            >
                                                {t('community.Edit')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className={`dropdown-item`}
                                                href="#"
                                                onClick={() => {
                                                    if (userIdValid) {
                                                        setShowDeletePostModal(true);
                                                    } else {
                                                         alert(t('error.Kindly sign in first.'));
                                                    }
                                                }}
                                            >
                                                 {t('community.Delete')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                        }

                    </Row>

                    {/* Add comment label */}
                    {commentLabelVisibility && (
                        <Row style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '2%' }}>
                            <Col xs={3} lg={1} >
                                {/* <img
                                    // src={e.ReviewerImageDisplay}
                                    src={userimage}
                                    alt="Image"
                                    className="img-fluid"
                                    style={{ width: '100%', height: 'auto', maxWidth: '90px', maxHeight: '70px', borderRadius: '50%' }}
                                /> */}
                                <div></div>
                            </Col>
                            <Col xs={9} lg={9} >
                                <textarea
                                    id="commentInput"
                                    name="commentInput"
                                    style={{
                                        height: '120px',
                                        borderColor: COLORS.lightGray1,
                                        borderWidth: 1,
                                        borderRadius: 5,
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        padding: '5px',
                                        fontSize: '16px',
                                        backgroundColor: 'transparent',
                                        // marginTop: '5%',
                                        height: '70px',
                                        resize: 'none', // Disable textarea resizing
                                    }}
                                    placeholder={t("placeholder.Leave your comment here...")}
                                    onChange={(onC) => {
                                        // setCommentValue(onC.target.value)
                                    }}
                                    onKeyDown={(onK) => {
                                        if (onK.key === 'Enter') {
                                            const commentValue = document.querySelector('textarea[name="commentInput"]').value;
                                            submitComment(commentValue)
                                            // setCommentValue('')
                                            document.querySelector('textarea[name="commentInput"]').value = ""

                                            setCommentLabelVisibility(false)

                                        }
                                    }}
                                // value={commentValue}
                                ></textarea>
                            </Col>

                        </Row>
                    )}

                    {/* Display comment label */}
                    {
                        commentLabelVisibility && comment.map((comments, commentIndex) => (
                            <Row style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '2%' }} key={commentIndex}>
                                <Col xs={3} lg={1} >
                                    <img
                                        // src={e.ReviewerImageDisplay}
                                        src={comments.author.ImageDisplay}
                                        alt="Image"
                                        className="img-fluid"
                                        style={{ width: '100%', height: 'auto', maxWidth: '90px', maxHeight: '80px', borderRadius: '50%' }}
                                    />
                                </Col>
                                <Col xs={9} lg={9}>
                                    <Container>
                                        <div
                                            style={{
                                                borderColor: COLORS.lightGray1,
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                boxSizing: 'border-box',
                                                padding: '5px',
                                                fontSize: '16px',
                                                backgroundColor: 'transparent',
                                                overflow: 'auto',
                                            }}
                                        >

                                            <div style={{ display: isWebsite ? 'flex' : '', flexDirection: isWebsite ? 'row' : '', justifyContent: isWebsite ? 'space-between' : '' }}>

                                                <div style={{ fontWeight: 'bold' }}>{comments.author.Name}</div>
                                                {!isWebsite && (<br></br>)}
                                                <div>{comments.DisplayCreatedAt}</div>
                                            </div>
                                            <textarea
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    resize: 'none',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    fontSize: '16px',
                                                    color: 'black',
                                                }}
                                                value={comments.comment}
                                                readOnly
                                            />
                                        </div>
                                    </Container>
                                </Col>
                            </Row>
                        ))

                    }

                    <DeletePostModal />
                    <EditPostModal />

                </Container>




            </div>
        </div>
    )
}

export default CommunityPostDetails