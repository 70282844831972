import React from 'react'
import { Container, Row, Col, Button, NavDropdown, Modal, Image, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import domainName from '../../domainName';
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SHA256 } from "crypto-js";
import { useTranslation } from "react-i18next";


const AccountAndSecurity = () => {

    const navigate = useNavigate();
    const smsKey = process.env.REACT_APP_SMS_SECRET_KEY;
    const apiKey = process.env.REACT_APP_API_KEY;

    // const { advisor } = useSelector((state) => state.switchRole);

    // Retrieving 'advisor' state from local storage
    const advisorState = JSON.parse(localStorage.getItem('advisorState'));

    const { token, UserData, UserId } = useSelector((state) => state.login);

    // for change phoneNumber modal
    const [frontCountryPhoneNumberModal, setFrontCountryPhoneNumberModal] = useState('+60');
    const [phoneNumberInputDisabled, setPhoneNumberInputDisabled] = useState(false)
    const [showEditPhoneNumber, setShowEditPhoneNumber] = useState(false)
    const [resendMessagePhoneNumber, setResendMessagePhoneNumber] = useState(false)
    const [showOtpInputPhoneNumber, setShowOtpInputPhoneNumber] = useState(false)
    const [isRequestingOTPForPhoneNumber, setIsRequestingOTPForPhoneNumber] = useState(false);
    const cooldownDurationForPhoneNumber = 30; // cooldown period in seconds
    const [phoneNumberInput, setPhoneNumberInput] = useState('')
    const [confirmButtonValidphoneNumber, setConfirmButtonValidphoneNumber] = useState(true)

    // for otp count down
    const [isRequestingOTP, setIsRequestingOTP] = useState(false);
    const cooldownDuration = 30; // cooldown period in seconds

    //from api data
    const [displayImage, setDisplayImage] = useState();
    const [displayUserName, setDisplayUserName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [email, setEmail] = useState()

    const [whatsAppNumber, setWhatsappNumber] = useState();
    const [canChangePassword, setCanChangePassword] = useState(false);

    // for modal update phone number
    const [showUpdatePhoneNumberModal, setShowUpdatePhoneNumberModal] = useState(false)

    // for modal update email
    const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false)

    // for modal update whatsapp
    const [showUpdateWhatsappModal, setShowUpdateWhatsappModal] = useState(false)
    const [frontCountryPhoneNumber, setFrontCountryPhoneNumber] = useState('+60');
    const [numberInput, setNumberInput] = useState('')
    const [phoneInputDisabled, setPhoneInputDisabled] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showOtpInput, setShowOtpInput] = useState(false)
    const [resendMessage, setResendMessage] = useState(false)
    const [confirmButtonValid, setConfirmButtonValid] = useState(true)

    // for modal change password
    const [changePasswordModalShow, setChangePasswordModalShow] = useState(false)

    // for modal change name
    const [changeNameModalShow, setChangeNameModalShow] = useState(false)


    // for modal change profile pic
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const [t] = useTranslation("global");


    // for country code selector 
    const countryData = [
        { code: '+93', name: 'Afghanistan' },
        { code: '+355', name: 'Albania' },
        { code: '+213', name: 'Algeria' },
        { code: '+1-684', name: 'American Samoa' },
        { code: '+376', name: 'Andorra' },
        { code: '+244', name: 'Angola' },
        { code: '+1-264', name: 'Anguilla' },
        { code: '+672', name: 'Antarctica' },
        { code: '+1-268', name: 'Antigua and Barbuda' },
        { code: '+54', name: 'Argentina' },
        { code: '+374', name: 'Armenia' },
        { code: '+297', name: 'Aruba' },
        { code: '+61', name: 'Australia' },
        { code: '+43', name: 'Austria' },
        { code: '+994', name: 'Azerbaijan' },
        { code: '+1-242', name: 'Bahamas' },
        { code: '+973', name: 'Bahrain' },
        { code: '+880', name: 'Bangladesh' },
        { code: '+1-246', name: 'Barbados' },
        { code: '+375', name: 'Belarus' },
        { code: '+32', name: 'Belgium' },
        { code: '+501', name: 'Belize' },
        { code: '+229', name: 'Benin' },
        { code: '+1-441', name: 'Bermuda' },
        { code: '+975', name: 'Bhutan' },
        { code: '+591', name: 'Bolivia' },
        { code: '+387', name: 'Bosnia and Herzegovina' },
        { code: '+267', name: 'Botswana' },
        { code: '+55', name: 'Brazil' },
        { code: '+246', name: 'British Indian Ocean Territory' },
        { code: '+1-284', name: 'British Virgin Islands' },
        { code: '+673', name: 'Brunei' },
        { code: '+359', name: 'Bulgaria' },
        { code: '+226', name: 'Burkina Faso' },
        { code: '+257', name: 'Burundi' },
        { code: '+855', name: 'Cambodia' },
        { code: '+237', name: 'Cameroon' },
        { code: '+1', name: 'Canada' },
        { code: '+238', name: 'Cape Verde' },
        { code: '+1-345', name: 'Cayman Islands' },
        { code: '+236', name: 'Central African Republic' },
        { code: '+235', name: 'Chad' },
        { code: '+56', name: 'Chile' },
        { code: '+86', name: 'China' },
        { code: '+61', name: 'Christmas Island' },
        { code: '+61', name: 'Cocos Islands' },
        { code: '+57', name: 'Colombia' },
        { code: '+269', name: 'Comoros' },
        { code: '+682', name: 'Cook Islands' },
        { code: '+506', name: 'Costa Rica' },
        { code: '+385', name: 'Croatia' },
        { code: '+53', name: 'Cuba' },
        { code: '+599', name: 'Curacao' },
        { code: '+357', name: 'Cyprus' },
        { code: '+420', name: 'Czech Republic' },
        { code: '+243', name: 'Democratic Republic of the Congo' },
        { code: '+45', name: 'Denmark' },
        { code: '+253', name: 'Djibouti' },
        { code: '+1-767', name: 'Dominica' },
        { code: '+1-809, +1-829, +1-849', name: 'Dominican Republic' },
        { code: '+670', name: 'East Timor' },
        { code: '+593', name: 'Ecuador' },
        { code: '+20', name: 'Egypt' },
        { code: '+503', name: 'El Salvador' },
        { code: '+240', name: 'Equatorial Guinea' },
        { code: '+291', name: 'Eritrea' },
        { code: '+372', name: 'Estonia' },
        { code: '+251', name: 'Ethiopia' },
        { code: '+500', name: 'Falkland Islands' },
        { code: '+298', name: 'Faroe Islands' },
        { code: '+679', name: 'Fiji' },
        { code: '+358', name: 'Finland' },
        { code: '+33', name: 'France' },
        { code: '+689', name: 'French Polynesia' },
        { code: '+241', name: 'Gabon' },
        { code: '+220', name: 'Gambia' },
        { code: '+995', name: 'Georgia' },
        { code: '+49', name: 'Germany' },
        { code: '+233', name: 'Ghana' },
        { code: '+350', name: 'Gibraltar' },
        { code: '+30', name: 'Greece' },
        { code: '+299', name: 'Greenland' },
        { code: '+1-473', name: 'Grenada' },
        { code: '+1-671', name: 'Guam' },
        { code: '+502', name: 'Guatemala' },
        { code: '+44-1481', name: 'Guernsey' },
        { code: '+224', name: 'Guinea' },
        { code: '+245', name: 'Guinea-Bissau' },
        { code: '+592', name: 'Guyana' },
        { code: '+509', name: 'Haiti' },
        { code: '+504', name: 'Honduras' },
        { code: '+852', name: 'Hong Kong' },
        { code: '+36', name: 'Hungary' },
        { code: '+354', name: 'Iceland' },
        { code: '+91', name: 'India' },
        { code: '+62', name: 'Indonesia' },
        { code: '+98', name: 'Iran' },
        { code: '+964', name: 'Iraq' },
        { code: '+353', name: 'Ireland' },
        { code: '+44-1624', name: 'Isle of Man' },
        { code: '+972', name: 'Israel' },
        { code: '+39', name: 'Italy' },
        { code: '+225', name: 'Ivory Coast' },
        { code: '+1-876', name: 'Jamaica' },
        { code: '+81', name: 'Japan' },
        { code: '+44-1534', name: 'Jersey' },
        { code: '+962', name: 'Jordan' },
        { code: '+7', name: 'Kazakhstan' },
        { code: '+254', name: 'Kenya' },
        { code: '+686', name: 'Kiribati' },
        { code: '+383', name: 'Kosovo' },
        { code: '+965', name: 'Kuwait' },
        { code: '+996', name: 'Kyrgyzstan' },
        { code: '+856', name: 'Laos' },
        { code: '+371', name: 'Latvia' },
        { code: '+961', name: 'Lebanon' },
        { code: '+266', name: 'Lesotho' },
        { code: '+231', name: 'Liberia' },
        { code: '+218', name: 'Libya' },
        { code: '+423', name: 'Liechtenstein' },
        { code: '+370', name: 'Lithuania' },
        { code: '+352', name: 'Luxembourg' },
        { code: '+853', name: 'Macao' },
        { code: '+389', name: 'Macedonia' },
        { code: '+261', name: 'Madagascar' },
        { code: '+265', name: 'Malawi' },
        { code: '+60', name: 'Malaysia' },
        { code: '+960', name: 'Maldives' },
        { code: '+223', name: 'Mali' },
        { code: '+356', name: 'Malta' },
        { code: '+692', name: 'Marshall Islands' },
        { code: '+222', name: 'Mauritania' },
        { code: '+230', name: 'Mauritius' },
        { code: '+262', name: 'Mayotte' },
        { code: '+52', name: 'Mexico' },
        { code: '+691', name: 'Micronesia' },
        { code: '+373', name: 'Moldova' },
        { code: '+377', name: 'Monaco' },
        { code: '+976', name: 'Mongolia' },
        { code: '+382', name: 'Montenegro' },
        { code: '+1-664', name: 'Montserrat' },
        { code: '+212', name: 'Morocco' },
        { code: '+258', name: 'Mozambique' },
        { code: '+95', name: 'Myanmar' },
        { code: '+264', name: 'Namibia' },
        { code: '+674', name: 'Nauru' },
        { code: '+977', name: 'Nepal' },
        { code: '+31', name: 'Netherlands' },
        { code: '+599', name: 'Netherlands Antilles' },
        { code: '+687', name: 'New Caledonia' },
        { code: '+64', name: 'New Zealand' },
        { code: '+505', name: 'Nicaragua' },
        { code: '+227', name: 'Niger' },
        { code: '+234', name: 'Nigeria' },
        { code: '+683', name: 'Niue' },
        { code: '+850', name: 'North Korea' },
        { code: '+1-670', name: 'Northern Mariana Islands' },
        { code: '+47', name: 'Norway' },
        { code: '+968', name: 'Oman' },
        { code: '+92', name: 'Pakistan' },
        { code: '+680', name: 'Palau' },
        { code: '+970', name: 'Palestine' },
        { code: '+507', name: 'Panama' },
        { code: '+675', name: 'Papua New Guinea' },
        { code: '+595', name: 'Paraguay' },
        { code: '+51', name: 'Peru' },
        { code: '+63', name: 'Philippines' },
        { code: '+870', name: 'Pitcairn' },
        { code: '+48', name: 'Poland' },
        { code: '+351', name: 'Portugal' },
        { code: '+1-787, +1-939', name: 'Puerto Rico' },
        { code: '+974', name: 'Qatar' },
        { code: '+242', name: 'Republic of the Congo' },
        { code: '+262', name: 'Reunion' },
        { code: '+40', name: 'Romania' },
        { code: '+7', name: 'Russia' },
        { code: '+250', name: 'Rwanda' },
        { code: '+590', name: 'Saint Barthelemy' },
        { code: '+290', name: 'Saint Helena' },
        { code: '+1-869', name: 'Saint Kitts and Nevis' },
        { code: '+1-758', name: 'Saint Lucia' },
        { code: '+590', name: 'Saint Martin' },
        { code: '+508', name: 'Saint Pierre and Miquelon' },
        { code: '+1-784', name: 'Saint Vincent and the Grenadines' },
        { code: '+685', name: 'Samoa' },
        { code: '+378', name: 'San Marino' },
        { code: '+239', name: 'Sao Tome and Principe' },
        { code: '+966', name: 'Saudi Arabia' },
        { code: '+221', name: 'Senegal' },
        { code: '+381', name: 'Serbia' },
        { code: '+248', name: 'Seychelles' },
        { code: '+232', name: 'Sierra Leone' },
        { code: '+65', name: 'Singapore' },
        { code: '+1-721', name: 'Sint Maarten' },
        { code: '+421', name: 'Slovakia' },
        { code: '+386', name: 'Slovenia' },
        { code: '+677', name: 'Solomon Islands' },
        { code: '+252', name: 'Somalia' },
        { code: '+27', name: 'South Africa' },
        { code: '+82', name: 'South Korea' },
        { code: '+211', name: 'South Sudan' },
        { code: '+34', name: 'Spain' },
        { code: '+94', name: 'Sri Lanka' },
        { code: '+249', name: 'Sudan' },
        { code: '+597', name: 'Suriname' },
        { code: '+47', name: 'Svalbard and Jan Mayen' },
        { code: '+268', name: 'Swaziland' },
        { code: '+46', name: 'Sweden' },
        { code: '+41', name: 'Switzerland' },
        { code: '+963', name: 'Syria' },
        { code: '+886', name: 'Taiwan' },
        { code: '+992', name: 'Tajikistan' },
        { code: '+255', name: 'Tanzania' },
        { code: '+66', name: 'Thailand' },
        { code: '+228', name: 'Togo' },
        { code: '+690', name: 'Tokelau' },
        { code: '+676', name: 'Tonga' },
        { code: '+1-868', name: 'Trinidad and Tobago' },
        { code: '+216', name: 'Tunisia' },
        { code: '+90', name: 'Turkey' },
        { code: '+993', name: 'Turkmenistan' },
        { code: '+1-649', name: 'Turks and Caicos Islands' },
        { code: '+688', name: 'Tuvalu' },
        { code: '+1-340', name: 'U.S. Virgin Islands' },
        { code: '+256', name: 'Uganda' },
        { code: '+380', name: 'Ukraine' },
        { code: '+971', name: 'United Arab Emirates' },
        { code: '+44', name: 'United Kingdom' },
        { code: '+1', name: 'United States' },
        { code: '+598', name: 'Uruguay' },
        { code: '+998', name: 'Uzbekistan' },
        { code: '+678', name: 'Vanuatu' },
        { code: '+379', name: 'Vatican' },
        { code: '+58', name: 'Venezuela' },
        { code: '+84', name: 'Vietnam' },
        { code: '+681', name: 'Wallis and Futuna' },
        { code: '+212', name: 'Western Sahara' },
        { code: '+967', name: 'Yemen' },
        { code: '+260', name: 'Zambia' },
        { code: '+263', name: 'Zimbabwe' },
    ];

    const checkPhoneNumberValid = async (phonenumber) => {
        let returnData;
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "MobileNo": phonenumber,
                    "LoginType": "MobileNo"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                console.log("/user/checkingMobileNoOrEmailIsExisting", responseJson.Data);
                returnData = responseJson.Data

            }))
            .catch((error) => {
                // console.log( "/user/checkingMobileNoOrEmailIsExisting error occurred. Please try again later.")
            });

        return returnData
    }

    async function sendEmailWhenEditEmail(emailValue, nameValue, userId) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/sendEmailWhenEditEmail",
                "POST",
                JSON.stringify({
                    "Email": emailValue,
                    "MemberName": nameValue,
                    "UserId": userId
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/sendEmailWhenEditEmail  responseJson :', responseJson)

                if (responseJson.Data.email == emailValue) {
                    alert(t('error.We have sent an email verification to your mailbox. Please proceed to verify and complete the process.'))
                }
            }))
            .catch((error) => {
                //console.log('/user/sendEmailWhenEditEmail  error :', error)
            });
    }

    async function handleConfirmationChangePassword() {

        const currentPassword = document.querySelector('input[name="currentpassword"]').value;
        const newPassword = document.querySelector('input[name="newpassword"]').value;
        const confirmPassword = document.querySelector('input[name="confirmpassword"]').value;

        //console.log("currentPassword", currentPassword)
        //console.log("newPassword", newPassword)
        //console.log("confirmPassword", confirmPassword)

        let errors = [];

        if (currentPassword == "") {
            errors.push(t("errorPush.- Current Password cannot be empty!"));
        }
        if (newPassword == "") {
            errors.push(t("errorPush.- New Password cannot be empty!"));
        }
        if (confirmPassword == "") {
            errors.push(t("errorPush.- Confirm Password cannot be empty!"));
        }
        if (newPassword.length < 8) {
            errors.push(t("errorPush.- Password length must be 8 characters or above!"));
        }

        if (newPassword != confirmPassword) {
            errors.push(t("errorPush.- New password must matched with Confirm Password!"));
        }

        if (errors.length > 0) {
            alert(errors.join("\n"))
        } else {

            const currentPasswordHashed = SHA256(currentPassword).toString();
            const passwordHashed = SHA256(confirmPassword).toString();
            const diversifyKey = Math.random().toString(36).substring(2, 12);


            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/user/userUpdateOwnPassword",
                    "POST",
                    JSON.stringify({
                        "UserId": UserId,
                        "Password": currentPasswordHashed,
                        "NewPassword": passwordHashed,
                        "DiversifyKey": diversifyKey,
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {

                    //return data here
                    //console.log('responseJson in /user/userUpdateOwnPassword', responseJson);

                    if (responseJson.ReturnCode == 200) {
                        window.location.reload();
                        alert(t("error.Successfully updated password!"));
                        // setChangePasswordModalShow(false)

                    } else {
                        alert(responseJson.ReturnDesc);
                    }

                }))
                .catch((error) => {
                    //console.log("/user/userUpdateOwnPassword went wrong. Please try again later.", error)
                });
        }


    }

    async function changeAndUpdateName(name) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateUserAccount",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "Name": name,
                    "Email": email,
                    "WhatappsMobileNo": whatsAppNumber,
                    "IsUpdateWhatappsMobileNo": false,
                    "MobileNo": phoneNumber,
                    "Photos": []
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log('responseJson in /user/updateUserAccount', responseJson);

                if (responseJson.ReturnCode == 200) {
                    window.location.reload();
                    alert(t("error.Successfully updated name!"))

                } else {
                    //console.log("/user/updateUserAccount went wrong. Please try again later.")
                }

            }))
            .catch((error) => {
                //console.log("/user/updateUserAccount went wrong. Please try again later.", error)
            });
    }

    async function insertImageToDatabase(returnImage64Image) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateUserAccount",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "Name": displayUserName,
                    "Email": email,
                    "WhatappsMobileNo": whatsAppNumber,
                    "IsUpdateWhatappsMobileNo": false,
                    "MobileNo": phoneNumber ? phoneNumber : null,
                    "Photos": [returnImage64Image]
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log('responseJson in /user/updateUserAccount', responseJson);

                if (responseJson.ReturnCode == 200) {
                    window.location.reload();
                    alert(t('error.Successfully updated profile picture !'))

                }
            }))
            .catch((error) => {
                //console.log("Oops!, /user/updateUserAccount went wrong. Please try again later.")
            });
    }

    const handlePictureChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedPicture(file);
            setPreviewImage(URL.createObjectURL(file));
        } else {
            setSelectedPicture(null);
            setPreviewImage(null);
        }
    };

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid black',
                    borderColor: '#D9D9D9',
                    marginTop: '2%'
                }}
            ></div>
        )
    }


    function renderLabel(titleName, data, onpress, dontShowArrow) {
        return (
            <Row className='mt-3'>
                <div style={{ color: COLORS.darkGray, cursor: !dontShowArrow ? 'pointer' : 'default' }} onClick={!dontShowArrow && onpress}>
                    <Row>
                        <Col>
                            {titleName}
                        </Col>
                        <Col className="text-end">
                            {data && data}
                            {
                                !dontShowArrow &&
                                <Image src={require('../../img/icons/arrow.png')} width={10} height={15} />
                            }

                        </Col>
                    </Row>
                </div>
                {greyLine()}
            </Row>
        )
    }

    function ChangePasswordModal() {
        return (
            <Modal show={changePasswordModalShow} onHide={() => setChangePasswordModalShow(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('accountAndSecurity.Change Password')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>{t('accountAndSecurity.Current Password')}</p>
                        <input type="password" name='currentpassword' />
                    </div>
                    <div style={{ marginTop: '3%' }}>
                        <p>{t('accountAndSecurity.New Password')}</p>
                        <input type="password" name='newpassword' />
                    </div>
                    <div style={{ marginTop: '3%' }}>
                        <p>{t('accountAndSecurity.Confirm Password')}</p>
                        <input type="password" name='confirmpassword' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setChangePasswordModalShow(false)}>
                    {t('accountAndSecurity.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleConfirmationChangePassword()
                    }}>
                        {t('accountAndSecurity.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function ChangePhoneNumberModal() {
        return (
            <Modal show={showUpdatePhoneNumberModal} onHide={() => setShowUpdatePhoneNumberModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('accountAndSecurity.Change Phone Number')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="col-3">
                            <Dropdown className="mt-2">
                                <Dropdown.Toggle className="form-control" variant="light" id="countryDropdown" style={{ backgroundColor: COLORS.white, borderColor: COLORS.lightGray1 }}>
                                    {frontCountryPhoneNumberModal}
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight>
                                    {/* <Dropdown.Item onClick={() => setFrontCountryPhoneNumberModal("+60")}>+60</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFrontCountryPhoneNumberModal("+65")}>+65</Dropdown.Item> */}

                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        {countryData.map((country) => (
                                            <Dropdown.Item key={country.code} onClick={() => setFrontCountryPhoneNumberModal(country.code)}>
                                                {country.name} ({country.code})
                                            </Dropdown.Item>
                                        ))}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col className="col-6">
                            <div className="input-group mt-2">
                                <input
                                    className="form-control"
                                    type="tel"
                                    id="phoneNumberInput"
                                    name="phoneNumberInput"
                                    placeholder={phoneNumberInput}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    disabled={phoneNumberInputDisabled}
                                />
                            </div>
                        </Col>


                        {
                            showEditPhoneNumber
                                ?
                                <Col className="col-2">
                                    <Button className="mt-2" onClick={() => {
                                        setResendMessagePhoneNumber(false)

                                        setShowEditPhoneNumber(false)
                                        setPhoneNumberInputDisabled(false)
                                        setShowOtpInputPhoneNumber(false)

                                        setConfirmButtonValidphoneNumber(true)
                                    }}>{t('accountAndSecurity.Edit')}</Button>
                                </Col>
                                :
                                <Col className="col-3">
                                    <Button className="mt-2" onClick={async () => {

                                        const inputPhoneNumberValue = document.querySelector('input[name="phoneNumberInput"]').value;

                                        const booleanTrueFalse = await checkPhoneNumberValid(frontCountryPhoneNumber + inputPhoneNumberValue);

                                        if (inputPhoneNumberValue == "") {
                                            alert(t('error.Phone number cannot be emtpy !'))
                                        } else if ((frontCountryPhoneNumber + inputPhoneNumberValue) == phoneNumber) {
                                            alert(t('error.New Phone number must be unique and cannot be duplicated!'))
                                        } else if (booleanTrueFalse != null) {
                                            alert(t('error.This mobile number existed. Please try another!'))
                                        } else {

                                            if (!isRequestingOTPForPhoneNumber) {
                                                setIsRequestingOTPForPhoneNumber(true);
                                                // Proceed with OTP request
                                                setResendMessagePhoneNumber(true)
                                                // setPhoneNumber(`${frontCountryPhoneNumberModal}${inputPhoneNumberValue}`)
                                                setPhoneNumberInput(inputPhoneNumberValue)
                                                setShowEditPhoneNumber(true)
                                                setPhoneNumberInputDisabled(true)
                                                setShowOtpInputPhoneNumber(true)
                                                sendOTPtoVerifyPhoneNumber(`${frontCountryPhoneNumberModal}${inputPhoneNumberValue}`)
                                                // Start cooldown timer

                                                setConfirmButtonValidphoneNumber(false)

                                                setTimeout(() => {
                                                    setIsRequestingOTPForPhoneNumber(false);
                                                }, cooldownDurationForPhoneNumber * 1000);

                                            } else {
                                                alert(t('error.Oops! Please wait 30 seconds before requesting OTP again.'))
                                            }
                                        }

                                    }}>{t('accountAndSecurity.Get otp')}</Button>
                                </Col>

                        }



                    </Row>
                    {
                        resendMessagePhoneNumber &&
                        <Row style={{ paddingLeft: '2%' }}>
                             {t('accountAndSecurity.Enter your otp here')}
                        </Row>
                    }

                    {
                        showOtpInputPhoneNumber &&
                        <Row>
                            <div className="input-group mt-2">
                                <input
                                    className="form-control"
                                    type="tel"
                                    id="otpInputPhoneNumber"
                                    name="otpInputPhoneNumber"
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </Row>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdatePhoneNumberModal(false)}>
                    {t('accountAndSecurity.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        const otpInputPhoneNumber = document.querySelector('input[name="otpInputPhoneNumber"]').value;

                        if (otpInputPhoneNumber == "") {
                        alert(t('error.Please filled in your otp number'))
                        } else {
                            setConfirmButtonValidphoneNumber(true)

                            //console.log(JSON.stringify({
                            //     "MobileNo": `${frontCountryPhoneNumberModal}${phoneNumberInput}`,
                            //     "OTP": otpInputPhoneNumber
                            // }))

                            await domainName
                                .serverAPICall(
                                    domainName.debugAPI + "/user/verifyVerificationTwilioOtp",
                                    "POST",
                                    JSON.stringify({
                                        "MobileNo": `${frontCountryPhoneNumberModal}${phoneNumberInput}`,
                                        "OTP": otpInputPhoneNumber
                                    })
                                )
                                .then(domainName.handleServerAPICallErrors)
                                .then((responseJson => {

                                    // console.log('/user/verifyVerificationTwilioOtp responseJson here', responseJson)

                                    if (responseJson.ReturnCode == 200) {
                                        updatePhoneNumberNumberWithAPI(`${frontCountryPhoneNumberModal}${phoneNumberInput}`)
                                    } else {
                                        setConfirmButtonValidphoneNumber(false)
                                        alert(responseJson.ReturnDesc);
                                    }

                                }))
                                .catch((error) => {
                                    // console.log('/user/verifyVerificationTwilioOtp error here', error)
                                });

                        }
                    }} disabled={confirmButtonValidphoneNumber}>
                        {t('accountAndSecurity.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function ChangeEmailModal() {
        return (
            <Modal show={showUpdateEmailModal} onHide={() => setShowUpdateEmailModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('changeEmailVerification.Change Email')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <p style={{ marginRight: '1%' }}>{t('accountAndSecurity.Current Email')} :</p>
                        <span style={{}}>{email}</span>
                        {/* <input type="text" placeholder={displayUserName} name='inputname' /> */}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ marginRight: '1%', display: 'flex', alignItems: 'center' }}>
                            <p style={{ margin: 0 }}>{t("accountAndSecurity.New Email")}</p>
                        </div>
                        <input type="email" placeholder="(e.g., xxx@xxx.com)" name='inputemail' style={{ boxShadow: COLORS.orange }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateEmailModal(false)}>
                    {t('accountAndSecurity.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        const inputEmailValue = document.querySelector('input[name="inputemail"]').value;

                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                        // Check if the email matches the regex pattern
                        const isValid = emailRegex.test(inputEmailValue)


                        // console.log('Input Email:', inputEmailValue);
                        if (inputEmailValue == "" || !isValid) {
                            alert(t('error.Please filled in valid email !'))
                        } else {
                            const dataCode = await checkEmailValid(inputEmailValue)
                            // console.log('dataCode.Data', dataCode.Data)
                            if (dataCode.Data != null) {
                                // means existed, need to show red words
                                alert(t('error.Email is existed, please try another !'))
                            } else {
                                // means not existed, show nothings
                                // console.log("inputEmailValue", inputEmailValue)
                                // console.log("dataCode.Data", displayUserName)
                                // console.log("UserId", UserId)
                                await sendEmailWhenEditEmail(inputEmailValue, displayUserName, UserId)

                                setShowUpdateEmailModal(false)
                                // call api to sent email

                            }
                        }

                    }}>
                       {t('accountAndSecurity.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    function ChangeNameModal() {
        return (
            <Modal show={changeNameModalShow} onHide={() => setChangeNameModalShow(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('accountAndSecurity.Change Name')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>{t('accountAndSecurity.Name')}</p>
                        <input type="text" placeholder={displayUserName} name='inputname' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setChangeNameModalShow(false)}>
                    {t('accountAndSecurity.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={() => {
                        const inputValue = document.querySelector('input[name="inputname"]').value;
                        //console.log('Input Name:', inputValue);
                        if (inputValue == "") {
                            alert(t('error.Name field cannot be empty!'))
                        } else {
                            changeAndUpdateName(inputValue)

                        }
                    }}>
                        {t('accountAndSecurity.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function ProfilePictureModal({ show, onHide, onPictureChange }) {

        const handleConfirm = () => {
            if (selectedPicture) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const base64Data = reader.result;
                    //console.log("base64Data", base64Data);

                    // Assuming you have a function called insertImageToDatabase to handle the database insertion
                    insertImageToDatabase(base64Data)
                        .then(() => {
                            //console.log('Image inserted successfully!');
                            onHide();
                        })
                        .catch((error) => {
                            console.error(t('error.Error inserting image to the database:'), error);
                            onHide();
                        });
                };

                reader.onerror = (error) => {
                    console.error(t('error.Error reading image file:'), error);
                    onHide();
                };

                reader.readAsDataURL(selectedPicture);
            } else {
                onHide();
            }
        };

        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('accountAndSecurity.Update Profile Picture')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>{t('accountAndSecurity.Picture')}</p>
                        {
                            previewImage ? <Image src={previewImage} width={150} height={150} style={{ borderRadius: 100 }} /> :
                                <Image src={displayImage} width={150} height={150} style={{ borderRadius: 100 }} />
                        }
                        <input type="file" accept="image/*" onChange={handlePictureChange} className='mt-3' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                    {t('accountAndSecurity.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={handleConfirm} disabled={!selectedPicture}>
                    {t('accountAndSecurity.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const checkEmailValid = async (emailinput) => {
        let returnResponseData;

        //console.log('checkEmailNumberValid')

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/checkingMobileNoOrEmailIsExisting",
                "POST",
                JSON.stringify({
                    "Email": emailinput,
                    "LoginType": "EMAIL"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                //console.log("/user/checkingMobileNoOrEmailIsExisting responseJson > ", responseJson);
                returnResponseData = responseJson;
            }))
            .catch((error) => {
                //console.log("/user/checkingMobileNoOrEmailIsExisting error > ", error);
            });

        return returnResponseData
    }

    async function getUserInfoForMeMenu() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/meMenu",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "DisplayScreen": "User"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/meMenu responseJson here', responseJson)
                setDisplayUserName(responseJson.Data.UserName)
                setDisplayImage(responseJson.Data.UserImage)
                setPhoneNumber(responseJson.Data.UserMobileNo)
                setEmail(responseJson.Data.UserEmail)
                setCanChangePassword(responseJson.Data.CanChangePassword)
                setWhatsappNumber(responseJson.Data.UserWhatAppsMobileNo)
                setNumberInput(responseJson.Data.UserWhatAppsMobileNo.substring(3))
                setPhoneNumberInput(responseJson.Data.UserMobileNo.substring(3))
            }))
            .catch((error) => {
                //console.log('/user/meMenu error here', error)
            });
    }

    async function sendOTPtoVerifyPhoneNumber(phoneNumber) {
        const smsid = SHA256(phoneNumber + "Adviser2u" + apiKey + smsKey)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/sendTwilioOtp",
                "POST",
                JSON.stringify({
                    "MobileNo": phoneNumber,
                    "SMS_ID": smsid.toString()
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/sendTwilioOtp responseJson here', responseJson)

            }))
            .catch((error) => {
                //console.log('/user/sendTwilioOtp error here', error)
            });

    }


    async function sendOTPtoVerifyWhatsapp(phoneNumber) {
        const smsid = SHA256(phoneNumber + "Adviser2u" + apiKey + smsKey)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/sendTwilioOtp",
                "POST",
                JSON.stringify({
                    "MobileNo": phoneNumber,
                    "SMS_ID": smsid.toString()
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/sendTwilioOtp responseJson here', responseJson)

            }))
            .catch((error) => {
                //console.log('/user/sendTwilioOtp error here', error)
            });

    }

    async function updatePhoneNumberNumberWithAPI(phoneNum) {
        //console.log("const updatePhoneNumberNumberWithAPI = (phoneNum) =>", phoneNum)
        // console.log(JSON.stringify({
        //     "UserId": UserId,
        //     "Name": displayUserName,
        //     "Email": email,
        //     "WhatappsMobileNo": whatsAppNumber,
        //     "IsUpdateWhatappsMobileNo": false,
        //     "MobileNo": phoneNum,
        //     "Photos": []
        // }))

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateUserAccount",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "Name": displayUserName,
                    "Email": email,
                    "WhatappsMobileNo": whatsAppNumber,
                    "IsUpdateWhatappsMobileNo": false,
                    "MobileNo": phoneNum,
                    "Photos": []
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/updateUserAccount whatsapp number update responseJson here', responseJson)
                if (responseJson.ReturnCode == 200) {
                    alert(t('error.Changed phone number successfully!'))
                    setShowUpdatePhoneNumberModal(false)
                    window.location.reload();
                } else {
                    alert(responseJson.ReturnDesc)
                }
            }))
            .catch((error) => {
                //console.log('/user/updateUserAccount whatsapp number update error here', error)
            });
    }


    async function updateWhatsappNumberWithAPI(whatsappNum) {
        //console.log("const updateWhatsappNumberWithAPI = (whatsappNum) =>", whatsappNum)
        // console.log(JSON.stringify({
        //     "UserId": UserData._id,
        //     "Name": UserData.Name,
        //     "Email": UserData.Email,
        //     "WhatappsMobileNo": whatsappNum,
        //     "IsUpdateWhatappsMobileNo": true,
        //     "MobileNo": UserData.MobileNo,
        //     "Photos": []
        // }))

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateUserAccount",
                "POST",
                JSON.stringify({
                    "UserId": UserData._id,
                    "Name": UserData.Name,
                    "Email": UserData.Email,
                    "WhatappsMobileNo": whatsappNum,
                    "IsUpdateWhatappsMobileNo": true,
                    "MobileNo": UserData.MobileNo,
                    "Photos": []
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/updateUserAccount whatsapp number update responseJson here', responseJson)
                if (responseJson.ReturnCode == 200) {

                    setShowUpdateWhatsappModal(false)
                    window.location.reload();
                } else {
                    alert(t('error.An error occurred. Please contact administrator and report this issue.'))
                }
            }))
            .catch((error) => {
                //console.log('/user/updateUserAccount whatsapp number update error here', error)
            });
    }



    function UpdateWhatsappModal() {
        return (
            <Modal show={showUpdateWhatsappModal} backdrop="static" keyboard={false} fade={false}>
                <Modal.Header>
                    <Modal.Title>{t('accountAndSecurity.Update Your WhatsApp')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('accountAndSecurity.Whatsapp No *')} </Row>
                    <Row>
                        <Col className="col-3">
                            <Dropdown className="mt-2">
                                <Dropdown.Toggle className="form-control" variant="light" id="countryDropdown" style={{ backgroundColor: COLORS.white, borderColor: COLORS.lightGray1 }}>
                                    {frontCountryPhoneNumber}
                                </Dropdown.Toggle>
                                <Dropdown.Menu alignRight>
                                    {/* <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+60")}>+60</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+65")}>+65</Dropdown.Item> */}

                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>=
                                        {countryData.map((country) => (
                                            <Dropdown.Item key={country.code} onClick={() => setFrontCountryPhoneNumber(country.code)}>
                                                {country.name} ({country.code})
                                            </Dropdown.Item>
                                        ))}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col className="col-6">
                            <div className="input-group mt-2">
                                <input
                                    className="form-control"
                                    type="tel"
                                    id="whatsappInput"
                                    name="whatsappInput"
                                    placeholder={numberInput}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    disabled={phoneInputDisabled}
                                />
                            </div>
                        </Col>


                        {
                            showEdit
                                ?
                                <Col className="col-2">
                                    <Button className="mt-2" onClick={() => {
                                        setResendMessage(false)

                                        setShowEdit(false)
                                        setPhoneInputDisabled(false)
                                        setShowOtpInput(false)

                                        setConfirmButtonValid(true)

                                    }}>{t('accountAndSecurity.Edit')}</Button>
                                </Col>
                                :
                                <Col className="col-3">
                                    <Button className="mt-2" onClick={() => {

                                        const inputValue = document.querySelector('input[name="whatsappInput"]').value;

                                        if (inputValue == "") {
                                            alert(t('error.Whatsapp number cannot be emtpy !'))
                                        } else {

                                            //console.log("isRequestingOTP", isRequestingOTP)

                                            if (!isRequestingOTP) {
                                                setIsRequestingOTP(true);
                                                // Proceed with OTP request
                                                setResendMessage(true)
                                                setNumberInput(inputValue)
                                                setShowEdit(true)
                                                setPhoneInputDisabled(true)
                                                setShowOtpInput(true)

                                                setConfirmButtonValid(false)

                                                sendOTPtoVerifyWhatsapp(`${frontCountryPhoneNumber}${inputValue}`)


                                                setTimeout(() => {
                                                    setIsRequestingOTP(false);
                                                }, cooldownDuration * 1000);

                                            } else {
                                                alert(t('error.Oops! Please wait 30 seconds before requesting OTP again.'))
                                            }

                                        }

                                    }}>{t('accountAndSecurity.Get otp')}</Button>
                                </Col>

                        }



                    </Row>
                    {
                        resendMessage &&
                        <Row style={{ paddingLeft: '2%' }}>
                            {t('accountAndSecurity.Enter your otp here')}
                        </Row>
                    }

                    {
                        showOtpInput &&
                        <Row>
                            <div className="input-group mt-2">
                                <input
                                    className="form-control"
                                    type="tel"
                                    id="otpInput"
                                    name="otpInput"
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </Row>
                    }



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowUpdateWhatsappModal(false)
                    }}
                    >
                        {t('accountAndSecurity.Cancel')}
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        const otpInputValue = document.querySelector('input[name="otpInput"]').value;

                        if (otpInputValue == "") {
                            alert(t('error.Please filled in your otp number'))
                        } else {
                            setConfirmButtonValid(true)

                            // console.log(JSON.stringify({
                            //     "MobileNo": `${frontCountryPhoneNumber}${numberInput}`,
                            //     "OTP": otpInputValue
                            // }))

                            await domainName
                                .serverAPICall(
                                    domainName.debugAPI + "/user/verifyVerificationTwilioOtp",
                                    "POST",
                                    JSON.stringify({
                                        "MobileNo": `${frontCountryPhoneNumber}${numberInput}`,
                                        "OTP": otpInputValue
                                    })
                                )
                                .then(domainName.handleServerAPICallErrors)
                                .then((responseJson => {

                                    //console.log('/user/verifyVerificationTwilioOtp responseJson here', responseJson)

                                    if (responseJson.ReturnCode == 200) {
                                        updateWhatsappNumberWithAPI(`${frontCountryPhoneNumber}${numberInput}`)
                                    } else {
                                        alert(responseJson.ReturnDesc);
                                    }

                                }))
                                .catch((error) => {
                                    //console.log('/user/verifyVerificationTwilioOtp error here', error)
                                });
                        }

                    }}
                        disabled={confirmButtonValid}
                    >
                        {t('accountAndSecurity.Confirm')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    useEffect(() => {
        getUserInfoForMeMenu()
    }, [])

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t('accountAndSecurity.Account & Security')}</h5>
                                </Col>
                            </Row>

                            <Row className='mt-2'>
                                <Col className="d-flex justify-content-center">
                                    <div style={{ cursor: 'pointer', color: COLORS.darkGray }} onClick={() => setShowModal(true)}>
                                        <Image src={displayImage} width={150} height={150} style={{ borderRadius: 100 }} />
                                    </div>
                                </Col>

                            </Row>
                            <Row className='mt-3'>
                                <Col className="d-flex justify-content-center">
                                    <div style={{ cursor: 'pointer', color: COLORS.darkGray }} onClick={() => setShowModal(true)}>
                                        <span style={{ color: COLORS.orange }}>{t('accountAndSecurity.Change Profile Pic')}</span>
                                    </div>
                                </Col>
                            </Row>
                            {renderLabel(t("accountAndSecurity.Name"), displayUserName, () => setChangeNameModalShow(true))}
                            {phoneNumber && renderLabel(t("accountAndSecurity.Phone"), phoneNumber, () => { setShowUpdatePhoneNumberModal(true) }, false)}
                            {advisorState.advisor == true && renderLabel(t("accountAndSecurity.WhatsApp No."), whatsAppNumber, () => setShowUpdateWhatsappModal(true))}
                            {email && renderLabel(t("accountAndSecurity.Email"), email, () => { setShowUpdateEmailModal(true) }, canChangePassword ? false : true)}
                            {/* {canChangePassword && renderLabel("Change Password")} */}
                            {canChangePassword && renderLabel(t("accountAndSecurity.Change Password"), undefined, () => setChangePasswordModalShow(true))}


                        </Container>
                    </Col>
                </Row>

                <ProfilePictureModal
                    show={showModal}
                    onHide={() => { setShowModal(false); setPreviewImage(null) }}
                    onPictureChange={handlePictureChange}
                />
                <ChangeNameModal />
                <ChangeEmailModal />
                <ChangePhoneNumberModal />
                <ChangePasswordModal />
                <UpdateWhatsappModal />
            </Container>
        </div>
    )
}

export default AccountAndSecurity