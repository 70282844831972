import React, { useState, useEffect, useRef } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { COLORS } from '../../colorTheme/colorTheme';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLatitude, setLongitude, setLocationDisplay } from '../../redux/slices/login';
import { useTranslation } from "react-i18next";

const ChangeLocation = (props) => {

  const [t] = useTranslation("global");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapRef = useRef(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  const [latt, setLat] = useState(1.445)
  const [longg, setLong] = useState(103.860008)
  const [postCodeAddressString, setPostCodeAddressString] = useState('')

  //
  const [isAddressValid, setIsAddressValid] = useState(false)

  // for address location
  const [address, setAddress] = useState('');

  //for address checking based on IP
  const [countryCodeFromIP, setCountryCodeFromIP] = useState('SG')

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        const addressComponents = data.results[0].address_components;

        setAddress(data.results[0].formatted_address)


        let postalCode = "";
        let region = "";
        let city = "";

        for (let i = 0; i < addressComponents.length; i++) {
          const component = addressComponents[i];
          const componentTypes = component.types;

          if (componentTypes.includes("postal_code")) {
            postalCode = component.long_name;
          } else if (componentTypes.includes("administrative_area_level_1")) {
            region = component.long_name;
          } else if (componentTypes.includes("locality")) {
            city = component.long_name;
          }
        }

        // console.log("Postal Code:", postalCode);
        // console.log("Region:", region);
        // //console.log("city:", city);

        const addressString = `${postalCode && postalCode + ','}${city && city + ','}${region && region}`;

        //console.log("Address:", addressString); // set the postcodeAndAddress here 

        if (addressString != "") {
          setIsAddressValid(true)
          setPostCodeAddressString(addressString)
        }

      }
    } catch (error) {
      //console.log('Error:', error);
    }
  };


  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      // Extract relevant address components from the results
      const addressComponents = results[0].address_components;

      let postalCode = "";
      let region = "";
      let city = "";

      setMarkerPosition(latLng);
      //console.log("latLng.lat", latLng.lat, typeof latLng.lat)
      //console.log("latLng.lat", latLng.lng, typeof latLng.lng)
      setLat(latLng.lat)
      setLong(latLng.lng)

      // Loop through address components to find specific details
      addressComponents.forEach((component) => {
        if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        } else if (component.types.includes('locality')) {
          // Set city if available
          city = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          // Set region if available
          region = component.long_name;
        }
      });

      // Do something with the selected address and its coordinates (latLng)
      // console.log('Selected Address:', selectedAddress);
      // console.log('Coordinates:', latLng);
      // console.log('addressDetails', addressDetails)

      setAddress(selectedAddress)


      const addressString = `${postalCode && postalCode + ','}${city && city + ','}${region && region}`;
      //console.log("Address:", addressString); // set the postcodeAndAddress here 

      if (addressString != "") {
        setIsAddressValid(true)
        setPostCodeAddressString(addressString)
      }
      // setLocationList(addressDetails);

    } catch (error) {
      console.error(t('error.Error selecting address:'), error);
    }
  };


  const handleMapClick = (mapProps, map, clickEvent) => {
    const { latLng } = clickEvent;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setLat(lat)
    setLong(lng)

    // get the exact address base on the lat lng
    getAddressFromLatLng(lat, lng)

    setMarkerPosition({ lat, lng });
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.map;
      if (map) {
        map.panTo({ lat: latt, lng: longg });
      }
    }
  }, [latt, longg]);

  useEffect(() => {
    const getCountryCode = async () => {

      const geoResponse = await fetch(process.env.REACT_APP_BASE_URL + '/user/getCountry2', {
        headers: { 'Authorization': process.env.REACT_APP_API_KEY }
      });

      const geoData = await geoResponse.json();
      if (geoData.ReturnCode == 200) {
        const country = geoData.GeoData
        setCountryCodeFromIP(country.toUpperCase())
      } else {
        setCountryCodeFromIP('SG')
      }
    }
    getCountryCode()
  }, [])


  return (
    <div style={{ height: 'calc(100vh - 64px)', width: '100%', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 60, left: 10, zIndex: '1', width: '50%', flexDirection: 'column' }}>
        <PlacesAutocomplete
          value={address}
          onChange={(a) => {
            setIsAddressValid(false)
            setAddress(a)
          }}
          onSelect={handleSelect}
          searchOptions={{ componentRestrictions: { country: countryCodeFromIP } }} // 'MY' is the country code for Malaysia
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: t('placeholder.Enter your address...'),
                  className: 'form-control',
                })}
              />
              <div>
                {loading && <div>{t('community.Loading...')}</div>}
                {suggestions.map((suggestion, index) => {
                  const { description } = suggestion;
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion)}
                      style={{
                        backgroundColor: 'white',
                        cursor: 'pointer',
                      }}
                    >
                      {description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <Button className='mt-2' disabled={!isAddressValid}><span style={{ fontWeight: 'bold' }}
          onClick={() => {
            //console.log('address string : ', postCodeAddressString)
            //console.log('lat & long : ', latt, longg)
            //here to do dispatch 
            dispatch(setLatitude(latt))
            dispatch(setLongitude(longg))
            dispatch(setLocationDisplay(postCodeAddressString))

            navigate('/')
          }}
        >Confirm</span></Button>
      </div>
      <Map
        google={props.google}
        zoom={14}
        initialCenter={{ lat: latt, lng: longg }}
        // onClick={handleMapClick}
        style={{ height: '100%', width: '100%' }}
        ref={mapRef}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(ChangeLocation);