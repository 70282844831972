import React from 'react'
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme';
import domainName from '../domainName';
import { useEffect } from 'react';
import { useState } from 'react';
import { SHA256 } from 'crypto-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';

const VerifyEmail = () => {
    const { pathname, search } = useLocation();

    const navigate = useNavigate()

    const [t, i18n] = useTranslation("global");

    const [message, setMessage] = useState(t('setMessage.Verification in progress...'))
    const [showSuccess, setShowSuccess] = useState(false)

    async function activeUserAccount(userId) {

        ////console.log('userId in activeuseraccount', userId)

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/activateUserAccount",
                "POST",
                JSON.stringify({
                    "UserId": userId,
                    "Status": "ACTIVE"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                if (responseJson.ReturnCode == 200) {
                    //display successful message here 
                    setShowSuccess(true)
                    setMessage(t('setMessage.Email verified ! Please go log in and enjoy adviser2u !'))
                } else {
                    alert(t('error.Email verification failed. Please try it again later'))
                    navigate("/");
                }

            }))
            .catch((error) => {
                //console.log("/user/activateUserAccount error > ", error);
            });
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value from parameter
        const userid = searchParams.get("userId");
        const id = searchParams.get("id");
        const resetid = searchParams.get("resetid");
        const email = searchParams.get("email");
        const timeid = searchParams.get("tid");

        //console.log("userid", userid)

        // put some condition

        const emailHash = SHA256(email);

        const salt = SHA256(emailHash + timeid + id).toString();

        if (salt == resetid) {
            activeUserAccount(userid)
        } else {
            alert(t('error.Email verification failed. Please try it again later'))
            navigate("/");
        }


        let _lang = searchParams.get("lang") ? searchParams.get("lang")  : localStorage.getItem('selectedValue')
        i18n.changeLanguage(_lang);

    }, [])

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row style={{ textAlign: 'center' }}>
                                <Col>
                                    <h3 className="fw-bold">{t('changeEmailVerification.Email verification')}</h3>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'center' }}>
                                <Col className='mt-3'>
                                    <h5 className="fw-bold">{message}</h5>
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center">
                                {
                                    showSuccess &&
                                    <Image
                                        src={require('../img/icons/successful.png')}
                                        style={{
                                            width: 120,
                                            height: 100,
                                        }}
                                        className='mt-3'
                                    />
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default VerifyEmail