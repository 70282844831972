import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import MaintenancePage from './MaintenancePage';

import en from "./lang/en.json";
import id from "./lang/id.json";
import zh from "./lang/zh.json";
import ms from "./lang/ms.json";
import tl from "./lang/tl.json";

import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend'


const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';



i18next
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
  supportedLngs: ['en', 'id', 'zh','ms','tl'],
  resources: {
    en: {global : en},
    id: {global : id},
    zh: {global : zh},
    ms: {global : ms},
    tl: {global : tl},
  },
  detection: {
    order: ['path', 'cookie','localStorage','htmlTag'],
    caches: ['cookie','localStorage'],
  },
  backend: {
    loadPath: '/lang/{{lng}}.json',
  },
  react: {useSuspense: false},
})


const root = createRoot(document.getElementById('root'));
root.render(
  isMaintenanceMode ? <MaintenancePage /> :
    <Provider store={store}>
      {/* <React.StrictMode> */}
      <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
        </I18nextProvider>
      </BrowserRouter>

      {/* </React.StrictMode> */}
    </Provider>
);


// ReactDOM.render(
//   <Provider store={store}>
//     <React.StrictMode>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.StrictMode>
//   </Provider>,
//   document.getElementById('root')
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
