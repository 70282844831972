import React from 'react';
import { COLORS } from '../colorTheme/colorTheme';
import { Link } from 'react-router-dom';
import { SHA256 } from 'crypto-js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const BookingListCard = ({ containerStyle, imageStyle, item, onPress, statusColor, navigation }) => {


  const { UserData, UserId, latitude, longitude } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  function greyLine() {
    return (
      <div
        style={{
          borderBottom: '1px solid #D8D8D8',
          marginBottom: '1.8%',
          marginTop: '1%',
        }}
      />
    );
  }

  return (
    <div>
      <Link to={navigation} style={{ textDecorationLine: 'none' }}>
        <div style={{
          flexDirection: 'column',
          ...containerStyle
        }}>

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '0%'
          }}>
            <span style={{ color: '#000', fontSize: '16px', fontWeight: 'bold' }}>{t('BookingsAdvisor.Booking No')}: {item.Booking_No}</span>
            <span style={{ color: statusColor }}>{item.DisplayStatus}</span>
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '2%'
          }}>
            {/* Image */}
            <img
              src={item.AdvisorData.ImageDisplay}
              style={imageStyle}
              alt="Booking Image"
            />

            <div style={{
              flex: 1,
              flexDirection: 'column',
              marginLeft: '2%'
            }}>
              <div onClick={onPress}>
                <span style={{ color: '#000', fontSize: '14px' }}>{item.ServiceName}</span>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                  <img
                    src={require("../img/icons/user.png")}
                    style={{ width: 20, height: 20 }}
                    alt="User Icon"
                  />
                  <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item.AdvisorData.Name}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                  <img
                    src={require("../img/icons/dateTime.png")}
                    style={{ width: 20, height: 20 }}
                    alt="Date and Time Icon"
                  />
                  <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item.DisplayDateTime}</span>
                </div>
                {item.DisplayLocation != undefined &&
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                    <img
                      src={require("../img/icons/location.png")}
                      style={{ width: 20, height: 20 }}
                      alt="Location Icon"
                    />
                    <span style={{ marginLeft: '2%', color: '#444', fontSize: '14px' }}>{item.DisplayLocation}</span>
                  </div>
                }
              </div>


            </div>
          </div>
        </div>
      </Link>

      <div>
        {/* Chat and WhatsApp button */}
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1%',
          marginTop: '2%'
        }}>
          <button onClick={(e) => {
            e.stopPropagation();

            let currentTime = new Date().getTime(); //epoch timestamp now
            const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime)
            navigate(`/message?userId=${UserId}&messageId=${item.ServiceUserId}&uid=${resultEncrypted}&tid=${currentTime}`)
          }} className="btn-white text-dark  fw-bold btn"
            style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>{t('BookingsAdvisor.Chat')}</span></button>
          <button onClick={(e) => {
            e.stopPropagation();
            if (item.AdvisorData.CanWhatsapps) {
              window.open(item.AdvisorData.WhatappsLink);
            } else {
              alert(t("errorOops! This service provider didn't register WhatsApp"));
            }
          }} className="btn-white text-dark  fw-bold btn"
            style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>{t('BookingsAdvisor.WhatsApp')}</span></button>
        </div>

        {greyLine()}
      </div>


    </div>
  );
}

export default BookingListCard;