import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

// import post from "./slices/post";
// import user from "./slices/user";
// import sidebarNavigation from "./slices/sidebarNavigation";
// import signUp from "./slices/signUp";
import login from "./slices/login";
import switchRole from "./slices/switchRole";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  // sidebarNavigation: persistReducer(persistConfig, sidebarNavigation),
  // signUp: persistReducer(persistConfig, signUp),
  login: persistReducer(persistConfig, login),
  switchRole: persistReducer(persistConfig, switchRole),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

// export default { store, persistor };
