import React, { useState } from 'react'
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AdvisorCard = ({ containerStyle, imageStyle, item, onClick }) => {

    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);


    return (
        <Col xs={6} md={4} lg={3} style={{ marginBottom: isWebsite ? '40px' : '20px' }}>
            <Link style={{ textDecorationLine: 'none' }} to={`/servicedetails?serviceid=${item.ServiceData._id}`}>
                <a onClick={() => {
                    // console.log('haha') 
                }}>
                    <Card style={{ width: '100%', borderWidth: 0 }}>
                        <Card.Img
                            variant="top"
                            src={item.ServiceData.CoverImageDisplay}
                            alt="Image 1"
                            style={{ height: isWebsite ? '300px' : '150px', objectFit: 'cover', borderRadius: 20 }}
                        />
                        <Card.Subtitle style={{ color: COLORS.black, position: 'absolute', top: isWebsite ? 240 : 110, backgroundColor: COLORS.white, padding: '3%', marginLeft: '5%', borderRadius: 20 }}>{item.DistanceDisplay}</Card.Subtitle>

                        <Card.Body style={{ padding: 0, margin: 0 }}>
                            <Card.Title className="mt-3 mb-0" style={{
                                color: COLORS.darkGray,
                                fontSize: '1.2rem',
                                display: '-webkit-box',
                                '-webkit-line-clamp': 2, // Set the number of lines to display
                                '-webkit-box-orient': 'vertical',
                                maxHeight: '2.5em', // Adjust the max-height as needed (2 lines with line height)
                                lineHeight: '1.25em', // Set the line height to half of the max-height
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%', // Adjust the maximum width as needed
                            }}>
                                {item.ServiceData.ServiceName}
                            </Card.Title>


                            <div className="d-flex p-0">
                                <div className="me-1">
                                    <Image src={require('../img/icons/star.png')} alt="Star" width={isWebsite ? 18 : 15} height={isWebsite ? 18 : 15} style={{ alignSelf: 'center' }} />
                                </div>
                                <div className="me-1">
                                    <Card.Text style={{ color: COLORS.lightOrange1 }}>{item.UsersReviewData.UserRating}</Card.Text>
                                </div>
                                <div>
                                    <Card.Text style={{ color: COLORS.gray }}>
                                        ({item.UsersReviewData.UserReviewListCount})
                                    </Card.Text>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </a>
            </Link>
        </Col >
    )
}

export default AdvisorCard
