import React, { useState, useEffect } from 'react';
import domainName from '../../domainName'
import { useDispatch, useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import './SubscriptionStepOne.css';
import validIcon from '../../img/valid.svg';
import { useTranslation } from "react-i18next";



const SubscriptionStepFour = () => {

  const location = useLocation();
  const [t] = useTranslation("global");

  const [changePlan, setChangePlan] = useState(0);
  const [userId, setUserId] = useState('');
  const [pathLang, setPathLang] = useState('');



  const handleViewClick = () => {
    const isApp = /(android|webos|iphone|ipad|ipod|blackberry|windows phone)/i.test(
      navigator.userAgent
    );

    if (isApp) {
      window.location.href = 'advisor2uapp://HomeAdvisor';
    } else {
      window.location.href = `../subscription?userId=${userId}&lang=${pathLang}`;
    }
  };


  useEffect(() => {
    let queryParams = queryString.parse(location.search);
    console.log('queryParams::', queryParams)
    setChangePlan(queryParams.changePlan)
    setUserId(queryParams.userId)
    setPathLang(queryParams.lang)
  })

  return (
    <div className='greybk-div p-lg-5' style={{ height: '100vh' }}>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col className='col-lg-5'>
            <div className='box-container p-5 text-center'>
              <img className='mb-3' src={validIcon} />
              <h5 className='mb-3 fw-bold'>
                {changePlan == 1
                  ? t('subscriptionStepFour.Change Plan Successful')
                  : changePlan == 3
                    ? t('subscriptionStepFour.Cancel Successful')
                    : t('subscriptionStepFour.Subscription Successful')}
              </h5>
              <div className='mb-4'>
                {changePlan == 1
                  ? t('subscriptionStepFour.Thank you! Click the view button to see more subscription details.')
                  : changePlan == 3
                    ? t('subscriptionStepFour.Your subscription has been canceled successfully.')
                    :t('subscriptionStepFour.Congratulations, your adviser account is now active!')}
              </div>
              <Button className='orange-btn text-center col-lg-3' onClick={handleViewClick}>
                {t('subscriptionStepFour.View')}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )

}

export default SubscriptionStepFour