import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { COLORS } from '../../colorTheme/colorTheme';
import { useTranslation } from "react-i18next";


const SignUpSuccessful = () => {

    const [t] = useTranslation("global");

    const navigate = useNavigate();

    return (
        <div className="greybk-div p-lg-5" style={{ height: '100vh' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-5">
                        <Container className="box-container p-5">
                            <Row className="justify-content-center">
                                <Col className="d-flex justify-content-center">
                                    <Image
                                        style={{ width: 40, height: 40, marginTop: ('5%') }}
                                        src={require("../../img/icons/successful.png")}
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-4">
                                <Col className="d-flex justify-content-center">
                                    <h2 className="fw-bold">{t('signInSignUp.Success')}</h2>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <span style={{ textAlign: 'center' }}>{t('signInSignUp.Your account has been successfully created.')}</span>
                            </Row>
                            {/* <Row className='mt-4'>
                                <Button onClick={() => navigate('/signin')}>Log in</Button>
                            </Row> */}
                            <Nav.Link style={{ textDecorationLine: 'none' }}
                                // to={`/signin`}
                                href="/signin"
                            >
                                {/* <Link style={{ textDecorationLine: 'none' }}
                                    to={`/signin`}
                                > */}
                                <Row className="justify-content-center mt-4" style={{ backgroundColor: COLORS.orange, borderRadius: 20, paddingTop: '2%', paddingBottom: '2%' }}>
                                    <Col className="d-flex justify-content-center">
                                        <span style={{ color: COLORS.white }}>{t('signInSignUp.Log In')}</span>
                                    </Col>
                                </Row>
                                {/* </Link> */}

                            </Nav.Link>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SignUpSuccessful